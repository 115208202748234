const renderColumn = () => {
  const tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullName',
    },
    {
      label: 'Email',
      stateValue: 'email',
    },
    // {
    //   label: 'Phone Number',
    //   stateValue: 'mobile'
    // },
    {
      label: 'Status',
      stateValue: 'activeStatus',
    },
    {
      label: 'Action',
      stateValue: null,
    },
  ];
  return tableComlumn;
};

export default renderColumn;
