import { ORDER_INVOCE_TESTS } from '@/module/all-screenings/constants';
import {
  formatTotalPrice,
  generateStaffType,
  revertTotalCostStaffType,
} from '@/module/checkin-patient-information/util';
import CustomTooltip from '@/new-components/CustomTooltip';
import { Box, Card, Chip, Typography, withStyles } from '@material-ui/core';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 10,
    background: 'linear-gradient(to bottom, #FEFFFF 0%, #EDF7FF 100%)',
    width: (props) => (props.width ? props.width : 'unset'),
    zIndex: -1,
  },
})(Card);

const CustomBox = withStyles({
  root: {
    '& .divider': {
      height: 1,
      width: '100%',
      marginTop: 20,
      backgroundImage:
        'linear-gradient(to right, #3776ab 50%, transparent 50%)',
      backgroundPosition: 'top',
      backgroundSize: '10px 1px',
      backgroundRepeat: 'repeat-x',
    },

    '&:last-child .divider': {
      display: 'none',
    },
  },
})(Box);

const CustomHeaderBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,

    '& .title': {
      fontWeight: 800,
      fontFamily: 'Lato',
      fontSize: 20,

      color: theme.palette.secondary.main,
    },
  },
}))(Box);

const Invoice = ({
  totalCost,
  totalAmountDefault,
  dataExist,
  packageInfor,
  paymentStatus,
  lstOptionalTests,
  restrictedTestIds,
  dataInfo,
}) => {
  const [totalCostPaid, setTotalCostPaid] = useState(0);
  const optionalTests = useMemo(() => {
    let newLstOptionalTests = cloneDeep(lstOptionalTests);
    if (!isEmpty(dataInfo?.doneFollowUpTests)) {
      newLstOptionalTests = newLstOptionalTests.filter((it) => {
        return !dataInfo?.doneFollowUpTests
          .map((it) => it.testId)
          .includes(it.id);
      });
    }
    return newLstOptionalTests;
  }, [dataInfo, lstOptionalTests]);

  useEffect(() => {
    setTotalCostPaid(totalCost);
  }, [totalCost]);

  const renderStaffDiscount = (staffType, totalCost) => {
    let staffDiscount = 0;
    if (!isEmpty(staffType))
      [staffType].forEach((item) => {
        switch (item.staffValueType) {
          case 'Percent':
            staffDiscount = totalCost * (item.subsidy / 100);
            break;
          case 'Value':
            staffDiscount = item.subsidy;
            break;
          default:
            break;
        }
      });
    return `-${formatTotalPrice(staffDiscount, '$')}`;
  };

  const renderTotalAmount = (staffType, totalCost) => {
    const totalAmount = generateStaffType(
      isEmpty(staffType) ? [] : [staffType],
      totalCost
    );
    return formatTotalPrice(totalAmount, '$');
  };

  const isChangeTotalAmount = useMemo(() => {
    const newTotalAmount = renderTotalAmount(dataExist?.staffType, totalCost);
    return newTotalAmount !== formatTotalPrice(totalAmountDefault, '$');
  }, [dataExist, totalCost, totalAmountDefault]);

  const renderStaffTypeLabel = (data) => {
    // const unit = STAFF_SUBSIDY_TYPE.find(
    //   item => data?.staffValueType === item.value
    // )?.label;
    if (data?.staffValueType === 'Percent') return ` (${data?.subsidy}%) `;
    return '';
  };

  return (
    <Box>
      <CustomCard color="#e2f2ffb3" width={360}>
        <CustomHeaderBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1.625}
          px={2.5}
        >
          <Typography className="title" style={{ color: '#333333' }}>
            Invoice
          </Typography>
          {paymentStatus && (
            <Chip color="primary" size="small" label={paymentStatus} />
          )}
        </CustomHeaderBox>

        <Box maxHeight={400} overflow="auto">
          {(isEmpty(packageInfor) || isEmpty(dataExist?.lstPackages)) &&
            isEmpty(dataExist?.lstTests) &&
            isEmpty(dataExist?.lstProfiles) && (
              <Box pt={3} px={2.5}>
                <BigText>No services selected.</BigText>
              </Box>
            )}
          {!isEmpty(packageInfor?.name) && !isEmpty(dataExist?.lstPackages) && (
            <CustomBox pt={2.5} px={2.5}>
              <SmallText>Package</SmallText>
              <Box pt={2.5} display="flex" justifyContent="space-between">
                <BigText>{packageInfor?.name}</BigText>
                {packageInfor?.price ? (
                  <LightText style={{ color: '#333333' }}>
                    {formatTotalPrice(packageInfor?.price, '$')}
                  </LightText>
                ) : (
                  <LightText style={{ fontWeight: 500 }}>Free</LightText>
                )}
              </Box>
              {!isEmpty(optionalTests) && (
                <>
                  <BoldText m="20px 0 10px">Optional tests</BoldText>
                  {optionalTests.map((test) => (
                    <BorderRow key={test.id}>
                      <BigText>{test?.name}</BigText>
                      {test?.price ? (
                        <LightText>
                          {formatTotalPrice(test?.price, '$')}
                        </LightText>
                      ) : (
                        <LightText style={{ fontWeight: 500 }}>Free</LightText>
                      )}
                    </BorderRow>
                  ))}
                </>
              )}

              <div className="divider"></div>
            </CustomBox>
          )}

          {(!isEmpty(dataExist?.lstTests) ||
            !isEmpty(dataExist?.lstProfiles)) && (
            <CustomBox pt={2.5} px={2.5}>
              <SmallText>Add-Ons</SmallText>
              {!isEmpty(dataExist?.lstProfiles) && (
                <>
                  <BoldText m="20px 0 10px">Profiles</BoldText>
                  {dataExist?.lstProfiles.map((test) => (
                    <BorderRow key={test.id}>
                      <BigText>
                        <CustomTooltip content={test?.name} />
                      </BigText>

                      {test?.price ? (
                        <LightText>
                          {formatTotalPrice(test?.price, '$')}
                        </LightText>
                      ) : (
                        <LightText style={{ fontWeight: 500 }}>Free</LightText>
                      )}
                    </BorderRow>
                  ))}
                  <div className="divider"></div>
                </>
              )}
              {dataExist?.lstTests
                .sort(
                  (a, b) =>
                    ORDER_INVOCE_TESTS[a.category] -
                    ORDER_INVOCE_TESTS[b.category]
                )
                .map((test) => (
                  <>
                    <BoldText key={test.id} m="20px 0 10px">
                      {test.category}
                    </BoldText>
                    {test?.items?.map((item) => (
                      <BorderRow key={item.id}>
                        <BigText>
                          <CustomTooltip content={item?.name} />
                        </BigText>

                        {item?.price ? (
                          <LightText>
                            {formatTotalPrice(item?.price, '$')}
                          </LightText>
                        ) : (
                          <LightText style={{ fontWeight: 500 }}>
                            Free
                          </LightText>
                        )}
                      </BorderRow>
                    ))}
                  </>
                ))}
              <div className="divider"></div>
            </CustomBox>
          )}
        </Box>
        <Box p={2.5}>
          <DividerBox />
          <Row m="12px 0">
            <BigText>Total</BigText>
            <StrongText>{formatTotalPrice(totalCostPaid, '$')}</StrongText>
          </Row>
          <Row m="12px 0">
            <BigText>
              Staff discount {renderStaffTypeLabel(dataExist?.staffType)}
            </BigText>
            <StrongText>
              {renderStaffDiscount(dataExist?.staffType, totalCostPaid)}
            </StrongText>
          </Row>
          <DividerBox />
          <Row m="20px 0">
            <PurpleText color={'#ad5e99'}>TOTAL AMOUNT</PurpleText>
            <PurpleText color={'#ad5e99'}>
              {renderTotalAmount(dataExist?.staffType, totalCostPaid)}
            </PurpleText>
          </Row>
        </Box>
      </CustomCard>
    </Box>
  );
};

export default Invoice;
const StrongText = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin: ${(p) => p.m};
`;

const SmallText = styled.div`
  margin: ${(p) => p.m};
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  // text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${(p) => p.m};
`;

const BorderRow = styled(Row)`
  padding: 8px 0 8px 12px;
  border-left: 2px solid #3776ab;
`;

const PurpleText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${(p) => p.color};
`;

const DividerBox = styled.div`
  height: 1px;
  width: 100%;
  margin: ${(p) => p.m};
  background: #3776ab;
`;

const LightText = styled.div`
  color: #999;
  font-size: 16px;
  font-weight: 700;
  margin: ${(p) => p.m};
`;
const BigText = styled.div`
  font-size: 16px;
  margin: ${(p) => p.m};
  max-width: 250px;
  word-break: break-all;
`;

const BoldText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin: ${(p) => p.m};
`;
