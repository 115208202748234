export const COLUMN_ANGEL_NUDGE = [
  {
    label: 'Category',
    stateValue: 'category',
  },
  {
    label: 'Item',
    stateValue: 'item',
    minWidth: 112,
  },
  {
    label: 'Nudge Trigger/ Condition',
    stateValue: 'condition',
  },
  {
    label: 'Timing (All 12pm)',
    stateValue: 'timing',
  },
  {
    label: 'Channels',
    stateValue: 'channels',
  },
  {
    label: 'In-App Notification',
    stateValue: 'notification',
  },
  {
    label: 'Whatsapp/ In-App Message',
    stateValue: 'message',
  },
  {
    label: 'CTA/ Event Kill',
    stateValue: 'event-kill',
  },
  {
    label: 'Reminder 1',
    stateValue: 'reminder1',
  },
  {
    label: 'Reminder 2',
    stateValue: 'reminder2',
  },
  {
    label: 'Escalation',
    stateValue: 'escalation',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
  },
];

export const CHANNEL_DISPLAY = {
  App: 'App',
  WhatsApp: 'WhatsApp',
  Email: 'Email',
};

export const CHANNEL_DISPLAY_COLORS = {
  [CHANNEL_DISPLAY.App]: {
    txt: '#AD5E99',
    background: '#FFE9FB',
  },
  [CHANNEL_DISPLAY.WhatsApp]: {
    txt: '#0B8E5E',
    background: '#15a47029',
  },
  [CHANNEL_DISPLAY.Email]: {
    txt: '#23A6CC',
    background: '#23a6cc29',
  },
};
