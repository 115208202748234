import CustomTooltip from '../CustomTooltip';
import { CheckmarkCircleIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

const useStyles = makeStyles({
  root: {
    borderRight: '1px solid  #f1f1f1',
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      gap: 8,
    },
    height: '100%',
    padding: '20px 16px',
  },
  tabLabel: {
    marginRight: 4,
    marginBottom: 0,
    marginTop: 8,
    lineHeight: '27px',
  },
  tabNumber: {
    minWidth: 20,
    borderRadius: 7,
    backgroundColor: '#F6F6F6',
  },
  taChecked: {
    minWidth: 20,
    verticalAlign: 'middle',
  },
  checkedIcon: {
    color: '#0B8E5E',
    width: 24,
    height: 24,
    verticalAlign: 'middle',
  },
});

const CustomTabItem = withStyles((theme) => ({
  root: {
    width: (prop) => (prop.width ? prop.width : 170),
    minHeight: 44,
    borderRadius: 8,
    padding: '0px 13px',

    color: (prop) => (prop.disabled ? theme.palette.grey.subText : '#191919'),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,

      backgroundColor: theme.palette.primary.light,
      '& .number-selected': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const CustomTabs = ({
  listTab,
  value,
  onChange,
  renderLabel,
  renderKey,
  renderChipLabel = 'chipLabel',
  renderChipCustomSurvey = 'chipCustomSurvey',
  variant = 'scrollable',
  errorsIndex = [],
  isCapitalize = false,
  orientation,
  title,
  btnWidth,
  boxStyle,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  const onShowLabel = (item, index) => {
    let Icon = item?.icon;
    let label = renderLabel ? item[renderLabel] : item;
    if (isCapitalize) label = capitalize(label);
    const error = errorsIndex.includes(index);

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={boxStyle}
      >
        {Icon && (
          <Box width={20} height={20} alignSelf="center">
            <Icon width={20} height={20} />
          </Box>
        )}
        <p
          className={classes.tabLabel}
          style={{
            color: error ? 'red' : 'inherit',
          }}
        >
          <CustomTooltip content={label} />
        </p>
        {item[renderChipLabel] > 0 && (
          <div className={clsx(classes.tabNumber, 'number-selected')}>
            {item[renderChipLabel]}
          </div>
        )}
        {item[renderChipCustomSurvey] > 0 && (
          <div className={clsx(classes.taChecked)}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
          </div>
        )}

        {error && (
          <span style={{ paddingTop: 6 }}>
            <ErrorOutlineIcon color="error" fontSize="small" />
          </span>
        )}
      </Box>
    );
  };

  return (
    <Box className={classes.root} {...props}>
      {title ? (
        <Box
          px={2}
          pt={2.5}
          fontSize={16}
          fontFamily={'OpenSans'}
          fontWeight={600}
        >
          {title}
        </Box>
      ) : null}

      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant={variant}
        orientation={orientation}
        className={classes.tabs}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        style={disabled ? { pointerEvents: 'none' } : {}}
      >
        {listTab.map((item, index) => (
          <CustomTabItem
            width={btnWidth}
            label={onShowLabel(item, index)}
            key={item[renderKey] || item}
            disabled={disabled}
          />
        ))}
      </Tabs>
    </Box>
  );
};
export default CustomTabs;
