import clinicServiceDispatcher from './action';
import ServiceItem from './component/ServiceItem';
import { CLINIC_SERVICE_TYPE, MAPPING_CLINIC_SERVICE_ICON } from './constants';
import {
  ClinicInactive,
  CreateNewIcon,
  DeleteModalIcon,
  NoDataServicesIcon,
} from '@/assets/svg';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTabs from '@/new-components/CustomTabs';
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { cloneDeep, get, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  noDataHelper: {
    textAlign: 'center',
    border: 'none',
    color: '#666666',
    '& .title-text': {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Lato',
      color: '#333333',
    },
  },
});

const ClinicServices = () => {
  const { list: data } = useSelector((state) => state.clinicService);
  const classes = useStyles();

  const [defaultData, setDefaultData] = useState({});
  const [listServices, setListServices] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [formValue, setFormValue] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(0);
  const [isCreated, setIsCreated] = useState(false);

  const defaultService = {
    subPurposes: [],
    purposeQuestions: [],
    name: 'New Service',
    purposeType: null,
  };

  const defaultListItem = {
    name: 'New Service',
    icon: ClinicInactive,
  };

  const handleChangeForm = (key, newValue) => (e) => {
    let value = get(e, 'target.value');
    if (key === 'name') {
      const newList = cloneDeep(listServices);
      newList[currentTab][key] = value;
      setListServices(newList);
      setFormValue({ ...formValue, [key]: value });
    } else if (key === 'purposeType') {
      if (value === defaultData.purposeType) {
        setFormValue({
          ...formValue,
          [key]: value,
          purposeQuestions: defaultData.purposeQuestions,
          subPurposes: defaultData.subPurposes,
        });
      } else {
        setFormValue({
          ...formValue,
          [key]: value,
          purposeQuestions: [],
          subPurposes: [],
        });
      }
    } else setFormValue({ ...formValue, [key]: value });
  };

  const renderToast = (data, isCreated) => {
    if (!isCreated) {
      return customToast(
        'success',
        <span>
          Service <strong style={{ fontWeight: 600 }}>{data}</strong> has been
          successully updated.
        </span>,
        'Service updated'
      );
    } else {
      return customToast(
        'success',
        <span>
          Service <strong style={{ fontWeight: 600 }}>{data}</strong> has been
          successully created.
        </span>,
        'New service created'
      );
    }
  };

  const handleSaveForm = () => {
    setLoading(true);

    if (isCreated) {
      clinicServiceDispatcher.createPurpose(
        formValue,
        () => {
          setIsCreated(false);
          setLoading(false);
          clinicServiceDispatcher.getPurposesData();
          renderToast(`${formValue.name}`, isCreated);
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setIsCreated(false);
      clinicServiceDispatcher.editPurpose(
        formValue.id,
        formValue,
        () => {
          setLoading(false);
          clinicServiceDispatcher.getPurposesData();
          renderToast(`${formValue.name}`, isCreated);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  const onDeleteService = () => {
    if (isCreated) {
      const newServiceList = cloneDeep(listServices);
      newServiceList.splice(currentTab, 1);
      if (currentTab > 0) setCurrentTab(currentTab - 1);
      else setCurrentTab(0);
      clinicServiceDispatcher.getPurposesData();

      setListServices(newServiceList);
      setShowDeleteModal(false);
      setIsCreated(false);

      customToast('success', 'Deleted successfully');
    } else
      clinicServiceDispatcher.deletePurpose(formValue.id, () => {
        const newServiceList = cloneDeep(listServices);
        newServiceList.splice(currentTab, 1);
        if (currentTab > 0) setCurrentTab(currentTab - 1);
        else setCurrentTab(0);
        clinicServiceDispatcher.getPurposesData();

        setListServices(newServiceList);
        setShowDeleteModal(false);
        setIsCreated(false);
      });
  };

  const isDisableSaveBtn = useMemo(() => {
    let isValid = true;
    if (formValue.name !== defaultData.name) {
      isValid = false;

      if (formValue.purposeType !== defaultData.purposeType) {
        switch (formValue.purposeType) {
          case CLINIC_SERVICE_TYPE.INPUT_TEXT:
            isValid = isEmpty(formValue?.purposeQuestions);
            break;
          case CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE:
          case CLINIC_SERVICE_TYPE.SINGLE_CHOICE:
            isValid = isEmpty(formValue?.subPurposes);
            break;
          default:
            isValid = false;
            break;
        }

        if (!isEmpty(formValue) && !isEmpty(defaultData)) {
          if (formValue.purposeType !== defaultData.purposeType) {
            switch (formValue.purposeType) {
              case CLINIC_SERVICE_TYPE.INPUT_TEXT:
                isValid = isEmpty(formValue?.purposeQuestions);
                break;
              case CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE:
              case CLINIC_SERVICE_TYPE.SINGLE_CHOICE:
                isValid = isEmpty(formValue?.subPurposes);
                break;
              default:
                isValid = false;
                break;
            }
          }
          const checkDifferValuePurpose = formValue?.purposeQuestions.filter(
            (obj1) => {
              return defaultData?.purposeQuestions?.every((obj2) => {
                return (
                  obj1.title !== obj2.title ||
                  obj1.hintText !== obj2.hintText ||
                  obj1.order !== obj2.order
                );
              });
            }
          );
          const checkEmptyValuePurpose = formValue?.purposeQuestions.filter(
            (it) => isEmpty(it.title) || isEmpty(it.hintText)
          );
          const checkDifferValueSubPurpose = formValue?.subPurposes?.filter(
            (obj1) => {
              return defaultData?.subPurposes?.every((obj2) => {
                return obj1.name !== obj2.name || obj1.order !== obj2.order;
              });
            }
          );
          const checkEmptyValueSubPurpose = formValue?.subPurposes?.filter(
            (it) => isEmpty(it.name)
          );
          if (formValue.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT)
            if (
              !isEmpty(formValue?.purposeQuestions) &&
              formValue?.purposeQuestions?.length !==
                defaultData?.purposeQuestions?.length
            ) {
              isValid = !isEmpty(checkEmptyValuePurpose);
            } else {
              isValid =
                isEmpty(checkDifferValuePurpose) ||
                !isEmpty(checkEmptyValuePurpose);
            }
          if (
            [
              CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
              CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
            ].includes(formValue.purposeType)
          )
            if (
              !isEmpty(formValue?.subPurposes) &&
              formValue?.subPurposes?.length !==
                defaultData?.subPurposes?.length
            ) {
              isValid = !isEmpty(checkEmptyValueSubPurpose);
            } else {
              isValid =
                isEmpty(checkDifferValueSubPurpose) ||
                !isEmpty(checkEmptyValueSubPurpose);
            }
        } else {
          const checkEmptyValuePurpose = formValue?.purposeQuestions?.filter(
            (it) => isEmpty(it.title) || isEmpty(it.hintText)
          );
          const checkEmptyValueSubPurpose = formValue?.subPurposes?.filter(
            (it) => isEmpty(it.name)
          );

          if (formValue.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT)
            isValid =
              isEmpty(formValue?.purposeQuestions) ||
              !isEmpty(checkEmptyValuePurpose);

          if (
            [
              CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
              CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
            ].includes(formValue.purposeType)
          )
            isValid =
              isEmpty(formValue?.subPurposes) ||
              !isEmpty(checkEmptyValueSubPurpose);
        }
      }
    } else {
      if (!isEmpty(formValue) && !isEmpty(defaultData)) {
        if (formValue.purposeType !== defaultData.purposeType) {
          switch (formValue.purposeType) {
            case CLINIC_SERVICE_TYPE.INPUT_TEXT:
              isValid = isEmpty(formValue?.purposeQuestions);
              break;
            case CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE:
            case CLINIC_SERVICE_TYPE.SINGLE_CHOICE:
              isValid = isEmpty(formValue?.subPurposes);
              break;
            default:
              isValid = false;
              break;
          }
        }
        const checkDifferValuePurpose = formValue?.purposeQuestions?.filter(
          (obj1) => {
            return defaultData?.purposeQuestions?.every((obj2) => {
              return (
                obj1.title !== obj2.title ||
                obj1.hintText !== obj2.hintText ||
                obj1.order !== obj2.order
              );
            });
          }
        );
        const checkEmptyValuePurpose = formValue?.purposeQuestions?.filter(
          (it) => isEmpty(it.title) || isEmpty(it.hintText)
        );
        const checkDifferValueSubPurpose = formValue?.subPurposes?.filter(
          (obj1) => {
            return defaultData?.subPurposes?.every((obj2) => {
              return obj1.name !== obj2.name || obj1.order !== obj2.order;
            });
          }
        );
        const checkEmptyValueSubPurpose = formValue?.subPurposes?.filter((it) =>
          isEmpty(it.name)
        );
        if (formValue.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT)
          if (
            !isEmpty(formValue?.purposeQuestions) &&
            formValue?.purposeQuestions?.length !==
              defaultData?.purposeQuestions?.length
          ) {
            isValid = !isEmpty(checkEmptyValuePurpose);
          } else {
            isValid =
              isEmpty(checkDifferValuePurpose) ||
              !isEmpty(checkEmptyValuePurpose);
          }
        if (
          [
            CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
            CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
          ].includes(formValue.purposeType)
        )
          if (
            !isEmpty(formValue?.subPurposes) &&
            formValue?.subPurposes?.length !== defaultData?.subPurposes?.length
          ) {
            isValid = !isEmpty(checkEmptyValueSubPurpose);
          } else {
            isValid =
              isEmpty(checkDifferValueSubPurpose) ||
              !isEmpty(checkEmptyValueSubPurpose);
          }
      }
    }

    return isValid || isEmpty(formValue.name);
  }, [formValue, defaultData]);

  const isDisableCreateBtn = useMemo(() => {
    let isValid = false;

    const checkDifferValuePurpose = formValue?.purposeQuestions?.filter(
      (obj1) => {
        return defaultData?.purposeQuestions?.every((obj2) => {
          return (
            obj1.title !== obj2.title ||
            obj1.hintText !== obj2.hintText ||
            obj1.order !== obj2.order
          );
        });
      }
    );

    const checkEmptyValuePurpose = formValue?.purposeQuestions?.filter(
      (it) => isEmpty(it.title) || isEmpty(it.hintText)
    );

    const checkDifferValueSubPurpose = formValue?.subPurposes?.filter(
      (obj1) => {
        return defaultData?.subPurposes?.every((obj2) => {
          return obj1.name !== obj2.name || obj1.order !== obj2.order;
        });
      }
    );

    const checkEmptyValueSubPurpose = formValue?.subPurposes?.filter((it) =>
      isEmpty(it.name)
    );

    if (formValue.name !== defaultData.name) {
      isValid = true;
    }

    if (!isEmpty(formValue) && !isEmpty(defaultData)) {
      if (formValue.purposeType !== defaultData.purposeType) {
        switch (formValue.purposeType) {
          case CLINIC_SERVICE_TYPE.INPUT_TEXT:
            isValid = !isEmpty(formValue?.purposeQuestions);

            break;

          case CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE:
          case CLINIC_SERVICE_TYPE.SINGLE_CHOICE:
            isValid = !isEmpty(formValue?.subPurposes);
            break;

          default:
            isValid = true;
            break;
        }

        if (formValue.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT)
          if (
            !isEmpty(formValue?.purposeQuestions) &&
            formValue?.purposeQuestions?.length !==
              defaultData?.purposeQuestions?.length
          ) {
            isValid =
              !isEmpty(checkDifferValuePurpose) ||
              !isEmpty(checkEmptyValuePurpose);
          } else {
            isValid =
              !isEmpty(checkDifferValuePurpose) ||
              isEmpty(checkEmptyValuePurpose);
          }
        if (
          [
            CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
            CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
          ].includes(formValue.purposeType)
        )
          if (
            !isEmpty(formValue?.subPurposes) &&
            formValue?.subPurposes?.length !== defaultData?.subPurposes?.length
          ) {
            isValid =
              !isEmpty(checkDifferValueSubPurpose) ||
              !isEmpty(checkEmptyValueSubPurpose);
          } else {
            isValid =
              !isEmpty(checkDifferValueSubPurpose) ||
              isEmpty(checkEmptyValueSubPurpose);
          }
      } else {
        isValid = formValue.name !== defaultData.name;
      }
    }

    return isValid;
  }, [formValue, defaultData]);

  useEffect(() => {
    clinicServiceDispatcher.getPurposesData();

    return () => clinicServiceDispatcher.resetData();
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      const list = data.map((it) =>
        MAPPING_CLINIC_SERVICE_ICON.findIndex((d) => d.key === it.name) !== -1
          ? {
              ...it,
              icon: MAPPING_CLINIC_SERVICE_ICON.find((d) => d.key === it.name)
                .icon,
            }
          : { ...it, icon: ClinicInactive }
      );
      setListServices(list);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(data) && data[currentTab]?.id) {
      clinicServiceDispatcher.getDetailPurpose(
        data[currentTab]?.id,
        (result) => {
          const list = data.map((it) =>
            MAPPING_CLINIC_SERVICE_ICON.findIndex((d) => d.key === it.name) !==
            -1
              ? {
                  ...it,
                  icon: MAPPING_CLINIC_SERVICE_ICON.find(
                    (d) => d.key === it.name
                  ).icon,
                }
              : { ...it, icon: ClinicInactive }
          );
          setListServices(list);

          setDefaultData({
            ...result,
            subPurposes: isEmpty(result.subPurposes)
              ? result.subPurposes
              : result.subPurposes?.sort((a, b) => a.order - b.order),
            purposeQuestions: isEmpty(result.purposeQuestions)
              ? result.purposeQuestions
              : result.purposeQuestions?.sort((a, b) => a.order - b.order),
          });
          setFormValue({
            ...result,
            subPurposes: isEmpty(result.subPurposes)
              ? result.subPurposes
              : result.subPurposes?.sort((a, b) => a.order - b.order),
            purposeQuestions: isEmpty(result.purposeQuestions)
              ? result.purposeQuestions
              : result.purposeQuestions?.sort((a, b) => a.order - b.order),
          });
        }
      );
    }
  }, [currentTab, data]);

  return (
    <CustomPaperContainer
      isDialog
      btnLabel="Save"
      disabled={loading || isDisableSaveBtn || !formValue.purposeType}
      onClick={handleSaveForm}
      header={
        <CustomHeader
          isSearch={false}
          title={'Clinic services'}
          renderButton={() => (
            <Button
              color="primary"
              variant="contained"
              startIcon={<CreateNewIcon />}
              disabled={isCreated || isDisableCreateBtn}
              onClick={() => {
                setIsCreated(true);
                const newServiceList = cloneDeep(listServices);
                newServiceList.push(defaultListItem);
                setFormValue(defaultService);
                setListServices(newServiceList);
                setDefaultData({});
                setCurrentTab(newServiceList.length - 1);
              }}
            >
              Create new
            </Button>
          )}
        />
      }
    >
      <Divider light />
      <Box height="calc(100vh - 142px)" margin="0 auto">
        {isEmpty(listServices) ? (
          <Box
            height="calc(100vh - 210px)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box className={classes.noDataHelper}>
              <NoDataServicesIcon />
              <Typography className="title-text">No services</Typography>
              <Typography>Please create a new service</Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" height="calc(100vh - 210px)">
            <CustomTabs
              title="All services"
              renderLabel="name"
              renderKey="id"
              orientation="vertical"
              height="calc(100vh - 210px)"
              listTab={listServices}
              value={currentTab}
              onChange={(e, val) => {
                if (isDisableCreateBtn) {
                  customToast(
                    'error',
                    "Please click 'Save' button before open another service tab."
                  );
                } else setCurrentTab(val);
              }}
              btnWidth={216}
              boxStyle={{ justifyContent: 'flex-start', gap: 8 }}
              disabled={isCreated}
            />
            <Box
              component="main"
              height={'calc(100vh - 210px)'}
              width={'100%'}
              overflow="scroll"
            >
              <ServiceItem
                currentTab={currentTab}
                formValue={formValue}
                setFormValue={setFormValue}
                onChange={handleChangeForm}
                onDelete={() => setShowDeleteModal(true)}
              />
            </Box>
          </Box>
        )}
      </Box>

      {showDeleteModal && (
        <ModalConfirmation
          open={showDeleteModal}
          onClick={onDeleteService}
          onClose={() => setShowDeleteModal(false)}
          mainContent={'Delete service'}
          subContent={'Are you sure you want to delete this service?'}
          cancelLabel="Cancel"
          confirmLabel="Delete"
          confirmColor="red"
          Icon={<DeleteModalIcon />}
        />
      )}
    </CustomPaperContainer>
  );
};

export default ClinicServices;
