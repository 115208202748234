import { apiKey as webApiKey } from '../../../../firebaseConfig.json';
import { debounced } from '../../../../helpers/CommonHelper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const InputAddressEnhance = ({
  onSelect,
  addressSelected,
  isSubmitted,
  errors,
  field,
  disabled,
  label = 'Address',
  variant = 'outlined',
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${webApiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    loaded.current = true;
  }

  useEffect(() => {
    addressSelected && setValue(addressSelected);
  }, [addressSelected]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debounced(() => {
      autocompleteService.current.getPlacePredictions(
        { input: inputValue },
        (results) => {
          if (!active) {
            return;
          }
          let newOptions = [];
          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      );
    }, 500);

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      disabled={disabled}
      id="google-map-demo"
      style={{ width: '100%', marginTop: 5 }}
      variant={variant}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      value={value}
      onChange={(event, newValue) => {
        if (newValue) {
          const placeId = get(newValue, 'place_id');
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ placeId }, (results) => {
            const position = get(results, '[0].geometry.location');
            onSelect({
              description: get(newValue, 'description'),
              lat: position.lat(),
              lng: position.lng(),
            });
          });
        } else {
          onSelect({
            description: '',
            lat: null,
            lng: null,
          });
        }

        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          error={isSubmitted && errors[field] ? true : false}
          helperText={isSubmitted && errors[field]}
          {...params}
          label={label}
          disabled={disabled}
          fullWidth
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                {get(option, 'description')}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

InputAddressEnhance.propTypes = {
  onSelect: PropTypes.func,
  addressSelected: PropTypes.string,
  isSubmitted: PropTypes.bool,
  field: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

InputAddressEnhance.defaultProps = {
  onSelect: () => null,
  addressSelected: '',
  isSubmitted: false,
  field: 'address',
  errors: {
    address: 'Required',
  },
  disabled: false,
};

export default InputAddressEnhance;
