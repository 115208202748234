import { CHANNEL_DISPLAY } from '../constants';
import { handleSelectAll } from '@/helpers';
import CustomButton from '@/new-components/CustomButton';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  TextField,
  withStyles,
} from '@material-ui/core';
import { isBoolean, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))(DialogContent);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const ModalEditAngelNudge = ({ open, onClose, onSubmit, selectedItem }) => {
  const [formValue, setFormValue] = useState({
    timing: '',
    channel: [],
  });

  const listChannels = Object.values(CHANNEL_DISPLAY).map((d) => ({
    key: d,
    value: d,
  }));

  useEffect(() => {
    if (!selectedItem) return;
    const newFormValue =
      {
        timing: selectedItem.timing || '',
        channel: selectedItem.channel.split(',').map((d) => d.trim()),
      } || {};

    setFormValue(newFormValue);
  }, [selectedItem]);

  const handleChangeForm = (key) => (e) => {
    if (!setFormValue) return;

    let value;
    switch (key) {
      case 'channel':
        value = handleSelectAll(
          e.target.value,
          Object.values(CHANNEL_DISPLAY),
          formValue?.channel
        );
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = Object.keys(formValue);
    return filterKeys.some((key) =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
  }, [formValue]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>
        Edit {selectedItem?.item}
      </CustomModalTitle>
      <CustomDialogContent dividers>
        <CustomInputLabel>Timing (All 12pm)</CustomInputLabel>
        <CustomTextField
          placeholder="Enter text"
          value={formValue.timing}
          variant="outlined"
          fullWidth
          onChange={handleChangeForm('timing')}
        />
        <CustomInputLabel style={{ marginTop: '20px' }}>
          Channels
        </CustomInputLabel>
        <CustomSelectContainer
          filterValue={formValue.channel || []}
          data={listChannels}
          renderValue={<>{formValue.channel?.join(', ') || 'Select'}</>}
          height="48px"
          fontSize="16px"
          colorInput="#000000de"
          hideClear
          onChange={handleChangeForm('channel')}
          placeholder="Select"
          useSelectAll
          checkedAll={listChannels?.length === formValue.channel?.length}
        />
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={isEmptyParams}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalEditAngelNudge;
