import CustomSelect from '../../components/CustomSelect';
import MutipleSelection from '../../components/CustomSelect/MutipleSelection';
import DeleteModal from '../../components/DeleteModal';
import MenuAction from '../../components/MenuAction';
import TableContainer from '../../components/Table/TableContainer';
import { uploadImage } from '../../firebase';
import { getNationalNumber, filterObjectByKeys } from '../../helpers';
import clinicDispatcher from '../clinic/actions';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../common/constants/typeModal';
import { ROLE_TYPE } from '../role-management/constants';
import AdminModal from './AdminModal';
import adminDispatcher from './action';
import {
  ROLE_FIELD,
  DEFAULT_FIELD,
  SINGLE_IMAGE,
  LIST_IMAGES,
  ROLE_STATUS,
  COLOR_STATUS,
  ROLES_CANNOT_UPDATE,
} from './constants';
import renderColumn from './renderColumn';
import renderListAction from './renderListAction';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton,
} from '@material-ui/core';
import { MoreVertRounded, ArtTrackOutlined } from '@material-ui/icons';
import { isEmpty, get, find } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const AdminManagement = () => {
  const { paging, list, loading, listAdminRole } = useSelector(
    (state) => state.admin
  );
  const { list: listClinic } = useSelector((state) => state.clinic);
  const { userInfo, roleType } = useSelector((state) => state.auth);
  const [adminSelected, setAdminSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  //filter role
  const [filterOption, setFilterOption] = useState({});
  const [openFilterRole, setOpenFilterRole] = useState(false);
  const [roleFilter, setRoleFilter] = useState([]);
  // Ingore create role
  const tableColumn = renderColumn();
  // filter clinic
  const [clinicFilter, setClinicFilter] = useState([]);
  const isClinicManager = roleType === 'ClinicManager';

  useEffect(() => {
    adminDispatcher.getAdminRole();
    clinicDispatcher.getAllClinic();
  }, []);

  useEffect(() => {
    if (userInfo && isClinicManager) {
      const newListClinic = listClinic.map((clinic) => {
        const foundItem = userInfo?.lstClinic?.find(
          (item) => item.id === clinic.id
        );

        if (foundItem) {
          return { ...clinic, isDisabled: false };
        } else return { ...clinic, isDisabled: true };
      });
      setClinicFilter(newListClinic);
    } else {
      setClinicFilter(listClinic);
    }
  }, [listClinic, userInfo, isClinicManager]);

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteAdmin = () => {
    adminDispatcher.deleteData(adminSelected.id, searchKey, filterOption);
    setDeleteModal(false);
  };

  const onHandleModal = (type) => {
    switch (type) {
      case 'delete':
        onShowDeleteModal();
        break;
      case 'edit': {
        setShowModal(true);
        setTypeModal(TYPE_MODAL.Edit);
        setAnchorEl(null);
        break;
      }
      default:
        break;
    }
  };

  const onSubmitSuccess = () => {
    adminDispatcher.getData(searchKey, paging, filterOption);
    setShowModal(false);
    setTypeModal(null);
  };

  const onSubmitAdmin = async (formValue) => {
    adminDispatcher.setState('loading', true);
    //filter field by role
    const requiredField = DEFAULT_FIELD.concat(
      ROLE_FIELD[get(formValue, 'roleType.roleType', formValue.roleType)]
    );
    //filter object field
    let values = filterObjectByKeys(formValue, requiredField);
    const formProps = Object.getOwnPropertyNames(values);

    //async upload images
    const singleImgProps = formProps.filter((prop) =>
      SINGLE_IMAGE.includes(prop)
    );
    if (!!singleImgProps.length) {
      const listSingleImg = await Promise.all(
        singleImgProps.map(async (prop) => {
          if (values[prop]?.constructor === File) {
            const urlPath = await uploadImage(values[prop]);
            return { url: urlPath, key: prop };
          } else return { url: values[prop], key: prop };
        })
      );
      listSingleImg.forEach((img) => {
        values[img.key] = img.url;
      });
    }
    //
    if (formProps.includes(LIST_IMAGES) && !isEmpty(values[LIST_IMAGES])) {
      const listImgs = await Promise.all(
        values[LIST_IMAGES].map(async (file) => {
          if (file?.constructor === File) {
            const urlPath = await uploadImage(file);
            return urlPath;
          } else return file;
        })
      );
      values[LIST_IMAGES] = listImgs;
    }
    //format roleType
    values.roleType = get(formValue, 'roleType.roleType', formValue.roleType);
    if (typeModal === TYPE_MODAL.Create) {
      adminDispatcher.createUser(values, onSubmitSuccess);
    } else if (typeModal === TYPE_MODAL.Edit) {
      adminDispatcher.updateUser(
        { id: adminSelected.id, data: values },
        onSubmitSuccess
      );
    }
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFilted
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  const listActions = renderListAction(onHandleModal);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {<MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="User Management"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={adminDispatcher.getData}
        filterOption={filterOption}
        onAddNew={() => {
          setAdminSelected(null);
          setShowModal(true);
          setTypeModal(TYPE_MODAL.Create);
        }}
        ToolbarComponent={
          <CustomSelect
            onClick={() => {
              setOpenFilterRole(!openFilterRole);
            }}
            clearable={!isEmpty(roleFilter)}
            onClear={() => {
              setRoleFilter([]);
              setFilterOption({
                ...filterOption,
                roleType: [],
              });
            }}
            prefixIcon={
              <ArtTrackOutlined
                style={{
                  fontSize: 20,
                  color: '#E9E9E9',
                  marginRight: 8,
                }}
              />
            }
            renderValue={
              <>
                {roleFilter.map((role) => role.label).join(', ') ||
                  'Select Role'}
              </>
            }
          >
            <MutipleSelection
              value={roleFilter}
              data={listAdminRole}
              open={openFilterRole}
              key="roleType"
              label="label"
              onChange={(e) => {
                const role = get(e, 'target.value').map(
                  (item) => item.roleType
                );
                setFilterOption({
                  ...filterOption,
                  roleType: role,
                });
                setRoleFilter(e.target.value);
              }}
            />
          </CustomSelect>
        }
        renderRow={(row) => (
          <React.Fragment key={row.id}>
            <TableCell>
              {!hiddenRows.fullName && isEmpty(row.firstName)
                ? row.fullName
                : row.firstName + ' ' + row.lastName}
            </TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            {/* <TableCell>
              {!hiddenRows.mobile &&
                getNationalNumber(row.mobile, row.countryCode, true)}
            </TableCell> */}
            <TableCell>
              {!hiddenRows.roleType &&
                find(ROLE_TYPE, ['roleType', row.roleType])?.label}
            </TableCell>
            <TableCell>
              {!hiddenRows.approveStatus && (
                <StatusInfo
                  status={
                    row[
                      ROLE_STATUS.Approved.includes(row.roleType)
                        ? 'approveStatus'
                        : 'activeStatus'
                    ]
                  }
                >
                  {
                    row[
                      ROLE_STATUS.Approved.includes(row.roleType)
                        ? 'approveStatus'
                        : 'activeStatus'
                    ]
                  }
                </StatusInfo>
              )}
            </TableCell>

            <TableCell align="right">
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setAdminSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Dialog maxWidth="md" open={showModal} onClose={onCloseModal}>
        <AdminModal
          onClose={onCloseModal}
          selectedItem={adminSelected}
          loading={loading}
          searchKey={searchKey}
          typeModal={typeModal}
          onSubmit={onSubmitAdmin}
          listAdminRole={listAdminRole}
          listClinic={clinicFilter}
          isClinicManager={isClinicManager}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={adminSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteAdmin}
          modalName="Admin"
          title={adminSelected.firstName + ' ' + adminSelected.lastName}
        />
      )}
    </>
  );
};

const StatusInfo = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 2px 20px;
  text-align: center;
  width: fit-content;
  background-color: ${(props) => COLOR_STATUS[props.status]};
`;

export default AdminManagement;
