import LoadingButton from '../../../components/LoadingButton';
import UploadCompAnt from '../../../components/UploadCompAnt';
import { blobUrl } from '../../../helpers';
import { validateEmail, validateMobile } from '../../../utils/validation';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import doctorDispatcher from '../action/doctor';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import { UserOutlined } from '@ant-design/icons';
import {
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Fab,
  FormHelperText,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { EditRounded, AddRounded } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { Avatar } from 'antd';
import { get, startCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid',
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 30px 20px',
  fontSize: 14,
};

const footerContainerStyle = {
  display: 'flex',
  padding: '11px 20px 10px 0px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid',
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important',
  },
  disabledLabel: {
    transform: 'translate(2px, 10px) scale(0.75)',
    transformOrigin: 'top left',
    left: 0,
  },
});

const DetailPresenter = ({ onClose, selectedItem, detailModal, searchKey }) => {
  const classes = useStyles();
  const listClinic = useSelector((state) => state.doctor.clinics.list || []);

  const editable = !detailModal.isViewing;
  const variant = detailModal.isViewing ? 'filled' : 'standard';

  const [editableItem, setEditableItem] = useState({ countryCode: 'SG' });
  const [praticisingCertificate, setPraticisingCertificate] = useState(null);
  const [error, setError] = useState({});
  const [showLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!detailModal.isAddNew) {
      setEditableItem({
        ...selectedItem,
        dateOfBirth: moment(selectedItem.dateOfBirth),
      });
      let praticisingCertificate = get(selectedItem, 'praticisingCertificate');
      praticisingCertificate &&
        setPraticisingCertificate(praticisingCertificate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const VALIDATE_FIELDS = [
    'firstName',
    'lastName',
    'countryCode',
    // 'mobile',
    'email',
    ['email', validateEmail, 'Invalid email address'],
    'clinicId',
    'mcr',
  ];

  const validate = () => {
    let isValid = true;
    VALIDATE_FIELDS.every((f) => {
      if (typeof f === 'string') {
        if (!editableItem[f]) {
          setError({
            [f]: `${f === 'mcr' ? 'MCR' : startCase(f)} is required`,
          });
          isValid = false;
        }
      } else if (!f[1](editableItem[f[0]])) {
        setError({
          [f[0]]: f[2],
        });
        isValid = false;
      }
      return isValid;
    });
    if (!isValid) return isValid;
    else if (!praticisingCertificate) {
      setError({
        ...error,
        praticisingCertificate: true,
      });
      isValid = false;
    } else {
      setError({});
      setLoading(true);
    }
    return isValid;
  };

  const onFinish = () => setLoading(false);

  const renderFooter = () => {
    if (detailModal.isViewing) {
      return <ButtonEnhance onClick={onClose}>Close</ButtonEnhance>;
    } else if (detailModal.isViewing === false) {
      return (
        <>
          <LoadingButton
            showLoader={showLoading}
            onClick={() => {
              let data = {
                ...editableItem,
                praticisingCertificate,
              };
              validate() &&
                doctorDispatcher.editUser(data, onClose, searchKey, onFinish);
            }}
          >
            Save
          </LoadingButton>
          <ButtonEnhance
            onClick={onClose}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
    } else if (detailModal.isApprove)
      return (
        <>
          <LoadingButton
            showLoader={showLoading}
            onClick={() => {
              let data = {
                ...editableItem,
                praticisingCertificate,
                isAdminApprove: true,
              };
              validate() &&
                doctorDispatcher.editUser(data, onClose, searchKey, onFinish);
            }}
          >
            Approve
          </LoadingButton>
          <ButtonEnhance
            background="#EA6B75"
            backgroundHover="#994950"
            onClick={() =>
              doctorDispatcher.approveDoctor(
                editableItem.id,
                onClose,
                false,
                searchKey
              )
            }
          >
            Reject
          </ButtonEnhance>
        </>
      );
    else if (detailModal.isAddNew)
      return (
        <>
          <LoadingButton
            showLoader={showLoading}
            onClick={() => {
              let data = {
                ...editableItem,
                praticisingCertificate,
              };
              validate() &&
                doctorDispatcher.addDoctor(data, searchKey, onClose, onFinish);
            }}
          >
            Create
          </LoadingButton>
          <ButtonEnhance
            onClick={onClose}
            color="#192637"
            background="#E9E9E9"
            backgroundHover="#bababa"
          >
            Cancel
          </ButtonEnhance>
        </>
      );
  };

  const onClinicChange = (e) => {
    setEditableItem({
      ...editableItem,
      clinicId: e.target.value,
    });
  };

  const ProfilePicture = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        position: 'relative',
      }}
    >
      {editable && editableItem.imageUrl && (
        <UploadCompAnt
          id="upload_avatar"
          onChange={(file) => {
            setEditableItem({
              ...editableItem,
              imageUrl: file,
            });
          }}
          editable
          ActionComponent={
            <Fab
              onClick={() => document.getElementById('upload_avatar').click()}
              htmlFor="upload_avatar"
              style={{
                backgroundColor: '#AD5E99',
                color: 'white',
                width: 26,
                height: 26,
                minHeight: 26,
                border: '2px solid white',
                position: 'absolute',
                zIndex: 999,
                bottom: 0,
                left: 60,
              }}
              aria-label="edit"
            >
              <EditRounded style={{ fontSize: 12 }} />
            </Fab>
          }
        />
      )}

      {typeof editableItem.imageUrl === 'object' && editableItem.imageUrl ? (
        <div
          style={{
            backgroundImage: `url(${blobUrl(editableItem.imageUrl)})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: 80,
            height: 80,
            borderRadius: 40,
          }}
        />
      ) : (
        <Avatar src={editableItem.imageUrl} icon={<UserOutlined />} size={80} />
      )}
      {!editable ? (
        <span style={{ marginLeft: 10, color: '#192637' }}>
          Profile Picture
        </span>
      ) : (
        !editableItem.imageUrl && (
          <UploadCompAnt
            id="upload_avatar"
            onChange={(file) => {
              setEditableItem({
                ...editableItem,
                imageUrl: file,
              });
            }}
            editable
            ActionComponent={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#AD5E99',
                  marginLeft: 10,
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
              >
                <AddRounded
                  style={{ fontSize: 20, color: '#AD5E99', marginRight: 5 }}
                />
                Add Profile Picture
              </div>
            }
          />
        )
      )}
    </div>
  );

  return (
    <DialogContent className={classes.root}>
      <div style={titleContainerStyle}>
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        {selectedItem.fullName || 'Create New'}
      </div>
      <div style={bodyContainerStyle}>
        <SectionTitle>1. Personal Information</SectionTitle>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <ProfilePicture />
          </Grid>
          {typeof editableItem.canAccessDoxy === 'boolean' && (
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxDefault />}
                    checkedIcon={<CheckboxActive />}
                    checked={editableItem.canAccessDoxy}
                    color="primary"
                    onChange={(e) =>
                      setEditableItem({
                        ...editableItem,
                        canAccessDoxy: e.target.checked,
                      })
                    }
                  />
                }
                label="Access Doxy Call"
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              error={error.firstName}
              variant={variant}
              id="firstname-input"
              style={{ width: '95%' }}
              label="First Name"
              disabled={!editable}
              value={editableItem.firstName || ''}
              onChange={(e) =>
                setEditableItem({
                  ...editableItem,
                  firstName: e.target.value,
                })
              }
              helperText={
                error.firstName ||
                (detailModal.isAddNew
                  ? 'This will be reflected in all documents (eg receipt, MC etc)'
                  : null)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={error.lastName}
              variant={variant}
              id="lastname-input"
              label="Last Name"
              style={{ width: '95%' }}
              disabled={!editable}
              value={editableItem.lastName || ''}
              onChange={(e) =>
                setEditableItem({
                  ...editableItem,
                  lastName: e.target.value,
                })
              }
              helperText={
                error.lastName ||
                (detailModal.isAddNew
                  ? 'This will be reflected in all documents (eg receipt, MC etc)'
                  : null)
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              error={error.mobile || error.countryCode}
              helperText={error.mobile || error.countryCode}
              variant={variant}
              id="phone-input"
              label="Phone"
              style={{ width: '95%' }}
              disabled={!editable}
              value={editableItem.mobile || ''}
              onChange={(e) =>
                setEditableItem({
                  ...editableItem,
                  mobile: e.target.value,
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneInput
                      disabled={!editable}
                      country={editableItem.countryCode}
                      onChange={(e) =>
                        setEditableItem({
                          ...editableItem,
                          countryCode: e.target.value,
                        })
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              variant={variant}
              error={error.email}
              helperText={error.email}
              id="email-input"
              label="Email"
              style={{ width: '95%' }}
              disabled={!editable}
              value={editableItem.email || ''}
              onChange={(e) =>
                setEditableItem({
                  ...editableItem,
                  email: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              style={{ width: '95%' }}
              error={error.clinicId}
              disabled={!editable}
            >
              <InputLabel
                id="clinic-select-label"
                classes={{
                  disabled: classes.disabledLabel,
                }}
              >
                Clinic
              </InputLabel>
              <Select
                variant={variant}
                disabled={!editable}
                labelId="clinic-select-label"
                id="clinic-select"
                value={editableItem.clinicId || ''}
                onChange={onClinicChange}
              >
                {listClinic.map((i, index) => (
                  <MenuItem
                    value={i.id}
                    key={`${i.id}-${index}`}
                  >{`${i.name}, ${i.address}, ${i.unitNumber}`}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{error.clinicId}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={variant}
              id="mrc-input"
              label="MCR"
              disabled={!editable}
              style={{ width: '95%' }}
              value={editableItem.mcr || ''}
              onChange={(e) =>
                setEditableItem({
                  ...editableItem,
                  mcr: e.target.value,
                })
              }
              error={error.mcr}
              helperText={error.mcr}
            />
          </Grid>
        </Grid>
        <SectionTitle style={{ marginTop: 20, marginBottom: 10 }}>
          2. Identification
        </SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ height: 219, width: '95%' }}>
              <UploadCompAnt
                error={error.praticisingCertificate}
                id="upload_front_id"
                isAddNew={detailModal.isAddNew}
                label="Add Practising Certificate"
                fileData={praticisingCertificate}
                onChange={setPraticisingCertificate}
                editable={editable}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={footerContainerStyle}>{renderFooter()}</div>
    </DialogContent>
  );
};

const SectionTitle = styled.span`
  color: #192637;
  font-weight: 600;
`;

export default DetailPresenter;
