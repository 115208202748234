import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),
  resetData: () => ({}),
  getPurposesData:
    () =>
    async ({ Api, getState }) => {
      let { result, status } = await Api.post(`Purposes/filter`, {});
      status === 200 && clinicServiceDispatcher.getDataSuccess(result);
    },
  getDetailPurpose:
    (id, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(`Purposes/${id}`);
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },
  editPurpose:
    (id, payload, onSuccess, onFail) =>
    async ({ Api }) => {
      let { status, result } = await Api.put(`Purposes/${id}`, payload);
      status === 200 ? onSuccess(result) : onFail();
    },
  createPurpose:
    (payload, onSuccess, onFail) =>
    async ({ Api }) => {
      let { status } = await Api.post(`Purposes`, payload);
      status === 200 ? onSuccess() : onFail();
    },
  deletePurpose:
    (id, onSuccess) =>
    async ({ Api, customToast }) => {
      let { status } = await Api.delete(`Purposes/${id}`);
      if (status === 200) {
        customToast('success', 'Deleted successfully');
        onSuccess();
      }
    },
};

const clinicServiceDispatcher = synthesize('clinicService', mapDispatchToAC);
export default clinicServiceDispatcher;
