export const COLUMN_CLINIC_APPOINTMENT = [
  {
    label: 'Name',
    stateValue: 'name',
  },
  {
    label: 'ID number',
    stateValue: 'idNumber',
  },
  {
    label: 'Purpose',
    stateValue: 'purpose',
  },
  {
    label: 'Type',
    stateValue: 'type',
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date',
  },
  {
    label: 'Location',
    stateValue: 'location',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
  },
];

export const CLINIC_APPT_STATUS = [
  {
    value: 'Upcoming',
    key: 'Upcoming',
  },
  {
    value: 'Completed',
    key: 'Completed',
  },
  {
    value: 'Cancelled',
    key: 'Cancelled',
  },
  {
    value: 'Missed',
    key: 'Missed',
  },
];

export const CLINIC_STATUSES = {
  Upcoming: 'Upcoming',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed',
};

export const TYPE_MODAL = {
  Edit: 'Edit',
  Reschedule: 'Reschedule',
  Create: 'Create',
  CheckedIn: 'Checked in',
};
