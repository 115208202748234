import CustomDate from '../CustomDate';
import { ArrowDown } from '@/assets/svg';
import AsyncAutoComplete from '@/components/AsyncAutoComplete';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import counsellorsManagementDispatcher from '@/module/counsellors-management/action';
import { getFullTimeInDay } from '@/module/setup/utils';
import { TYPE_MODAL } from '@/module/telecounsel-appointments/constants';
import CustomButton from '@/new-components/CustomButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { cloneDeep, get, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText,
  },
  text: {
    fontWeight: 600,
  },
});

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const ModalAddOrEditTelecounselAppt = ({
  typeModal,
  open,
  onClose,
  onSubmit,
  selectedItem,
  isCounsellor,
  loading,
}) => {
  const { timeBooking } = useSelector((state) => state.counsellors);
  const [errors, setErrors] = useState({});

  const [formValue, setFormValue] = useState({
    patient: '',
    doctor: '',
    appointmentType: 'TeleCounsel',
    appointmentDate: moment().startOf('date').format(),
    appointmentTime: '',
  });

  useEffect(() => {
    if (!selectedItem) return;
    const newFormValue = { ...selectedItem } || {};

    newFormValue.patient = {
      id: newFormValue.patientId,
      fullName: newFormValue.patientFullName,
    };

    newFormValue.doctor = {
      id: newFormValue.doctorId,
      fullName: newFormValue.doctorFullName,
    };

    if (newFormValue.appointmentDate) {
      const currentMinutes = moment(selectedItem.appointmentDate).get('minute');
      const currentHours = moment(selectedItem.appointmentDate).get('hour');
      newFormValue.appointmentTime = moment(selectedItem.appointmentDate)
        .set({
          hour: currentHours,
          minute: currentMinutes,
        })
        .format('h:mm A');
    }

    setFormValue(newFormValue);
  }, [selectedItem]);

  const timeListDefault = useMemo(() => {
    if (!timeBooking || !formValue.doctor.id) return [];
    let listTime = cloneDeep(timeBooking).filter((obj) => {
      const appointmentDate = moment(formValue?.appointmentDate).format(
        'YYYY-MM-DD'
      );
      const dateTime = moment(
        `${appointmentDate} ${obj.value}`,
        'YYYY-MM-DD hh:mm A'
      ).toISOString();

      return moment(dateTime).isSameOrAfter(moment());
    });

    return listTime;
  }, [formValue, selectedItem, timeBooking]);

  const handleChangeForm = (key) => (e, timeValue) => {
    let value = get(e, 'target.value');

    if (key === 'appointmentDate') {
      setFormValue({
        ...formValue,
        appointmentTime: '',
        [key]: moment(e).startOf('date').format(),
      });
    } else {
      value = timeValue.value;
      setFormValue({ ...formValue, [key]: value });
    }
  };

  const handleSubmit = async () => {
    const hours = moment(formValue.appointmentTime, 'hh:mm A').get('hour');
    const minutes = moment(formValue.appointmentTime, 'hh:mm A').get('minute');
    const appointmentTime = moment(formValue.appointmentDate).set({
      hours,
      minutes,
    });

    try {
      await validateData(
        'appointmentCounsellingSchema',
        {
          ...formValue,
          appointmentTime,
          typeModal,
        },
        () => {
          const { appointmentTime, appointmentDate, ...body } = formValue;
          const newAppointmentDate = moment(appointmentDate).set({
            hours,
            minutes,
            seconds: 0,
          });
          onSubmit(typeModal, {
            ...body,
            appointmentDate: newAppointmentDate,
          });
          setErrors({});
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = [
      'patient',
      'doctor',
      'appointmentDate',
      'appointmentTime',
    ];
    return filterKeys.some((key) =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
  }, [formValue]);

  useEffect(() => {
    if (formValue?.appointmentDate && formValue.doctor.id) {
      counsellorsManagementDispatcher.getDataBooking({
        date: formValue?.appointmentDate,
        counsellorId: formValue.doctor.id,
      });
    }
  }, [formValue]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomDialogTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create
          ? 'Create appointment'
          : 'Edit appointment'}
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <CustomInputLabel>Client</CustomInputLabel>
        <AsyncAutoComplete
          label="Patient"
          endpoint="Patients/filter-for-teleconsult"
          method="post"
          defaultFilter={{
            appointmentType: 'TeleCounsel',
          }}
          value={formValue.patient}
          setValue={(newValue) =>
            setFormValue({
              ...formValue,
              patient: newValue,
            })
          }
          error={errors.patient}
          getOptionLabel={(option) => option.fullName || ''}
          renderKey="fullName"
          renderInput={(params) => (
            <CustomTextField
              variant="outlined"
              {...params}
              placeholder="Search by client name"
            />
          )}
          disabled={typeModal === TYPE_MODAL.Edit}
          PaperComponent={({ children, ...other }) => (
            <CustomPaper {...other}>{children}</CustomPaper>
          )}
        />

        <CustomInputLabel style={{ marginTop: '20px' }}>
          Assigned counsellor
        </CustomInputLabel>
        <AsyncAutoComplete
          label="Counsellor"
          endpoint="Counsellors/filter"
          method="post"
          defaultFilter={{
            availabilities: [0, 1, 2, 3, 4, 5, 6],
            activeStatuses: ['Active'],
            approveStatuses: ['Approved'],
          }}
          value={formValue.doctor}
          setValue={(newValue) => {
            setFormValue({
              ...formValue,
              appointmentTime: '',
              doctor: newValue,
            });
          }}
          error={errors.doctor}
          getOptionLabel={(option) => option.fullName || ''}
          renderKey="fullName"
          renderInput={(params) => (
            <CustomTextField
              variant="outlined"
              {...params}
              placeholder="Search by counsellor name"
            />
          )}
          disabled={isCounsellor}
          disableClearable
          PaperComponent={({ children, ...other }) => (
            <CustomPaper {...other}>{children}</CustomPaper>
          )}
        />

        <CustomInputLabel style={{ marginTop: '20px' }}>
          Appointment date
        </CustomInputLabel>
        <CustomDate
          selectedDate={
            formValue.appointmentDate
              ? moment(formValue.appointmentDate)
              : undefined
          }
          disablePast
          isFullWidth
          placeholder="Select date"
          clearable={false}
          onChange={handleChangeForm('appointmentDate')}
          style={{ height: 48, fontSize: 16 }}
        />

        <CustomInputLabel style={{ marginTop: '20px' }}>
          Timeslot
        </CustomInputLabel>

        <CustomAutocomplete
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => option.value || ''}
          options={timeListDefault || []}
          value={
            typeModal === TYPE_MODAL.Create
              ? timeListDefault.find(
                  (it) => it?.value === formValue?.appointmentTime
                ) || ''
              : timeListDefault.find(
                  (it) => it?.value === formValue?.appointmentTime
                ) || {
                  key: formValue?.appointmentTime,
                  value: formValue?.appointmentTime,
                }
          }
          onChange={handleChangeForm('appointmentTime')}
          popupIcon={<ArrowDown />}
          renderInput={(params) => (
            <CustomTextField
              variant="outlined"
              placeholder="Select time"
              {...params}
            />
          )}
          disableClearable
          PaperComponent={({ children, ...other }) => (
            <CustomPaper {...other}>{children}</CustomPaper>
          )}
        />
        {errors.appointmentTime && (
          <Typography color="error">{errors.appointmentTime}</Typography>
        )}
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isEmptyParams || loading}
        >
          {typeModal === TYPE_MODAL.Create ? 'Create' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAddOrEditTelecounselAppt.propTypes = {
  typeModal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedItem: PropTypes.object,
};

ModalAddOrEditTelecounselAppt.defaultProps = {
  typeModal: TYPE_MODAL.Create,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet'),
  selectedItem: null,
};

export default ModalAddOrEditTelecounselAppt;
