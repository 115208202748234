import { roleType } from '../../../enum/RequestEnum';
import { getNationalNumber } from '../../../helpers';
import { isEmpty } from 'lodash';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({
    data,
  }),
  getData:
    (search, paging, sortOption = {}, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().ca.paging;
      let { result, status } = await Api.post(`Users/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...sortOption,
        ...filterOption,
        roleType: roleType.CA,
      });
      if (status === 200) {
        caDispatcher.getDataSuccess(result);
      }
    },

  createCA:
    (
      {
        firstName,
        lastName,
        email,
        mobile,
        password,
        countryCode,
        lstClinicId,
      },
      search,
      callback
    ) =>
    async ({ Api }) => {
      let { status } = await Api.post(`Users/createAccount`, {
        firstName,
        lastName,
        email,
        password,
        // mobile: getNationalNumber(mobile, countryCode),
        countryCode,
        roleType: roleType.CA,
        lstClinicId,
      });
      if (status === 200) {
        caDispatcher.getData(search);
        callback();
      }
    },

  deleteCA:
    (id) =>
    async ({ Api, customToast }) => {
      let { status } = await Api.delete(`Users/${id}`);
      if (status === 200) {
        customToast('success', 'Deleted');
        caDispatcher.getData();
      }
    },

  updateCA:
    (
      { id, firstName, lastName, email, mobile, countryCode, lstClinicId },
      search,
      callback
    ) =>
    async ({ Api }) => {
      let { status } = await Api.put(`Users/${id}`, {
        id,
        firstName,
        lastName,
        email,
        mobile: isEmpty(mobile)
          ? undefined
          : getNationalNumber(mobile, countryCode),
        countryCode,
        lstClinicId,
      });
      if (status === 200) {
        caDispatcher.getData(search);
        callback();
      }
    },
};

const caDispatcher = synthesize('ca', mapDispatchToAC);
export default caDispatcher;
