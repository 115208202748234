import { urlLabel } from '@/enum/PermissionEnum';
import { CAQueue } from '@/module/ca-queue';
import ClinicAppointment from '@/module/clinic-appointment';
import ClinicServices from '@/module/clinic-services';
import ClinicListPage from '@/module/clinic/pages/ClinicListPage';

export const clinicRoutes = [
  {
    path: `/${urlLabel.clinicManagement}`,
    component: ClinicListPage,
    exact: true,
  },
  {
    path: `/${urlLabel.queueManagement}`,
    component: CAQueue,
    exact: true,
  },
  {
    path: `/${urlLabel.clinicServices}`,
    component: ClinicServices,
    exact: true,
  },
  {
    path: `/${urlLabel.clinicAppointments}`,
    component: ClinicAppointment,
    exact: true,
  },
];
