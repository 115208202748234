import {
  BuildingPeopleIcon,
  PeopleAddIcon,
  RadioActiveIcon,
  RadioInactiveIcon,
} from '@/assets/svg';
import {
  Box,
  Grid,
  Radio,
  withStyles,
  Typography,
  Card,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';

const CustomCard = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 120,
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid #ccc`,
    borderRadius: 8,

    '& .card-icon': {
      width: 72,
      height: 72,
      color: '#ccc',
    },

    '& .card-text': {
      fontSize: 16,
      fontFamily: 'OpenSans',
      color: '#191919',
    },

    '&:hover': {
      '& .card-icon': {
        color: theme.palette.primary.main,
      },

      '&.MuiPaper-outlined': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },

    '&.selected': {
      '&.MuiPaper-outlined': {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& .card-icon, .card-text': {
        color: theme.palette.primary.main,
      },
      '& .card-text': {
        fontWeight: 600,
      },
    },
  },
}))(Card);

const PatientTypeStep = ({ formValue, setFormValue }) => {
  const onClick = (value) => {
    setFormValue({ ...formValue, patientType: value });
  };
  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomCard
            variant="outlined"
            onClick={() => onClick('minc')}
            className={isEqual('minc', formValue.patientType) ? 'selected' : ''}
          >
            <Box display="flex" alignItems="center">
              <Radio
                checked={isEqual('minc', formValue.patientType)}
                color="primary"
                icon={<RadioInactiveIcon />}
                checkedIcon={<RadioActiveIcon />}
              />
              <Typography className="card-text">MinC User</Typography>
            </Box>

            <PeopleAddIcon className="card-icon" />
          </CustomCard>
        </Grid>

        <Grid item xs={6}>
          <CustomCard
            variant="outlined"
            onClick={() => onClick('nonMinc')}
            className={
              isEqual('nonMinc', formValue.patientType) ? 'selected' : ''
            }
          >
            <Box display="flex" alignItems="center">
              <Radio
                checked={isEqual('nonMinc', formValue.patientType)}
                color="primary"
                icon={<RadioInactiveIcon />}
                checkedIcon={<RadioActiveIcon />}
              />
              <Typography className="card-text">Non MinC User</Typography>
            </Box>

            <BuildingPeopleIcon className="card-icon" />
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientTypeStep;
