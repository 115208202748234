const renderColumn = (isNotUpdate) => {
  const tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullName',
    },
    {
      label: 'Email',
      stateValue: 'email',
    },
    // {
    //   label: 'Mobile',
    //   stateValue: 'mobile'
    // },
    {
      label: 'Role',
      stateValue: 'roleType',
    },
    {
      label: 'Status',
      stateValue: 'approveStatus',
    },
    {
      label: 'Action',
      stateValue: null,
    },
  ];

  if (isNotUpdate) tableComlumn.pop();

  return tableComlumn;
};

export default renderColumn;
