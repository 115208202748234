// import { LocalStreamContainer } from './styled';
import { LocalStreamContainer, LocalSubUserStreamContainer } from './styled';
import AgoraInstanceTeleconsultNow from '@/agora/agoraInstanceTeleconsultNow';
import {
  CallEndIcon,
  VideoCam,
  VideoCamOff,
  Mirco,
  MircoOff,
  ChatMessIcon,
  MincAdmin,
  DesktopIcon,
} from '@/assets/svg';
import {
  Box,
  Fab,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  textName: {
    fontSize: 24,
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#333333',
  },
  textTime: {
    fontSize: 20,
    color: '#333333',
  },

  callEndBtn: {
    width: 56,
    height: 56,
    backgroundColor: '#db3d49',
    borderRadius: 16,
    boxShadow: 'none',
  },
  btn: {
    width: 56,
    height: 56,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: 'none',
  },
  btnSelected: {
    backgroundColor: '#ad5e99',
  },

  btnSmall: {
    width: 24,
    height: 24,
    backgroundColor: 'white',
    borderRadius: '4px !important',
    boxShadow: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  localStream: {
    width: '100%',
    height: '100%',

    borderRadius: 16,
    // backgroundColor: "#333333",

    '&div:nth-child(1)': {
      zIndex: 0,
      borderRadius: 16,
      position: 'absolute',
    },

    '& .text': {
      zIndex: 1,
      color: 'white',
      position: 'absolute',
      bottom: 20,
      left: 20,

      fontSize: 24,
      fontFamily: 'Lato',
      fontWeight: 600,
    },
  },

  iconNewMessage: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    background: '#ea6b75',
    top: 8,
    right: 8,
  },
  bgColor: {
    background:
      'linear-gradient(0deg, #0000001f 0%, rgba(115, 115, 115, 0) 100%)',
  },
});

const StreamMobileFooter = ({
  transform,
  isMuteVideo,
  isMuteAudio,

  isSharing,
  showChat,
  showNRIC,
  setEndModal,

  handleToggleChatMessage,
  hasNewMessage,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const mdBreakpoints = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      position="absolute"
      bottom={0}
      p={5}
      width={'100%'}
      className={classes.bgColor}
    >
      {isSharing ? (
        <LocalSubUserStreamContainer key="sub_user_stream" id="sub_user_stream">
          <Box position="absolute" top={8} right={8} zIndex={2}>
            <Box className={classes.btnSmall}>
              <DesktopIcon color={'#ad5e99'} />
            </Box>
          </Box>
          <Box
            className="mute-video-layer"
            style={{ backgroundColor: '#3776AB' }}
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              style={{ transform: 'translate(-50%, -50%)' }}
              zIndex={2}
              border="unset !important"
            >
              <MincAdmin color={'#E2F2FF'} width="40px" height="40px" />
            </Box>
          </Box>
        </LocalSubUserStreamContainer>
      ) : null}

      <LocalStreamContainer
        key="sub_stream"
        id="sub_stream"
        transform={transform}
        isMuteVideo={isMuteVideo}
        isHighlight={showNRIC || showChat}
        isResponsive={mdBreakpoints}
      >
        {isMuteAudio ? (
          <Box position="absolute" top={8} right={8} zIndex={2}>
            <Box className={classes.btnSmall}>
              <MircoOff color={'#ad5e99'} />
            </Box>
          </Box>
        ) : null}

        <Box className="mute-video-layer">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
            zIndex={2}
            border="unset !important"
          >
            <MincAdmin color={'#FFE9FB'} width="40px" height="40px" />
          </Box>
        </Box>
      </LocalStreamContainer>
      <Box display="flex" justifyContent="center" gridGap={16}>
        {/* <Fab className={classes.callEndBtn} onClick={() => setEndModal(true)}>
          <CallEndIcon />
        </Fab> */}

        <Fab
          className={classes.btn}
          aria-label="enable-video"
          onClick={() => {
            AgoraInstanceTeleconsultNow.instance.muteVideo(!isMuteVideo);
          }}
        >
          {isMuteVideo ? (
            <VideoCamOff color="#ad5e99" />
          ) : (
            <VideoCam color="#ad5e99" />
          )}
        </Fab>
        <Fab
          className={classes.btn}
          aria-label="mute-audio"
          onClick={() => {
            AgoraInstanceTeleconsultNow.instance.muteAudio(!isMuteAudio);
          }}
        >
          {isMuteAudio ? (
            <MircoOff color="#ad5e99" />
          ) : (
            <Mirco color="#ad5e99" />
          )}
        </Fab>
        <Fab
          className={clsx(classes.btn, {
            [classes.btnSelected]: showChat,
          })}
          aria-label="chat"
          onClick={handleToggleChatMessage}
          style={{ position: 'relative' }}
        >
          {hasNewMessage && <div className={classes.iconNewMessage}></div>}
          <ChatMessIcon color={showChat ? 'white' : '#ad5e99'} />
        </Fab>
      </Box>
    </Box>
  );
};

export default StreamMobileFooter;
