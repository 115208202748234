import { CLINIC_SERVICE_TYPE, MAPPING_CLINIC_SERVICE_TYPE } from '../constants';
import ListSubServices from './ListSubServices';
import { RadioActiveIcon, RadioInactiveIcon, TrashIcon } from '@/assets/svg';
import CustomButton from '@/new-components/CustomButton';
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
        paddingLeft: 4,
        marginLeft: 0,
        height: 30,
      },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
})(TextField);

const CustomTitleLabel = withStyles({
  root: {
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 700,
    color: '#000000',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600,
    color: '#666666',
  },
})(InputLabel);

const CustomFormControlLabel = withStyles({
  root: {
    '& .MuiTypography-root': {
      fontWeight: 'unset',
    },
  },
})(FormControlLabel);

const ServiceItem = ({ formValue, setFormValue, onChange, onDelete }) => {
  return (
    <Box px={2.5} py={3} width={'70%'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomTitleLabel>Service</CustomTitleLabel>
            <CustomButton
              onClick={onDelete}
              style={{ fontWeight: 600, minWidth: 'unset', width: 'auto' }}
              color="primary"
              startIcon={<TrashIcon />}
            >
              Delete
            </CustomButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>Service name</CustomInputLabel>
          <CustomTextField
            fullWidth
            autoFocus
            variant="outlined"
            value={formValue.name || ''}
            onChange={onChange('name')}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Sub-services options</CustomInputLabel>

          <RadioGroup name="purposeType" onChange={onChange('purposeType')}>
            {MAPPING_CLINIC_SERVICE_TYPE.map((it, index) => (
              <CustomFormControlLabel
                value={it.key}
                control={
                  <Radio
                    checked={isEqual(it.key, formValue.purposeType)}
                    color="primary"
                    icon={<RadioInactiveIcon />}
                    checkedIcon={<RadioActiveIcon />}
                  />
                }
                style={{
                  fontWeight: isEqual(it.key, formValue.purposeType)
                    ? 600
                    : 400,
                }}
                label={it.text}
              />
            ))}
          </RadioGroup>
        </Grid>

        {formValue.purposeType &&
        formValue.purposeType !== CLINIC_SERVICE_TYPE.NO_SUB_SERVICE ? (
          <>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid item xs={12}>
              <CustomTitleLabel>List of sub-services</CustomTitleLabel>
            </Grid>
            <Grid item xs={12}>
              {formValue.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT ? (
                <ListSubServices
                  fieldKey="purposeQuestions"
                  formValue={formValue}
                  setFormValue={setFormValue}
                />
              ) : (
                <ListSubServices
                  fieldKey="subPurposes"
                  formValue={formValue}
                  setFormValue={setFormValue}
                />
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default ServiceItem;
