import {
  IconGroup,
  SelectContainer,
  SelectContent,
  SelectRenderValue,
} from '../CustomSelect/CustomeSelect.styled';
import { ArrowDown } from '@/assets/svg';
import { CloseCircleFilled } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CustomSelect = ({
  children,
  onClick,
  clearable,
  onClear,
  renderValue,
  prefixIcon,
  suffixIcon,
  isFullWidth,
  width = 300,
  arrowStyle,
  isShowArrow,
  padding,
  height,
  disabled,
  fontSize,
  open,
  colorInput = '#999999',
  placeholder,
  useSelectAll,
  ...rest
}) => {
  const checkPlaceHolder =
    useSelectAll && placeholder
      ? renderValue?.props?.children === placeholder
      : renderValue === placeholder;
  return (
    <SelectContainer
      onClick={onClick}
      {...rest}
      height={height}
      className={disabled && 'disabled'}
    >
      {checkPlaceHolder ? (
        <SelectContent fontSize={fontSize} color={'#999999'}>
          {prefixIcon}
          <SelectRenderValue>{placeholder}</SelectRenderValue>
          {children}
        </SelectContent>
      ) : (
        <SelectContent
          fontSize={fontSize}
          color={clearable ? '#333' : colorInput}
        >
          {prefixIcon}
          <SelectRenderValue>{renderValue}</SelectRenderValue>
          {children}
        </SelectContent>
      )}

      <IconGroup>
        {clearable && (
          <CloseCircleFilled
            className="ant-input-clear-icon"
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          />
        )}
        {isShowArrow && (
          <Box pl={1}>
            <ArrowDown
              style={
                open
                  ? { verticalAlign: 'middle', transform: 'rotate(180deg' }
                  : { verticalAlign: 'middle', ...arrowStyle }
              }
            />
          </Box>
        )}
        {suffixIcon}
      </IconGroup>
    </SelectContainer>
  );
};

CustomSelect.propTypes = {
  isFullWidth: PropTypes.bool,
  colorBorderBottomInput: PropTypes.string,
};

CustomSelect.defaultProps = {
  isFullWidth: false,
  colorBorderBottomInput: '#E9E9E9',
};

export default CustomSelect;
