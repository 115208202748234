import { createDownloadableLink, formatUTCDate } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),
  resetData: () => ({}),
  getData:
    (search, paging, sortOption = {}, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().telecounselAppointment.paging;
      let { result, status } = await Api.post(`ClinicAppointments/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption,
        ...sortOption,
      });
      status === 200 && clinicApptDispatcher.getDataSuccess(result);
    },
  createClinicAppointment:
    (payload, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.post(`ClinicAppointments`, payload);
      status === 200 && onSuccess();
    },

  editClinicAppointment:
    (id, payload, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.put(`ClinicAppointments/${id}`, payload);
      status === 200 && onSuccess();
    },
  checkInClinicAppointment:
    (id, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.put(`ClinicAppointments/${id}/check-in`);
      status === 200 && onSuccess();
    },
  cancelClinicAppointment:
    (id, onSuccess) =>
    async ({ Api }) => {
      let { status } = await Api.put(`ClinicAppointments/${id}/cancel`);
      status === 200 && onSuccess();
    },
  getExportExcel:
    (params) =>
    async ({ Api }) => {
      let { result, status } = await Api.post(`ClinicAppointments/csv-report`, {
        ...params,
        fromDate: params?.fromDate
          ? formatUTCDate(params?.fromDate, { isStarOfDay: true })
          : undefined,
        endDate: params?.endDate
          ? formatUTCDate(params?.endDate, { isEndOfDay: true })
          : undefined,
      });

      const fileName = `Clinic_Appointment_Report`;
      status === 200 && createDownloadableLink(result, fileName);
    },
  getDetailClinic:
    (id, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(`ClinicAppointments/${id}`);
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },
};

const clinicApptDispatcher = synthesize('clinicAppt', mapDispatchToAC);
export default clinicApptDispatcher;
