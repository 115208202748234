import minkConfig from '../../mink.config.json';
import { authDispatcher } from '../auth';
import teleConsultNowDispatcher from './action';
import CorporatePlanStep from './component/CorporatePlanStep';
import StreamMobilePanel from './component/StreamCall/StreamMobilePanel';
import SymptomsStep from './component/SymptomsStep';
import AgoraInstanceTeleconsultNow from '@/agora/agoraInstanceTeleconsultNow';
import { AvatarCallIcon, RejoinCallIcon, WaitingCallIcon } from '@/assets/svg';
import { getListChannels, listenChannels, setCurrentUserId } from '@/firebase';
import {
  endCall,
  initHubConnection,
  joinCall,
  joinGroup,
  rejoinCall,
} from '@/helpers/patientTeleconsultRoomSignalR';
import CustomButton from '@/new-components/CustomButton';
import ModalConfirmationMobile from '@/new-components/CustomModal/ModalConfirmationMobile';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  IconButton,
  withStyles,
  Typography,
  LinearProgress,
  makeStyles,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Progress from 'react-progress-2';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  cardEndCallInfo: {
    background: 'linear-gradient(180deg, #FEFFFF 0%, #EDF7FF 100%)',
    border: '1px solid #3776AB33',
    borderRadius: 10,
    width: 'fit-content',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    gap: 16,

    '& .title': {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: 18,
    },
    '& .text': {
      fontFamily: 'OpenSans',
      fontSize: 16,
    },
  },
});

const CustomTitle = withStyles({
  root: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomLink = withStyles((theme) => ({
  root: {
    flex: 2,
    width: '100%',
    height: 48,
    borderRadius: 8,

    backgroundColor: theme.palette.primary.main,
    color: theme.palette.general.white,

    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ' 10px 20px',

    '& .label': {
      fontSize: 18,
      fontFamily: 'OpenSans',
      fontWeight: 400,
      textTransform: 'unset',
    },

    '&:hover': {
      color: theme.palette.general.white,
    },

    '&.MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
  },
}))(Link);

const CustomLinearProgress = withStyles({
  root: {
    '&.MuiLinearProgress-colorPrimary': {
      background: '#E2F2FF',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      background: '#3776AB',
    },
  },
})(LinearProgress);

export const TELECONSULT_NOW_PROCESS_STEPS = {
  SYMPTOMS: 'symptoms',
  CORPORATE_PLAN: 'corporatePlan',
};

const TeleconsultNow = () => {
  const classes = useStyles();
  const {
    userInfo: currentUser,
    accessToken,
    notifyToken,
    startCallTime,
  } = useSelector((state) => state.auth);

  const { patientInfo } = useSelector((state) => state.globalState);
  const {
    clinics,
    waitingModalVisibility,
    calling,
    waitingRoomStatus,
    rejoinCallData,
    callData,
    channelId,
    partner,
    completedCallVisibility,
    rejoinModalVisibility,
    callProblem,
  } = useSelector((state) => state.teleConsultNow);

  const [symptomsSelected, setSymptomsSelected] = useState([]);
  const [valueOption, setValueOption] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [valueCorporate, setValueCorporate] = useState('');

  const [listChannels, setListchannels] = useState([]);
  const [errors, setErrors] = useState({});

  const listChannelsRef = useRef();
  listChannelsRef.current = listChannels;

  const [formValue, setFormValue] = useState({
    memberId: '',
    symptoms: '',
    anyMedication: false,
    anyDrugAllergy: false,

    corperatePlan: '',
    frontCorperatePlan: '',
    backCorperatePlan: '',

    clinicId: '',
    deviceType: 'IOS',
    promotionCode: '',

    notifyToken: '',
    appointmentId: '',
    haveAppointment: false,

    consultType: 'Teledoc',
    haveToRotateView: false,
    drugAllergyDetails: '',
    concerns: '',
    isDoxyConsult: true,
  });

  const [duration, setDuration] = useState(
    isNaN(new moment(rejoinCallData?.CallProblemTime).unix())
      ? 300
      : new moment(rejoinCallData?.CallProblemTime).unix() +
          minkConfig.DOCTOR_REJOIN_TIMEOUT -
          new moment().unix()
  );

  const [durationWaitingCall, setDurationWaitingCall] = useState(180);

  const handleBack = () =>
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  const handleNext = () =>
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  const handleChangeValueOption = (_, value) => {
    setValueOption(value);
  };

  const doCalling = async () => {
    teleConsultNowDispatcher.setState('calling', true);
    teleConsultNowDispatcher.setState('waitingModalVisibility', false);
    await joinCall();
  };

  const doJoinHub = async () => await joinGroup();

  const resetData = () => {
    setCurrentStep(0);
    setSymptomsSelected([]);
    setValueOption(null);
    setSearchKey('');
    setFormValue({
      ...formValue,
      symptoms: '',
      anyMedication: false,
      anyDrugAllergy: false,

      corperatePlan: '',
      frontCorperatePlan: '',
      backCorperatePlan: '',

      deviceType: 'IOS',
      promotionCode: '',

      appointmentId: '',
      haveAppointment: false,

      consultType: 'Teledoc',
      haveToRotateView: false,
      drugAllergyDetails: '',
      concerns: '',
    });
  };

  const handleCallNow = () => {
    Progress.show();
    setShowLoading(true);
    // Check promo code valid
    if (!isEmpty(formValue.promotionCode))
      teleConsultNowDispatcher.checkValidPromotionsCode(
        formValue.promotionCode,
        (result) => {
          Progress.hide();
          setShowLoading(false);
          if (result) {
            setErrors({});
            if (valueCorporate === 'new') {
              teleConsultNowDispatcher.addCorporatePlan(
                formValue,
                () => {
                  Progress.hide();
                  setShowLoading(false);
                  teleConsultNowDispatcher.joinPTWaitingRoom(
                    { ...formValue, symptoms: searchKey },
                    () => {
                      Progress.hide();
                      setShowLoading(false);
                      teleConsultNowDispatcher.setState(
                        'waitingModalVisibility',
                        true
                      );
                      doJoinHub();
                      // doCalling();
                    }
                  );
                },
                () => {
                  Progress.hide();
                  setShowLoading(false);
                }
              );
            } else
              teleConsultNowDispatcher.joinPTWaitingRoom(
                { ...formValue, symptoms: searchKey },
                () => {
                  Progress.hide();
                  setShowLoading(false);
                  teleConsultNowDispatcher.setState(
                    'waitingModalVisibility',
                    true
                  );
                  doJoinHub();
                  // doCalling();
                }
              );
          } else {
            setErrors({
              promotionCode: 'This promo code is not valid',
            });
          }
        }
      );
    else {
      if (valueCorporate === 'new') {
        teleConsultNowDispatcher.addCorporatePlan(
          formValue,
          () => {
            Progress.hide();
            setShowLoading(false);
            teleConsultNowDispatcher.joinPTWaitingRoom(
              { ...formValue, symptoms: searchKey },
              () => {
                Progress.hide();
                setShowLoading(false);
                teleConsultNowDispatcher.setState(
                  'waitingModalVisibility',
                  true
                );
                doJoinHub();
                // doCalling();
              }
            );
          },
          () => {
            Progress.hide();
            setShowLoading(false);
          }
        );
      } else
        teleConsultNowDispatcher.joinPTWaitingRoom(
          { ...formValue, symptoms: searchKey },
          () => {
            Progress.hide();
            setShowLoading(false);
            teleConsultNowDispatcher.setState('waitingModalVisibility', true);
            doJoinHub();
            // doCalling();
          }
        );
    }
  };

  const handleChangeForm = (key) => (e, newValue) => {
    let value;

    switch (key) {
      case 'frontCorperatePlan':
      case 'backCorperatePlan':
        const FILE_SIZE = 2 * 1024 * 1024; // ~2mb
        if (get(e, 'size') > FILE_SIZE) {
          customToast('error', 'Image is too large');
          return;
        }
        value = e;
        break;

      default:
        value = get(e, 'target.value');
        break;
    }
    setFormValue({ ...formValue, [key]: value });
  };

  const handleClearForm = (key) => {
    setFormValue({ ...formValue, [key]: '' });
  };

  const handleCloseWaitingModalVisibility = () => {
    teleConsultNowDispatcher.setState('waitingModalVisibility', false);
  };

  const handleCloseRejoinModalVisibility = () => {
    teleConsultNowDispatcher.setState('rejoinModalVisibility', false);
  };

  const handleCancelCall = () => {
    resetData();
    handleCloseWaitingModalVisibility();
    teleConsultNowDispatcher.leaveWaitingRoom();
  };

  const handleCancelRejoinCall = async () => {
    resetData();
    await endCall(rejoinCallData?.DoctorId);
    AgoraInstanceTeleconsultNow.instance.leaveChannel({
      leaveOnlyShareScreen: false,
    });
    teleConsultNowDispatcher.leaveWaitingRoom();
    handleCloseRejoinModalVisibility();
  };

  const initFirebase = () => {
    setCurrentUserId(currentUser.id, currentUser);
    updateListChannels();
  };

  const updateListChannels = async () => {
    let listChannels = await getListChannels(currentUser.id);
    listenChannels(currentUser.id, (data) => {
      if (
        [...listChannelsRef.current].find((channel) => channel.id === data.id)
      )
        setListchannels(
          [...listChannelsRef.current].map((i) => (i.id === data.id ? data : i))
        );
      else {
        setListchannels([...listChannelsRef.current, data]);
      }
    });
    setListchannels(listChannels);
  };

  const getPatientChannel = () => {
    return listChannels.find((channel) =>
      channel?.members?.includes(partner?.id)
    );
  };

  const STEPS = [
    {
      key: TELECONSULT_NOW_PROCESS_STEPS.SYMPTOMS,
      name: 'Teleconsult now',
      component: (
        <SymptomsStep
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          selectedItems={symptomsSelected}
          setSelectedItems={setSymptomsSelected}
          handleSubmit={handleCallNow}
        />
      ),
    },
    // {
    //   key: TELECONSULT_NOW_PROCESS_STEPS.CORPORATE_PLAN,
    //   name: 'Teleconsult now',
    //   component: (
    //     <CorporatePlanStep
    //       errors={errors}
    //       valueOption={valueOption}
    //       setValueOption={setValueOption}
    //       formValue={formValue}
    //       setFormValue={setFormValue}
    //       handleChangeForm={handleChangeForm}
    //       handleSubmit={handleCallNow}
    //       loading={showLoading}
    //       handleChangeValueOption={handleChangeValueOption}
    //       valueCorporate={valueCorporate}
    //       setValueCorporate={setValueCorporate}
    //       handleClearForm={handleClearForm}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    teleConsultNowDispatcher.getCorporatePlans();
    teleConsultNowDispatcher.getSymptomsList();
    teleConsultNowDispatcher.checkWaitingRoomStatus();
    teleConsultNowDispatcher.checkAvailableService();
    teleConsultNowDispatcher.getAllClinics();
    setTimeout(() => {
      initHubConnection(accessToken);
    }, 0);

    return () => {
      teleConsultNowDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(patientInfo) && !isEmpty(clinics)) {
      setFormValue({
        ...formValue,
        memberId: patientInfo.id,
        clinicId: clinics[0].id,
        notifyToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo, clinics, notifyToken]);

  useEffect(() => {
    let intervalId;
    if (waitingModalVisibility || callProblem) {
      intervalId = setInterval(() => {
        teleConsultNowDispatcher.checkWaitingRoomStatus();

        if (durationWaitingCall <= 0) {
          resetData();
          teleConsultNowDispatcher.setState('waitingModalVisibility', false);
          teleConsultNowDispatcher.leaveWaitingRoom();
          clearInterval(intervalId);
        }

        setDurationWaitingCall((duration) => duration - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [durationWaitingCall, waitingModalVisibility, callProblem]);

  useEffect(() => {
    let intervalId;

    if (!isEmpty(rejoinCallData)) {
      intervalId = setInterval(async () => {
        if (duration <= 0 || waitingRoomStatus?.id === null) {
          resetData();
          await endCall(rejoinCallData?.DoctorId);
          AgoraInstanceTeleconsultNow.instance.leaveChannel({
            leaveOnlyShareScreen: false,
          });
          teleConsultNowDispatcher.leaveWaitingRoom();
          teleConsultNowDispatcher.setState('callId', rejoinCallData.CallId);
          teleConsultNowDispatcher.setState('rejoinCallData', null);
          teleConsultNowDispatcher.setState('callProblem', false);

          clearInterval(intervalId);
        }
        setDuration((duration) => duration - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, rejoinCallData, waitingRoomStatus]);

  useEffect(() => {
    if (waitingRoomStatus?.hasCallRequest) {
      doCalling();
      handleCloseWaitingModalVisibility();
    }

    if (waitingRoomStatus?.hasRejoinCall) {
      teleConsultNowDispatcher.setState('rejoinModalVisibility', true);
    }
  }, [waitingRoomStatus]);

  useEffect(() => {
    if (currentUser.id) initFirebase();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id]);

  useEffect(() => {
    if (calling) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }
  }, [calling]);

  const formatDuration = (period) => {
    let parts = [];
    const duration = moment.duration(period);

    // return nothing when the duration is falsy or not correctly parsed (P0D)
    if (!duration || duration.toISOString() === 'P0D') return;

    if (duration.seconds() >= 0 && duration.seconds() <= 1) {
      parts.push(1 + 'minute');
    } else {
      if (duration.years() >= 1) {
        const years = Math.floor(duration.years());
        parts.push(years + ' ' + (years > 1 ? 'years' : 'year'));
      }

      if (duration.months() >= 1) {
        const months = Math.floor(duration.months());
        parts.push(months + ' ' + (months > 1 ? 'months' : 'month'));
      }

      if (duration.days() >= 1) {
        const days = Math.floor(duration.days());
        parts.push(days + ' ' + (days > 1 ? 'days' : 'day'));
      }

      if (duration.hours() >= 1) {
        const hours = Math.floor(duration.hours());
        parts.push(hours + ' ' + (hours > 1 ? 'hours' : 'hour'));
      }

      if (duration.minutes() >= 1) {
        const minutes = Math.floor(duration.minutes());
        parts.push(minutes + ' ' + (minutes > 1 ? 'minutes' : 'minute'));
      }
    }

    return parts.join(' ');
  };

  const formatEndCallTime = useMemo(() => {
    if (!callData?.StartCallTime) return;
    const startTime = moment(callData?.StartCallTime);
    const endTime = moment();
    return formatDuration(endTime.diff(startTime));
  }, [callData?.StartCallTime]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py={2.5}
        height={'100dvh'}
      >
        <Box>
          <Box
            px={2.5}
            pb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flex={1}>
              <IconButton onClick={handleBack} disabled={currentStep === 0}>
                <KeyboardArrowLeft />
              </IconButton>
            </Box>
            <Box flex={2} textAlign="center">
              <CustomTitle>{STEPS[currentStep]?.name}</CustomTitle>
            </Box>
            <Box flex={1}></Box>
          </Box>

          <Box display="flex" alignItems="center" gridGap={4}>
            {STEPS.map((it, inx) => (
              <CustomLinearProgress
                style={{ flex: 1 }}
                variant="determinate"
                value={
                  (currentStep === 0 && inx === currentStep) ||
                  currentStep === 1
                    ? 100
                    : 0
                }
                key={it.key}
              />
            ))}
          </Box>
        </Box>
        {!waitingRoomStatus ? (
          <Box
            height="100dvh"
            width="100dvw"
            bgcolor="#fff"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" size={20} />
          </Box>
        ) : (
          STEPS[currentStep]?.component
        )}
      </Box>

      {calling && callData && channelId && (
        <StreamMobilePanel
          startCallTime={startCallTime}
          channelInfo={getPatientChannel()}
          updateListChannels={updateListChannels}
          videoChannelId={channelId}
          callData={rejoinCallData || callData}
          callProblem={callProblem}
        />
      )}

      {completedCallVisibility && (
        <ModalConfirmationMobile
          open={completedCallVisibility}
          mainContent="Video call completed"
          title={<Box height={40}></Box>}
          subContent={
            <Box
              color="#666666"
              fontSize={16}
              px={7}
              mt={2}
              textAlign="center"
              fontFamily={'OpenSans'}
            >
              <p>
                Please check your email for your teleconsultation follow-up and
                payment confirmation.
              </p>
            </Box>
          }
          otherActionsContent={() => (
            <>
              <CustomButton
                style={{ width: '100%', height: 48, flex: 1 }}
                onClick={() => authDispatcher.logout()}
                variant="outlined"
                color="primary"
              >
                Sign out
              </CustomButton>
              <CustomLink href="https://minmed.sg/" target="_blank">
                <span className="label">Visit our website</span>
              </CustomLink>
            </>
          )}
          Icon={
            <Box mt={12.5} className={classes.cardEndCallInfo}>
              <AvatarCallIcon />
              <Box textAlign="left">
                <div className="title">
                  Dr. {callData?.DoctorFirstName} {callData?.DoctorLastName}
                </div>
                {callData?.StartCallTime && (
                  <div className="text">{formatEndCallTime}</div>
                )}
              </Box>
            </Box>
          }
        />
      )}

      {rejoinModalVisibility && (
        <ModalConfirmationMobile
          open={rejoinModalVisibility}
          mainContent="Your call just got disconnected"
          title={<Box height={40}></Box>}
          subContent={
            <Box
              color="#666666"
              fontSize={16}
              px={7}
              mt={2}
              textAlign="center"
              fontFamily={'OpenSans'}
            >
              <p>
                Click on the button “Reconnect" to get back to the call with
                your doctor.
              </p>
            </Box>
          }
          otherActionsContent={() => (
            <>
              <CustomButton
                onClick={handleCancelRejoinCall}
                variant="outlined"
                color="primary"
                style={{ width: '100%', height: 48 }}
                margin="0 16px"
              >
                Cancel call
              </CustomButton>
              <CustomButton
                onClick={() => rejoinCall()}
                variant="contained"
                color="primary"
                style={{ width: '100%', height: 48 }}
                margin="0 16px"
              >
                Reconnect
              </CustomButton>
            </>
          )}
          Icon={<RejoinCallIcon style={{ marginTop: 100 }} />}
        />
      )}

      {waitingModalVisibility && (
        <ModalConfirmationMobile
          open={waitingModalVisibility}
          mainContent="Our doctor will see your shortly"
          title={<Box height={40}></Box>}
          subContent={
            <Box
              color="#666666"
              fontSize={16}
              px={7}
              mt={2}
              textAlign="center"
              fontFamily={'OpenSans'}
            >
              <p>Please wait for a while until our doctor is ready.</p>
            </Box>
          }
          otherActionsContent={() => (
            <CustomButton
              onClick={handleCancelCall}
              variant="contained"
              color="primary"
              style={{ width: '100%', height: 48 }}
              margin="0 16px"
            >
              Cancel call
            </CustomButton>
          )}
          Icon={<WaitingCallIcon style={{ marginTop: 100 }} />}
        />
      )}
    </>
  );
};

export default TeleconsultNow;
