import ServicesItem from '../EditPackage/SelectServices/ServicesItem';
import CustomSurvey from './ServiceItems/CustomSurvey';
import Invoice from '@/module/all-screenings/component/Invoice';
import { generateLstTests } from '@/module/all-screenings/utils';
import CustomTabs from '@/new-components/CustomTabs';
import { makeStyles, Paper, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },

  leftContainer: {
    overflowY: 'hidden',
    flex: '1',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 282px)',
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '16px 40px',
    display: 'flex',
    height: 'calc(100vh - 282px)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 20,
    gap: 8,
  },
  disabledSwitch: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
});

const SelectServiceStep = ({
  paymentStatus,
  projectInfo,
  locationId,
  totalCost,
  totalAmountDefault,
  formValue,
  setFormValue,
  handleChangeData,
  packageDetail,
  setPackageDetail,
  projectCustomFields,
  lstOptionalTests,
  restrictedTestIds,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [errors, setErrors] = useState({});

  const TABS = [
    {
      label: 'Packages',
      chipLabel: formValue?.lstPackages?.length,
      body: (
        <ServicesItem
          isPackage
          projectId={projectInfo.id}
          locationId={locationId}
          dataExist={formValue.lstPackages}
          handleChangeData={handleChangeData}
          filterKey="lstPackages"
          formValue={formValue}
          setFormValue={setFormValue}
          packageDetail={packageDetail}
          setPackageDetail={setPackageDetail}
          restrictedTestIds={restrictedTestIds}
        />
      ),
    },
    {
      label: 'Profile',
      chipLabel: formValue?.lstProfiles?.length,
      body: (
        <ServicesItem
          isProfile
          projectId={projectInfo.id}
          dataExist={formValue.lstProfiles}
          handleChangeData={handleChangeData}
          filterKey="lstProfiles"
        />
      ),
    },
    {
      label: 'Measurement',
      chipLabel: formValue?.lstMeasurements?.length,
      body: (
        <ServicesItem
          category="Measurements"
          projectId={projectInfo.id}
          dataExist={formValue.lstMeasurements}
          handleChangeData={handleChangeData}
          filterKey="lstMeasurements"
        />
      ),
    },
    {
      label: 'Procedure',
      chipLabel: formValue?.lstProcedures?.length,
      body: (
        <ServicesItem
          category="Procedures"
          projectId={projectInfo.id}
          locationId={locationId}
          dataExist={formValue.lstProcedures}
          handleChangeData={handleChangeData}
          filterKey="lstProcedures"
        />
      ),
    },
    {
      label: 'Radiology',
      chipLabel: formValue?.lstRadiologies?.length,
      body: (
        <ServicesItem
          category="Radiologies"
          projectId={projectInfo.id}
          locationId={locationId}
          dataExist={formValue.lstRadiologies}
          handleChangeData={handleChangeData}
          filterKey="lstRadiologies"
        />
      ),
    },
    {
      label: 'Vaccination',
      chipLabel: formValue?.lstVaccinations?.length,
      body: (
        <ServicesItem
          category="Vaccinations"
          projectId={projectInfo.id}
          dataExist={formValue.lstVaccinations}
          handleChangeData={handleChangeData}
          filterKey="lstVaccinations"
        />
      ),
    },
    {
      label: 'Review',
      chipLabel: formValue?.lstReviews?.length,
      body: (
        <ServicesItem
          category="Reviews"
          projectId={projectInfo.id}
          dataExist={formValue.lstReviews}
          handleChangeData={handleChangeData}
          filterKey="lstReviews"
        />
      ),
    },
    {
      label: 'Custom survey',
      chipCustomSurvey: formValue?.lstScreeningFields?.length,
      body: (
        <CustomSurvey
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
          dataDefault={projectCustomFields}
          dataExist={formValue.lstScreeningFields}
        />
      ),
    },
  ];

  return (
    <Paper square elevation={0} className={classes.container}>
      <Box
        className={classes.leftContainer}
        flexDirection="column"
        gridGap={16}
      >
        <Box display="flex">
          <CustomTabs
            listTab={TABS}
            value={currentTab}
            onChange={(e, val) => {
              setCurrentTab(val);
            }}
            renderLabel="label"
            renderKey="label"
            orientation="vertical"
            height="calc(100vh - 282px)"
          />
          {TABS[currentTab].body}
        </Box>
      </Box>

      <Box className={classes.rightContainer}>
        <Invoice
          lstTests={generateLstTests(formValue)}
          lstProfiles={formValue?.lstProfiles}
          lstPackages={formValue?.lstPackages}
          ignoredProfiles={formValue?.ignoredProfiles}
          ignoredTests={formValue?.ignoredTests}
          lstOptionalTests={lstOptionalTests}
          staffType={formValue?.staffType}
          projectInfo={projectInfo}
          paymentStatus={paymentStatus}
        />
      </Box>
    </Paper>
  );
};

export default SelectServiceStep;
