import {
  CheckboxActive,
  CheckboxDefault,
  CheckboxDisabledActive,
} from '@/assets/svg';
import CustomTooltip from '@/components/CustomTooltip';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import { StyledAccordion } from '@/new-components/CustomCollapseCard/styled';
import { Checkbox, Grid, FormControlLabel, Card } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 16,
    color: '#333333',
    fontWeight: 600,
  },
})(InputLabel);

const CustomGrid = withStyles({
  root: {
    borderBottom: '1px solid #e1e1e1',
    paddingBottom: 16,
    marginBottom: 16,

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
})(Grid);

const useStyle = makeStyles({
  textPrice: {
    fontWeight: 600,
  },
});

const ViewPackageItem = ({
  ignoredTests = [],
  ignoredProfiles,
  packageDetail,
  dataInfo,
  restrictedTestIds = [],
}) => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState('panel');

  const [radiologies, setRadiologies] = useState([]);
  const [procedures, setProcedures] = useState([]);

  const [unMandatoryProcedures, setUnMandatoryProcedures] = useState([]);
  const [unMandatoryRadiologies, setUnMandatoryRadiologies] = useState([]);

  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRadiologies, setSelectedRadiologies] = useState([]);

  const availableProcedures = useMemo(
    () => procedures.filter((item) => !restrictedTestIds.includes(item.id)),
    [procedures, restrictedTestIds]
  );
  const availableRadiologies = useMemo(
    () => radiologies.filter((item) => !restrictedTestIds.includes(item.id)),
    [radiologies, restrictedTestIds]
  );

  useEffect(() => {
    if (isEmpty(packageDetail)) return;
    const { lstTests, doneFollowUpTests } = packageDetail;
    let selectedProcedures = [];
    let selectedRadiologies = [];
    let procedures = [];
    let radiologies = [];

    lstTests.forEach((item) => {
      if (item.category === 'Procedures')
        procedures = item.items?.sort((a, b) => b.isRequired - a.isRequired);
      if (item.category === 'Radiologies')
        radiologies = item.items?.sort((a, b) => b.isRequired - a.isRequired);
    });

    // Filter test restricted by location
    // if (!isEmpty(restrictedTestIds)) {
    //   procedures = procedures?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    //   radiologies = radiologies?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    // }

    const unMandatoryProcedures = [...procedures].filter(
      (it) => !it.isRequired
    );
    const unMandatoryRadiologies = [...radiologies].filter(
      (it) => !it.isRequired
    );

    // Procedures

    if (isEmpty(ignoredTests)) {
      selectedProcedures = unMandatoryProcedures?.filter((value) => {
        return ignoredTests?.some((test) => {
          return value.id === test.testId;
        });
      });
    } else {
      selectedProcedures = unMandatoryProcedures?.filter((value) => {
        return ignoredTests?.every((test) => {
          return value.id !== test.testId;
        });
      });
    }

    let newUnMandatoryProcedures = cloneDeep(procedures)
      ?.map((it) => {
        if (findIndex(selectedProcedures, (test) => test.id === it.id) !== -1) {
          return { ...it, isChecked: true };
        }

        return it;
      })
      ?.sort((a, b) => b.isRequired - a.isRequired);

    // Radiologies

    if (isEmpty(ignoredTests)) {
      selectedRadiologies = unMandatoryRadiologies?.filter((value) => {
        return ignoredTests?.some((test) => {
          return value.id === test.testId;
        });
      });
    } else {
      selectedRadiologies = unMandatoryRadiologies?.filter((value) => {
        return ignoredTests?.every((test) => {
          return value.id !== test.testId;
        });
      });
    }

    let newUnMandatoryRadiologies = cloneDeep(radiologies)
      ?.map((it) => {
        if (findIndex(selectedRadiologies, (test) => test.id === it.id) !== -1)
          return { ...it, isChecked: true };
        return it;
      })
      ?.sort((a, b) => b.isRequired - a.isRequired);

    if (!isEmpty(doneFollowUpTests)) {
      newUnMandatoryProcedures = newUnMandatoryProcedures.filter(
        (it) => !doneFollowUpTests.map((it) => it.testId).includes(it.id)
      );
      newUnMandatoryRadiologies = newUnMandatoryRadiologies.filter(
        (it) => !doneFollowUpTests.map((it) => it.testId).includes(it.id)
      );
    }

    setUnMandatoryProcedures(unMandatoryProcedures);
    setUnMandatoryRadiologies(unMandatoryRadiologies);

    setProcedures(newUnMandatoryProcedures);
    setRadiologies(newUnMandatoryRadiologies);

    setSelectedProcedures(selectedProcedures);
    setSelectedRadiologies(selectedRadiologies);
  }, [
    dataInfo,
    packageDetail,
    ignoredProfiles,
    ignoredTests,
    restrictedTestIds,
  ]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box px={2}>
      <CustomInputLabel>Package</CustomInputLabel>

      <StyledAccordion
        expanded={expanded === 'panel'}
        onChange={handleChange('panel')}
        className={clsx(expanded === 'panel' ? 'expanded' : '')}
        style={{ border: '1px solid #e1e1e1' }}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography>{packageDetail?.name}</Typography>
          </Box>

          <Typography className={classes.textPrice}>
            {formatTotalPrice(packageDetail?.price, '$')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {!isEmpty(procedures) && !isEmpty(unMandatoryProcedures) && (
              <CustomGrid container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {`Procedures (Choose only ${packageDetail.totalMandatoryProcedure} tests)`}
                  </Typography>
                </Grid>
                {availableProcedures.map((item) => (
                  <Grid item xs={6} key={item.id}>
                    {item.isRequired ? (
                      <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                          <Checkbox
                            className={
                              item.isRequired ? classes.iconDisabled : null
                            }
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxDisabledActive />}
                            checked={item.isRequired}
                            disabled={item.isRequired}
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    ) : (
                      <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                          <Checkbox
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxActive />}
                            checked={item.isChecked}
                            disabled={
                              !item.isChecked &&
                              selectedProcedures.length ===
                                packageDetail.totalMandatoryProcedure
                            }
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    )}
                  </Grid>
                ))}
              </CustomGrid>
            )}

            {!isEmpty(radiologies) && !isEmpty(unMandatoryRadiologies) && (
              <CustomGrid container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {`Radiologies (Choose only ${packageDetail.totalMandatoryRadiology} tests)`}
                  </Typography>
                </Grid>
                {availableRadiologies.map((item) => (
                  <Grid item xs={6} key={item.id}>
                    {item.isRequired ? (
                      <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                          <Checkbox
                            className={
                              item.isRequired ? classes.iconDisabled : null
                            }
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxDisabledActive />}
                            checked={item.isRequired}
                            disabled={item.isRequired}
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    ) : (
                      <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                          <Checkbox
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxActive />}
                            checked={item.isChecked}
                            disabled={
                              !item.isChecked &&
                              selectedRadiologies.length ===
                                packageDetail.totalMandatoryRadiology
                            }
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    )}
                  </Grid>
                ))}
              </CustomGrid>
            )}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default ViewPackageItem;
