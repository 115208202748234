import CustomCountryCodeInput from '../CustomCountryCodeInput';
import { VectorIcon } from '@/assets/svg';
import { getBase64 } from '@/helpers';
import validateData from '@/helpers/validationHelpers/validationSchema';
import counsellorsManagementDispatcher from '@/module/counsellors-management/action';
import { TYPE_MODAL } from '@/module/counsellors-management/constants';
import { dummyRequest } from '@/module/lab-result/utils';
import CustomButton from '@/new-components/CustomButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Upload } from 'antd';
import { get, isBoolean, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText,
  },
  text: {
    fontWeight: 600,
  },
});

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    color: '#333333',
  },
})(Typography);

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666',
  },
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
  },
})(TextField);

const CustomRejectButton = withStyles({
  root: {
    background: '#DB3D49',

    '&.MuiButton-contained.Mui-disabled': {
      color: '#FFFFFF',
      background: '#DB3D494D',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(CustomButton);

const UploadWrapper = withStyles({
  root: {
    '& .ant-upload.ant-upload-drag': {
      height: 224,
      borderRadius: 8,
      border: '1px solid #e1e1e1',

      '& .ant-upload.ant-upload-btn': {
        padding: '4px 0',
      },
    },
  },
})(Box);

const ModalAddOrEditCounsellor = ({
  typeModal,
  open,
  onClose,
  onSubmit,
  selectedItem,
  loading,
  onApprove,
  onReject,
}) => {
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState(null);

  const [formValue, setFormValue] = useState({
    // mobile: '',
    countryCode: 'SG',
    email: '',
    firstName: '',
    lastName: '',
    pricePerHour: 0,
  });

  useEffect(() => {
    if (selectedItem?.id) {
      counsellorsManagementDispatcher.getDetailCounsellor(
        selectedItem.id,
        (data) => {
          setFormValue(data);
          setImageUrl(data.counsellingLicense);
        }
      );
    }
  }, []);

  const handleChangeForm = (key) => (e, selectVal) => {
    let value = get(e, 'target.value');

    switch (key) {
      case 'pricePerHour':
      case 'mobile':
        // case 'licenseNumber':
        value = e.value;
        break;
      // case 'counsellingLicense':
      //   getBase64(e.file.originFileObj, (imageUrl) => {
      //     imageUrl && setImageUrl(imageUrl);
      //   });
      //   value = get(e, 'file.originFileObj');
      //   break;
      default:
        break;
    }

    setFormValue({ ...formValue, [key]: value });
  };

  const handleSubmit = async () => {
    try {
      await validateData('counsellorSchema', formValue, () => {
        if (typeModal === TYPE_MODAL.Verify) {
          onApprove(formValue);
        } else onSubmit(typeModal, formValue);
        setErrors({});
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = [
      // 'mobile',
      'email',
      'firstName',
      'lastName',
      'pricePerHour',
    ];
    if (typeModal === TYPE_MODAL.Verify) filterKeys.pop();
    return filterKeys.some((key) =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
  }, [formValue, typeModal]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
    >
      <CustomDialogTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create ? 'Create new' : typeModal} counsellor
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTitleLabel>Personal information</CustomTitleLabel>
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>First name</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter first name"
              value={formValue.firstName || ''}
              onChange={handleChangeForm('firstName')}
              error={errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>Last name</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter last name"
              value={formValue.lastName || ''}
              onChange={handleChangeForm('lastName')}
              error={errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <CustomTitleSubLabel>Phone number</CustomTitleSubLabel>
            <Box display="flex" gridGap={8}>
              <CustomCountryCodeInput
                country={formValue.countryCode}
                disabled={false}
                onChange={handleChangeForm('countryCode')}
              />
              <NumberFormat
                customInput={CustomTextField}
                variant="outlined"
                placeholder="Enter number"
                onValueChange={handleChangeForm('mobile')}
                allowNegative={false}
                value={formValue.mobile || ''}
              />
            </Box>
            {errors.mobile && (
              <CustomFormHelperText error>{errors.mobile}</CustomFormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <CustomTitleSubLabel>Email</CustomTitleSubLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter email address"
              value={formValue.email || ''}
              onChange={handleChangeForm('email')}
              error={errors.email}
              helperText={errors.email}
            />
          </Grid>
          {typeModal !== TYPE_MODAL.Verify && (
            <Grid item xs={12}>
              <CustomTitleSubLabel>Rate per hour</CustomTitleSubLabel>
              <NumberFormat
                fullWidth
                customInput={CustomTextField}
                variant="outlined"
                placeholder="0.00"
                onValueChange={handleChangeForm('pricePerHour')}
                allowNegative={false}
                value={formValue.pricePerHour || ''}
                decimalScale={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {/* <Grid item xs={6}>
            <CustomTitleSubLabel>License number</CustomTitleSubLabel>
            <NumberFormat
              customInput={CustomTextField}
              variant="outlined"
              placeholder="Enter number"
              onValueChange={handleChangeForm('licenseNumber')}
              allowNegative={false}
              value={formValue.licenseNumber || ''}
              error={errors.licenseNumber}
              helperText={errors.licenseNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleLabel>Counselling license</CustomTitleLabel>
          </Grid>
          <Grid item xs={6}>
            <UploadWrapper>
              <Upload.Dragger
                listType="picture-card"
                customRequest={dummyRequest}
                onChange={handleChangeForm('counsellingLicense')}
                showUploadList={false}
                accept=".jpeg,.jpg,.png"
                file={formValue.counsellingLicense}
                progress={{
                  strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  },
                  strokeWidth: 3,
                  format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  {isEmpty(formValue.counsellingLicense) ? (
                    <div className="group">
                      <VectorIcon />
                      <div className="text">
                        <div>
                          <p style={{ fontSize: 16 }}>Drag and drop or Browse</p>
                          <span style={{ fontSize: 12 }}>
                            Supported format: PNG or JPEG images
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Box
                      display="table-cell"
                      textAlign="center"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <img
                        alt="Counselling License"
                        style={{ width: '100%', height: 216 }}
                        src={imageUrl}
                      />
                    </Box>
                  )}
                </Box>
              </Upload.Dragger>
            </UploadWrapper>
          </Grid> */}
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        {typeModal === TYPE_MODAL.Verify ? (
          <>
            <CustomRejectButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={onReject}
              disabled={isEmptyParams || loading}
            >
              Reject
            </CustomRejectButton>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={isEmptyParams || loading}
            >
              Approve
            </CustomButton>
          </>
        ) : (
          <CustomButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={isEmptyParams || loading}
          >
            {typeModal === TYPE_MODAL.Create ? 'Create' : 'Save'}
          </CustomButton>
        )}
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAddOrEditCounsellor.propTypes = {
  typeModal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedItem: PropTypes.object,
};

ModalAddOrEditCounsellor.defaultProps = {
  typeModal: TYPE_MODAL.Create,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet'),
  selectedItem: null,
};

export default ModalAddOrEditCounsellor;
