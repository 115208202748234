import { ArrowDown, CheckboxActive, CheckboxDefault } from '@/assets/svg';
import AsyncAutoComplete from '@/components/AsyncAutoComplete';
import { CLINIC_SERVICE_TYPE } from '@/module/clinic-services/constants';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import CustomDate from '@/new-components/CustomDate';
import {
  Box,
  Grid,
  TextField,
  withStyles,
  InputLabel,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: (props) => (props.hasClear ? 0 : 30),
      padding: '8px 5px',
      fontSize: 16,
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0,

      '& .MuiOutlinedInput-input': {
        marginRight: 8,
      },
    },
  },
})(TextField);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const CustomMultipleTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      minHeight: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
        paddingLeft: 4,
        marginLeft: 4,
        height: 30,
      },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
})(TextField);

const CustomMultiplePaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}))(Paper);

const CreateApptStep = ({
  purposeDetail,
  formValue,
  setFormValue,
  handleChangeForm,
  listTimeSlots,
}) => {
  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputLabel>Patient name</CustomInputLabel>
          <AsyncAutoComplete
            label="Patient name"
            endpoint="Patients/filter-for-clinic-appointment"
            method="post"
            defaultFilter={{
              appointmentType: 'TeleConsult',
            }}
            value={
              isEmpty(formValue.fullName)
                ? formValue.patient
                : {
                    fullName: formValue.fullName,
                    identityNumber: formValue.identityNumber,
                  }
            }
            setValue={(newValue) =>
              setFormValue({
                ...formValue,
                patient: newValue,
              })
            }
            disabled={!isEmpty(formValue.fullName)}
            getOptionLabel={(option) =>
              option.fullName &&
              option.identityNumber &&
              `${option.fullName} - ${option.identityNumber}`
            }
            renderInput={(params) => (
              <CustomTextField
                hasClear
                variant="outlined"
                {...params}
                placeholder="Search by patient name or ID Number"
              />
            )}
            renderOption={(option) =>
              option.fullName &&
              option.identityNumber &&
              `${option?.fullName} - ${option?.identityNumber}`
            }
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Location</CustomInputLabel>

          <AsyncAutoComplete
            label="Locations"
            endpoint="Locations/filter"
            method="post"
            isLocation
            defaultFilter={{
              sortByName: true,
              hasPlatoName: true,
              statuses: ['Active'],
            }}
            value={formValue.location}
            setValue={(newValue) => {
              setFormValue({
                ...formValue,
                appointmentTime: '',
                location: newValue,
              });
            }}
            getOptionLabel={(option) => option.name || ''}
            nameKey="name"
            renderInput={(params) => (
              <CustomTextField
                variant="outlined"
                {...params}
                placeholder="Select or search clinic"
              />
            )}
            disableClearable
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>Purpose of visit</CustomInputLabel>
          <AsyncAutoComplete
            label="Purposes"
            endpoint="Purposes/filter"
            method="post"
            value={formValue.purpose}
            setValue={(newValue) => {
              setFormValue({
                ...formValue,
                purposeId: newValue.id,
                subPurposeIds: [],
                purposeAnswers: [],
                purpose: newValue,
              });
            }}
            getOptionLabel={(option) => option.name || ''}
            nameKey="name"
            isFormatResult
            renderInput={(params) => (
              <CustomTextField
                variant="outlined"
                {...params}
                placeholder="Select purpose"
              />
            )}
            disableClearable
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Grid>

        {!isEmpty(purposeDetail) &&
          purposeDetail.purposeType === CLINIC_SERVICE_TYPE.SINGLE_CHOICE && (
            <Grid item xs={12}>
              <CustomInputLabel>
                {purposeDetail?.name === 'Medical Screening'
                  ? 'Screening'
                  : purposeDetail?.name}{' '}
                type
              </CustomInputLabel>

              <CustomAutocomplete
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option.name || ''}
                options={purposeDetail.subPurposes || []}
                value={
                  purposeDetail.subPurposes.find((d) =>
                    formValue.subPurposeIds.includes(d.id)
                  ) || ''
                }
                onChange={handleChangeForm('purposeData', purposeDetail)}
                popupIcon={<ArrowDown />}
                renderInput={(params) => (
                  <CustomTextField
                    placeholder="Select"
                    {...params}
                    variant="outlined"
                  />
                )}
                PaperComponent={({ children, ...other }) => (
                  <CustomPaper {...other}>{children}</CustomPaper>
                )}
              />
            </Grid>
          )}

        {!isEmpty(purposeDetail) &&
          purposeDetail.purposeType === CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE && (
            <Grid item xs={12}>
              <CustomInputLabel>
                {purposeDetail?.name === 'Medical Screening'
                  ? 'Screening'
                  : purposeDetail?.name}{' '}
                type
              </CustomInputLabel>

              <CustomAutocomplete
                multiple
                disableCloseOnSelect
                value={
                  purposeDetail.subPurposes
                    .filter((d) => formValue.subPurposeIds.includes(d.id))
                    .map((it) => it.name) || ''
                }
                onChange={handleChangeForm('purposeData', purposeDetail)}
                options={purposeDetail.subPurposes.map((it) => it.name)}
                getOptionLabel={(option) => option}
                popupIcon={<ArrowDown />}
                renderInput={(params) => (
                  <CustomMultipleTextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      isEmpty(formValue?.subPurposeIds) ? 'Select' : ''
                    }
                  />
                )}
                renderOption={(option, { selected }) => {
                  return (
                    <Box display="flex" gridGap={4}>
                      <Checkbox
                        icon={<CheckboxDefault width={20} height={20} />}
                        checkedIcon={<CheckboxActive width={20} height={20} />}
                        checked={selected}
                        color="primry"
                        style={{ padding: 0, marginRight: 4 }}
                      />
                      <span>{option}</span>
                    </Box>
                  );
                }}
                renderTags={(value, getTagProps) => {
                  return (
                    <Box fontSize={16} ml={1}>
                      {value.join(', ')}
                    </Box>
                  );
                }}
                PaperComponent={({ children, ...other }) => (
                  <CustomMultiplePaper {...other}>
                    {children}
                  </CustomMultiplePaper>
                )}
              />
            </Grid>
          )}

        {!isEmpty(purposeDetail) &&
          purposeDetail.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT &&
          formValue.purposeAnswers.map((it, inx) => (
            <Grid item xs={12}>
              <CustomInputLabel>{it.title}</CustomInputLabel>

              <CustomTextField
                rows={3}
                multiline
                fullWidth
                variant="outlined"
                placeholder={it.hintText}
                value={it.answer || ''}
                onChange={handleChangeForm('purposeData', purposeDetail, inx)}
              />
            </Grid>
          ))}

        <Grid item xs={12}>
          <CustomInputLabel>Date</CustomInputLabel>
          <CustomDate
            selectedDate={formValue.date ? moment(formValue.date) : undefined}
            disablePast
            isFullWidth
            placeholder="Select date"
            clearable={false}
            onChange={handleChangeForm('date')}
            style={{ height: 48, fontSize: 16 }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Time slot</CustomInputLabel>
          <CustomAutocomplete
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option.value || ''}
            options={listTimeSlots || []}
            value={
              listTimeSlots?.find(
                (it) => it?.value === formValue?.appointmentTime
              ) || ''
            }
            onChange={handleChangeForm('appointmentTime')}
            popupIcon={<ArrowDown />}
            renderInput={(params) => (
              <CustomTextField
                variant="outlined"
                placeholder="Select time slot"
                {...params}
              />
            )}
            disableClearable
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateApptStep;
