import clinicServiceDispatcher from '../action';

const initialState = {
  list: [],
};

const clinicServiceReducer = clinicServiceDispatcher(initialState, {
  [clinicServiceDispatcher.getDataSuccess]: (_, payload) => ({
    list: payload.data,
  }),

  [clinicServiceDispatcher.getDataBookingSuccess]: (state, payload) => {
    return { ...state, timeBooking: payload.timeBooking };
  },
  [clinicServiceDispatcher.setState]: (_, payload) => ({
    [payload.state]: payload.value,
  }),
  [clinicServiceDispatcher.resetData]: (_) => ({
    ...initialState,
  }),
});

export default clinicServiceReducer;
