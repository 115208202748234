import { CHANNEL_DISPLAY, CHANNEL_DISPLAY_COLORS } from '../constants';
import { Box, Chip, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  chip: {
    // minWidth: 82,
    color: (props) => props.color,
    backgroundColor: (props) => props.backgroundColor,
  },
});

const ChannelComponent = ({
  status,
  filterList = CHANNEL_DISPLAY,
  filterListColor = CHANNEL_DISPLAY_COLORS,
}) => {
  const channels = useMemo(() => {
    if (isEmpty(status)) return [];
    return status.split(',').map((d) => d.trim());
  }, [status]);

  let displayStatus = filterList[status] || status;
  let color = filterListColor[displayStatus]?.txt || '#23A6CC';
  let backgroundColor =
    filterListColor[displayStatus]?.background || '#23a6cc29';

  const classes = useStyles({ color, backgroundColor });

  return (
    <Box display={'flex'} gridGap={8} flexWrap={'wrap'}>
      {channels.map((d) => {
        let color = filterListColor[d]?.txt || '#23A6CC';
        let backgroundColor = filterListColor[d]?.background || '#23a6cc29';
        return (
          <Chip
            size="small"
            style={{ color, backgroundColor }}
            className={classes.chip}
            label={d}
          />
        );
      })}
    </Box>
  );
};

export default ChannelComponent;
