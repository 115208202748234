import Enum from './Enum';

export const urlLabel = Enum({
  verifyPatient: 'verify-email',
  home: '',
  login: 'login',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  resetPasswordOldUser: 'reset-password-old-user',
  logout: 'logout',
  accountSettings: 'settings',
  chatSample: 'chat-sample',
  profileManagement: 'profile-management',
  workoutManagement: 'workout-management',
  excutePaypal: 'excutePaypal',
  internalLogin: 'internal/login',
  internalRegister: 'internal/register',
  clinicManagementMenu: 'clinic-set-up-menu',
  userPermission: 'user-permission',
  deepLink: 'deep-link',
  screenQueue: 'queue',
  resultInbox: 'result-inbox',
  exportIrReport: 'export-ir-report',
  swabberManagement: 'swabber',
  bookingManagementSupervisedArt: 'appointment-config-supervised-art',
  appointmentsMenu: 'appointments-menu',

  // Old link Project
  projects: 'projects',
  adminTools: 'projects/admin-tools',
  createProject: 'projects/company-management/new-project',
  projectManage: 'projects/company-management/edit-project',
  editProject: 'projects/company-management/edit-project/:projectName',
  editSequenceStations: 'projects/company-management/edit-sequence-stations',
  stationManagement: 'projects/stations',
  createStation: 'projects/stations/create',
  editStation: 'projects/stations/:stationID',
  timeSlotTemplate: 'projects/admin-tools/time-slot-template',
  timeSlotTemplateCreate:
    'projects/admin-tools/time-slot-template/new-time-slot-template',
  timeSlotTemplateEdit:
    'projects/admin-tools/time-slot-template/edit-time-slot-template',
  consentManagement: 'projects/admin-tools/consent-management',
  createConsent: 'projects/admin-tools/consent-management/new-consent',
  editConsent: 'projects/admin-tools/consent-management/:consentId',
  // Old link Life-survey
  surveyManagement: 'life-survey',
  createSurvey: 'life-survey/new-life-survey',
  editSurvey: 'life-survey/edit-life-survey',
  // Old Link Services
  services: 'services',
  testManagement: 'services/tests',
  testForm: 'services/tests/new-test-form',
  testEditForm: 'services/tests/:name',
  measurementManagement: 'services/measurements',
  measurementForm: 'services/measurements/new-measurement-form',
  measurementEditForm: 'services/measurements/:name',
  procedureManagement: 'services/procedures',
  procedureForm: 'services/procedures/new-procedure-form',
  procedureEditForm: 'services/procedures/:name',
  radiologyManagement: 'services/radiologies',
  radiologyForm: 'services/radiologies/new-radiology-form',
  radiologyEditForm: 'services/radiologies/:name',
  vaccinationManagement: 'services/vaccinations',
  vaccinationForm: 'services/vaccinations/new-vaccination-form',
  vaccinationEditForm: 'services/vaccinations/:name',
  reviewManagement: 'services/reviews',
  reviewnForm: 'services/reviews/new-review-form',
  reviewEditForm: 'services/reviews/:name',
  profileAndTests: 'services',
  healthProfiles: 'services/profiles',
  healthProfilesForm: 'services/profiles/create-new-profile',
  healthProfilesEditForm: 'services/profiles/:name',
  healthProfilesStatements: 'services/profiles/edit-conditions',
  healthPackages: 'services/packages',
  healthPackagesForm: 'services/packages/create-new-package',
  healthPackagesEditForm: 'services/packages/:packageName',
  profilesAndTests: 'services',
  statementManagement: 'services/statements',
  motherhoodStatement: 'services/motherhood-statements',
  screeningQuestionnaires: 'services/screening-questionnaires',
  acknowledgeResult: 'acknowledge-abnormal-result',

  // Old link Appointments Heath Screening
  appointments: 'appointments-heath-screening',
  checkInPatientInfo:
    'appointments-heath-screening/patient-registration-information',
  assignConsentForm: 'appointments-heath-screening/select-consent-form',
  detailAppointment: 'appointments-heath-screening/detail-appointment',
  surveyDetail: 'appointments-heath-screening/lifestyle-survey',

  /* ========= New Router ========= */
  /* All-users */
  userDetails: 'all-users',
  editUserDetail: 'all-users/user-details',

  /* Teleconsult */
  doctorManagement: 'teleconsult/doctor',
  patientManagement: 'teleconsult/patient-management',
  caManagement: 'teleconsult/ca',
  waitingRoom: 'teleconsult/consult-room',
  meetingHistory: 'teleconsult/consult-list',
  consultList: 'teleconsult/visit-records',
  bookingManagement: 'teleconsult/appointment-config',
  appointmentManagement: 'teleconsult/appointments',
  mhsManagement: 'teleconsult/mhs-management',
  teleconsultAnalytics: 'teleconsult/teleconsult-analytics',

  /* Clinic */
  clinicManagement: 'clinic/setup/clinic-locations',
  clinicServices: 'clinic/setup/clinic-services',
  queueManagement: 'clinic/queue-management',
  clinicAppointments: 'clinic/clinic-appointments',

  /* Health-Screening */
  // Upcomming
  upcomingHealthScreening: 'health-screening/upcoming',
  upcomingEditAppointment: 'health-screening/upcoming/edit-appointment',
  // All Screenings
  allScreenings: 'health-screening/all-screenings',
  aSEditAppointment: 'health-screening/all-screenings/edit-appointment',
  aSviewPatientRegistration:
    'health-screening/all-screenings/patient-registration',
  aSRegisterPatient: 'health-screening/all-screenings/register-patient',
  aSEditPackage:
    'health-screening/all-screenings/register-patient/edit-package',
  aSCreateAppointment: 'health-screening/all-screenings/create-new-appointment',
  aSScreeningProgress: 'health-screening/all-screenings/screening-progress',
  aSPatientRegistration:
    'health-screening/all-screenings/screening-progress/patient-registration',
  aSCreateFollowUpAppointment:
    'health-screening/all-screenings/create-follow-up-appointment',
  aSRescheduleAppointment:
    'health-screening/all-screenings/reschedule-appointment',
  // Setup
  createCompany: 'health-screening/setup/company/create-new-company',
  editCompany: 'health-screening/setup/company/company-information',
  locationManagement: 'health-screening/setup/location',
  locationManagementCreate:
    'health-screening/setup/location/create-new-location',
  locationManagementEdit: 'health-screening/setup/location/edit-location',
  companyManagement: 'health-screening/setup/company',
  // Configuration
  configuration: 'health-screening/configuration',
  configPackages: 'health-screening/configuration/packages',
  configProfiles: 'health-screening/configuration/profiles',
  configOtherServices: 'health-screening/configuration/other-services',
  configTimeslotTemplate: 'health-screening/configuration/timeslot-template',
  configStations: 'health-screening/configuration/stations',
  configLifestyleSurvey: 'health-screening/configuration/lifestyle-survey',
  configConsentForm: 'health-screening/configuration/consent-form',
  configMotherhood: 'health-screening/configuration/motherhood-statements',
  // Other
  orderListExport: 'health-screening/e-order',
  backroomModule: 'health-screening/backroom',
  siteClosingReport: 'health-screening/closing-report',

  /* Minc Admin */
  apiSupport: 'minc-admin/api-support',
  marketingMessages: 'minc-admin/marketing-messages',
  adminManagement: 'minc-admin/user-management',
  rolesManagement: 'minc-admin/roles-management',
  promotion: 'minc-admin/promotion',
  deletionRequest: 'minc-admin/deletion-worklist',
  bannerManagement: 'minc-admin/banner-management',
  labResult: 'minc-admin/lab-result',
  importHSAppointments: 'minc-admin/import-hs-appointments',
  stuckAccounts: 'minc-admin/stuck-accounts',
  ecommerceManagement: 'minc-admin/ecommerce-management',
  angelNudgeManagement: 'minc-admin/angel-nudge-management',

  /* Telefit */
  categoryManagement: 'telefit/workout/category-management',
  livestreamManagement: 'telefit/workout/livestream-management',
  livestreamSectionDetail: 'telefit/workout/livestream-management/:id',
  onDemandManagement: 'telefit/workout/on-demand-management',
  studioClassesManagement: 'telefit/workout/studio-classes-management',

  upcomingWorkoutManagement: 'telefit/upcoming-workouts',
  studioManagement: 'telefit/studio-management',
  trainerManagement: 'telefit/trainer-management',
  userManagement: 'telefit/internal-trainer-user-management',

  /* Telecounsel */
  telecounselQueue: 'counselling/queue',
  telecounselAllCounsels: 'counselling/all-counsellings',
  telecounselAppointments: 'counselling/appointments',
  telecounselAppointmentConfig: 'counselling/appointment-configuration',
  telecounseRMCounsellors: 'counselling/resources-management/counsellors',
  telecounseRMCounsellorExecutives:
    'counselling/resources-management/counsellor-executives',

  /* Teleconsult Now */
  patientLogin: 'teleconsult',
  patientRegister: 'teleconsult/register',
  teleconsultNow: 'teleconsult/teleconsult-now',
  completeProfile: 'teleconsult/complete-profile',
  patientForgotPassword: 'teleconsult/forgot-password',
});
