import teleConsultNowDispatcher from '../../action';
import StreamMobileChat from './StreamMobileChat';
import StreamMobileFooter from './StreamMobileFooter';
import StreamMobileHeader from './StreamMobileHeader';
import AgoraInstanceTeleconsultNow from '@/agora/agoraInstanceTeleconsultNow';
import { EndCallMbIcon, MincAdmin } from '@/assets/svg';
import { endCall } from '@/helpers/patientTeleconsultRoomSignalR';
import { useContainerDimensions } from '@/helpers/useHookHelpers';
import { ModalUI } from '@/module/common';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import AvatarInCall from '@/new-components/StreamInCallPanel/AvatarInCall';
import { Box, CircularProgress, Drawer, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const useStyles = makeStyles({
  paper: {
    width: (props) => `calc(100% - ${props.modalWidth}px)`,
    height: '100%',
    top: 0,
    left: 0,
    transition: 'width 0.2s',
    position: 'relative',
    borderRadius: 'unset',
    backgroundColor: '#333333',

    padding: (props) =>
      props.isSharing
        ? props.showNRIC || props.showChat
          ? '20px'
          : `20px 0 20px 20px`
        : `unset`,
    display: (props) => (props.isSharing ? 'flex' : 'block'),
  },

  sharingContainerShowNIRIC: {
    width: 429,
    overflow: 'hidden',
    backgroundColor: '#333333',
    right: '429px',
    borderLeft: 'unset',
  },

  chatContainer: {
    width: '100dvw',

    overflow: 'hidden',
  },

  sharingContainer: {
    width: 365,
    overflow: 'hidden',
    backgroundColor: '#333333',
    borderLeft: 'unset',
  },
  loadingWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  btn: {
    width: 56,
    height: 56,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: 'none',
    zIndex: 1,
  },
});

const StreamMobilePanel = ({
  remoteStreams,
  isMuteVideo,
  isMuteAudio,

  channelInfo,
  videoChannelId,
  showChat,

  showNRIC,
  isSharing,
  callData,
  updateListChannels,
  isPatientMuteAudio,
  callProblem,
  startCallTime,
}) => {
  const [modalWidth, setModalWidth] = useState(showChat ? 429 : 0);
  const [hasNewMessage, setHasNewMessage] = useState(false);

  const [chatChannel, setChatChannel] = useState({});
  const [endModal, setEndModal] = useState(false);
  const classes = useStyles({ modalWidth });

  let mainStreamRef = useRef(null);
  const [, , ratio] = useContainerDimensions(mainStreamRef);

  useEffect(() => {
    AgoraInstanceTeleconsultNow.instance.joinChannel(videoChannelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // return () => {
    //   // clean up memberAppointment
    //   teleConsultNowDispatcher.setState('memberAppointment', null);
    // };
  }, []);

  useEffect(() => {
    if (channelInfo) {
      setChatChannel(channelInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelInfo]);

  const handleToggleChatMessage = () => {
    setModalWidth(!showChat ? 429 : 0);
    teleConsultNowDispatcher.setState('showChat', !showChat);
    isSharing && setModalWidth(!showChat ? 429 : 0);

    setHasNewMessage(false);
  };
  return (
    <ModalUI {...{ open: true, classes }}>
      {callData && (
        <StreamMobileHeader startCallTime={startCallTime} callData={callData} />
      )}

      <Box
        width="100%"
        height="100%"
        display={isSharing ? 'flex' : 'block'}
        bgcolor={
          isSharing
            ? showNRIC || showChat
              ? '#66666666'
              : '#333333'
            : '#e2f2ff'
        }
      >
        <Box
          width="100%"
          height="100%"
          borderRadius={16}
          border={`1px solid  ${
            isSharing
              ? showNRIC || showChat
                ? 'transparent'
                : '#979797'
              : '#e2f2ff'
          }`}
          bgcolor={
            isSharing
              ? showNRIC || showChat
                ? 'transparent'
                : '#66666666'
              : '#e2f2ff'
          }
        >
          {!callProblem ? (
            <StyledContainer
              ref={mainStreamRef}
              key="main_stream"
              id="main_stream"
              showChat={showChat}
              isSharing={isSharing}
              transform={'translate(-50%, -50%) !important;'}
            >
              {isEmpty(remoteStreams) ? (
                <Box className={classes.loadingWrapper}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%) ',
                    backgroundColor: '#e2f2ff',
                  }}
                >
                  <AvatarInCall>
                    <Box color={'#e2f2ff'} fontFamily="Lato" fontWeight={700}>
                      <MincAdmin width={67} height={67} />
                    </Box>
                  </AvatarInCall>
                </Box>
              )}
            </StyledContainer>
          ) : (
            <Box className={classes.loadingWrapper}>
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Box>
      </Box>
      <StreamMobileFooter
        isPatientMuteAudio={isPatientMuteAudio}
        isSharing={isSharing}
        isMuteAudio={isMuteAudio}
        isMuteVideo={isMuteVideo}
        setEndModal={setEndModal}
        hasNewMessage={hasNewMessage}
        handleToggleChatMessage={handleToggleChatMessage}
      />
      {endModal && (
        <ModalConfirmation
          open={!!endModal}
          onClose={() => setEndModal(false)}
          mainContent="End call"
          subContent="Do you want to end your consultation?"
          onClick={async () => {
            await endCall(callData?.DoctorId);
            AgoraInstanceTeleconsultNow.instance.leaveChannel({
              leaveOnlyShareScreen: false,
            });
          }}
          confirmLabel="Confirm"
          Icon={<EndCallMbIcon />}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        />
      )}

      <Drawer
        open={showChat}
        anchor="right"
        variant="persistent"
        classes={{ paper: classes.chatContainer }}
        onClose={handleToggleChatMessage}
      >
        <StreamMobileChat
          callData={callData}
          setChatChannel={setChatChannel}
          updateListChannels={updateListChannels}
          isOpen={showChat}
          channelInfo={chatChannel}
          onClose={handleToggleChatMessage}
          setHasNewMessage={setHasNewMessage}
        />
      </Drawer>
    </ModalUI>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  div:nth-child(2) {
    background-color: #333333;
  }

  video {
    width: ${(props) => (props.isSharing ? `100%` : `300%`)} !important;
    height: ${(props) => (props.isSharing ? `100%` : `200%`)} !important;
    background: ${(props) =>
      props.isSharing ? `#666666` : `#000000`} !important;

    transform: ${(props) =>
      !isEmpty(props.transform) ? props.transform : 'translate(-50%, -50%)'} 

    top: 50% !important;
    left: 50% !important;
  }
`;

const mapStateToProps = (state) => {
  const {
    localStream,
    remoteStreams,
    isMuteAudio,
    isMuteVideo,
    isPatientMuteAudio,
    isSharing,
    showChat,
    showNRIC,
    showSharing,
    verifyingUserData,
    isPatientMuteVideo,
    startCallTime,
    cameraSwitched,
  } = state.teleConsultNow;
  return {
    localStream,
    remoteStreams,
    isMuteAudio,
    isMuteVideo,
    isPatientMuteAudio,
    isSharing,
    showChat,
    showNRIC,
    showSharing,
    verifyingUserData,
    isPatientMuteVideo,
    startCallTime,
    cameraSwitched,
  };
};

export default connect(mapStateToProps)(StreamMobilePanel);
