import AsyncAutoComplete from '@/components/AsyncAutoComplete';
import { Paper, TextField, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',
    minWidth: 250,

    '& .MuiOutlinedInput-root': {
      height: 36,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const FilterConsultCountChart = ({ filterOptions, setFilterOptions }) => {
  const [doctor, setDoctor] = useState({});
  return (
    <AsyncAutoComplete
      endpoint="Users/filter"
      method="post"
      value={doctor}
      setValue={(newValue) => {
        setFilterOptions({
          ...filterOptions,
          doctorId: newValue?.id,
        });
        setDoctor(newValue);
      }}
      defaultFilter={{
        roleType: 'Doctor',
      }}
      getOptionLabel={(option) => option.fullName || ''}
      renderKey="fullName"
      renderInput={(params) => (
        <CustomTextField
          fullWidth
          variant="outlined"
          {...params}
          placeholder="Select doctor"
        />
      )}
      PaperComponent={({ children, ...other }) => (
        <CustomPaper {...other}>{children}</CustomPaper>
      )}
    />
  );
};

export default FilterConsultCountChart;
