import { roleType } from '../../../enum/RequestEnum';
import { uploadImage } from '../../../firebase';
import { getNationalNumber } from '../../../helpers';
import { isEmpty } from 'lodash';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({
    data,
  }),
  getData:
    (search, paging, sortOption = {}, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().doctor.paging;
      let { result, status } = await Api.post(`Users/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...sortOption,
        ...filterOption,
        roleType: roleType.Doctor,
      });
      if (status === 200) {
        doctorDispatcher.getDataSuccess(result);
      }
    },
  approveDoctor:
    (userId, callback, isApprove = false, searchKey, onFinish = () => {}) =>
    async ({ Api, customToast }) => {
      let { status } = await Api.post(`Doctors/approve`, {
        isApprove,
        userId,
      });
      if (status === 200) {
        doctorDispatcher.getData(searchKey);
        callback();
        customToast('success', 'Success');
      }
      onFinish();
    },
  activeDoctor:
    (userId, isActive = false, searchKey) =>
    async ({ Api, customToast }) => {
      let { status } = await Api.post(`Doctors/active`, {
        isActive,
        userId,
      });
      if (status === 200) {
        doctorDispatcher.getData(searchKey);
        customToast('success', 'Success');
      }
    },
  deleteUser:
    (id, searchKey) =>
    async ({ Api, customToast, getState }) => {
      let { status } = await Api.delete(`Users/${id}`);
      if (status === 200) {
        let { paging } = getState().doctor;
        doctorDispatcher.getData(searchKey, paging);
        customToast('success', 'Success');
      }
    },
  editUser:
    (rawData, callback, searchKey, onFinish) =>
    async ({ Api, customToast }) => {
      let data = {
        ...rawData,
        countryCode: rawData.countryCode || 'SG',
      };
      data.mobile = isEmpty(data.mobile)
        ? undefined
        : getNationalNumber(data.mobile, data.countryCode);
      let storageUrl;
      if (data.imageUrl?.constructor === File) {
        storageUrl = await uploadImage(data.imageUrl);
        data.imageUrl = storageUrl;
      }
      if (data.praticisingCertificate?.constructor === File) {
        storageUrl = await uploadImage(data.praticisingCertificate);
        data.praticisingCertificate = storageUrl;
      }
      let { result, status } = await Api.put(`Doctors/${data.id}/info`, data);
      if (status === 200) {
        customToast('success', 'Success');
        doctorDispatcher.getData(searchKey);
        callback(result);
      }
      onFinish();
    },
  addDoctor:
    (rawData, searchKey, callback, onFinish) =>
    async ({ Api, customToast, getState }) => {
      let data = {
        ...rawData,
        countryCode: rawData.countryCode || 'SG',
      };
      // data.mobile = getNationalNumber(data.mobile, data.countryCode);
      let storageUrl;
      if (data.imageUrl?.constructor === File) {
        storageUrl = await uploadImage(data.imageUrl);
        data.imageUrl = storageUrl;
      }
      if (data.praticisingCertificate?.constructor === File) {
        storageUrl = await uploadImage(data.praticisingCertificate);
        data.praticisingCertificate = storageUrl;
      }
      let { status } = await Api.post(`Doctors`, {
        ...data,
      });
      if (status === 200) {
        let { paging } = getState().doctor;
        doctorDispatcher.getData(searchKey, paging);
        customToast('success', 'Success');
        callback();
      }
      onFinish();
    },
  getClinics:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.post('Clinics/filter', {});
      if (status === 200) {
        doctorDispatcher.getClinicSuccess(result);
      }
    },
  getClinicSuccess: (data) => ({
    data,
  }),
  getTeleConsultStatus:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get('FunctionsConfigurations', {
        function: 'TeleConsult',
      });
      status === 200 && doctorDispatcher.getTeleConsultStatusSuccess(result);
    },
  getTeleConsultStatusSuccess: (data) => ({ data }),

  onChangeTeleConsultStatus:
    (data) =>
    async ({ Api }) => {
      const { status } = await Api.put('FunctionsConfigurations', {
        function: 'TeleConsult',
        active: data,
      });
      status === 200 && doctorDispatcher.getTeleConsultStatusSuccess(data);
    },
};

const doctorDispatcher = synthesize('doctor', mapDispatchToAC);
export default doctorDispatcher;
