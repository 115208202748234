import { urlLabel } from '@/enum/PermissionEnum';
import AdminManagement from '@/module/admin-management';
import AngelNudgeManagement from '@/module/angel-nudge-management';
import APISupportScreen from '@/module/api-support';
import { BannerManagement } from '@/module/banner';
import AccountDeletion from '@/module/deletion-request';
import EcommerceManagement from '@/module/e-commerce-management';
import ImportHSAppointments from '@/module/import-hs-appointments';
import LabResult from '@/module/lab-result/component';
import MarketingMessages from '@/module/marketing-messages';
import Promotion from '@/module/promotion';
import RolesManagement from '@/module/role-management';
import StuckAccounts from '@/module/stuck-accounts';

export const mincAdminRoutes = [
  {
    path: `/${urlLabel.adminManagement}`,
    component: AdminManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.stuckAccounts}`,
    component: StuckAccounts,
    exact: true,
  },
  {
    path: `/${urlLabel.rolesManagement}`,
    component: RolesManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.promotion}`,
    component: Promotion,
    exact: true,
  },
  {
    path: `/${urlLabel.deletionRequest}`,
    component: AccountDeletion,
    exact: true,
  },
  {
    path: `/${urlLabel.bannerManagement}`,
    component: BannerManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.marketingMessages}`,
    component: MarketingMessages,
    exact: true,
  },
  {
    path: `/${urlLabel.labResult}`,
    component: LabResult,
    exact: true,
  },
  {
    path: `/${urlLabel.importHSAppointments}`,
    component: ImportHSAppointments,
    exact: true,
  },
  {
    path: `/${urlLabel.apiSupport}`,
    component: APISupportScreen,
    exact: true,
  },
  {
    path: `/${urlLabel.ecommerceManagement}`,
    component: EcommerceManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.angelNudgeManagement}`,
    component: AngelNudgeManagement,
    exact: true,
  },
];
