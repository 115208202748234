import CreateApptStep from './CreateApptStep';
import PatientInformationStep from './PatientInformationStep';
import PatientTypeStep from './PatientTypeStep';
import { CloseIcon } from '@/assets/svg';
import { getNationalNumber } from '@/helpers';
import { authDispatcher } from '@/module/auth';
import clinicServiceDispatcher from '@/module/clinic-services/action';
import { CLINIC_SERVICE_TYPE } from '@/module/clinic-services/constants';
import locationDispatcher from '@/module/setup/action';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import CustomStepper from '@/new-components/CustomStepper';
import {
  checkRegexFullName,
  validateEmail,
  validateIdentity,
  validateMobile,
  validateNRIC,
} from '@/utils/validation';
import {
  Box,
  IconButton,
  Paper,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { cloneDeep, get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

export const STEPS_CONTROL = {
  back: 'Back',
  next: 'Next',
  finish: 'Create',
};

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto',
  },
});

const CustomDrawerTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ModalCreateClinicAppt = ({ loading, onClose, onSubmit, typeModal }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [purposeDetail, setPurposeDetail] = useState({});
  const [listTimeSlots, setListTimeSlots] = useState([]);
  const [errors, setErrors] = useState({});
  const [showLoading, setShowLoading] = useState(false);

  const [formValue, setFormValue] = useState({
    patientType: null,
    patient: {},
    location: {},
    purpose: {},
    date: moment().startOf('date').format(),
    timeSlotId: '',
    purposeId: '',
    subPurposeIds: [],
    purposeAnswers: [],

    email: '',
    countryCode: 'SG',
    phoneNumber: '',
    fullName: '',
    dateOfBirth: '',
    gender: '',
    identityType: 'NRIC',
    identityNumber: '',
    ethnicGroup: '',
    notificationEmail: '',
    patientId: null,
  });

  const checkIsValidDate = (valueStartDate) => {
    const startDateUnix = moment(valueStartDate).startOf('date').unix();

    const dateCompare = moment().startOf('date').unix();
    return startDateUnix < dateCompare;
  };

  const checkIsValidTime = (endTime) => {
    const endTimeUnix = moment(endTime).unix();
    const timeCompare = moment().unix();

    return endTimeUnix < timeCompare;
  };

  const checkIsValidTime5Mins = (endTime) => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const checkInvalidTimeslot = (row) => {
    const { startDate, endDate, isActive } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return;
  };

  const validAccountInfo = (data) => {
    let valid = true;
    let errors = {};
    if (!validateMobile(data.phoneNumber, data.countryCode)) {
      errors = { ...errors, phoneNumber: 'Phone number is invalid!' };
      valid = false;
    }

    if (!validateEmail(data.email)) {
      errors = { ...errors, email: 'Email is invalid!' };
      valid = false;
    }

    if (!data.email) {
      errors = {
        ...errors,
        email: 'Email is required!',
      };

      valid = false;
    }

    if (formValue.identityNumber) {
      const validIdentityNumber =
        formValue.identityType === 'NRIC'
          ? validateNRIC(formValue.identityNumber)
          : validateIdentity(formValue.identityNumber);

      if (!validIdentityNumber) {
        errors = { ...errors, identityNumber: 'Identity Number is invalid!' };
        valid = false;
      }
    }

    if (formValue.fullName) {
      const isValidFullName = checkRegexFullName(formValue.fullName);
      if (!isValidFullName) {
        errors = {
          ...errors,
          fullName:
            'Full Name must contain letters only or letters combined with special characters',
        };
        valid = false;
      }
    }

    setErrors(errors);
    return valid;
  };

  const handleNextStep = () => {
    setShowLoading(true);
    // Check invalid

    if (!validAccountInfo(formValue)) {
      setShowLoading(false);
      return;
    }

    setErrors({});
    authDispatcher.checkEmailExist(formValue.email, 'Patient', () => {
      authDispatcher.checkMobileExist(
        getNationalNumber(formValue.phoneNumber, formValue.countryCode),
        formValue.countryCode,
        'Patient',
        () => {
          authDispatcher.checkNricExist(formValue.identityNumber, () => {
            setCurrentStep(currentStep + 1);
            setShowLoading(false);
          });
        }
      );
    });
  };

  const handleSubmit = () => {
    if (formValue.patientType === 'minc' || formValue?.patientId) {
      const newParamsAppt = {
        patientId: formValue.patient.id || formValue?.patientId,
        locationId: formValue.location.id,
        timeSlotId: formValue.timeSlotId,
        date: moment(formValue.date).format('YYYY-MM-DD'),
        purposeId: formValue.purposeId,
        subPurposeIds: formValue.subPurposeIds,
        purposeAnswers: formValue.purposeAnswers,
      };
      onSubmit(typeModal, newParamsAppt, formValue.patient.fullName);
    } else {
      const newParamsPatient = {
        email: formValue.email,
        countryCode: formValue.countryCode,
        phoneNumber: formValue.phoneNumber,
        fullName: formValue.fullName,
        dateOfBirth: moment(formValue.dateOfBirth).startOf('date').format(),
        gender: formValue.gender,
        identityType: formValue.identityType,
        identityNumber: formValue.identityNumber,
        ethnicGroup: formValue.ethnicGroup,
        notificationEmail: formValue.notificationEmail,
      };
      authDispatcher.addPatient(newParamsPatient, (result) => {
        const newParamsAppt = {
          patientId: result,
          locationId: formValue.location.id,
          timeSlotId: formValue.timeSlotId,
          date: moment(formValue.date).format('YYYY-MM-DD'),
          purposeId: formValue.purposeId,
          subPurposeIds: formValue.subPurposeIds,
          purposeAnswers: formValue.purposeAnswers,
        };
        setFormValue({ ...formValue, patientId: result });
        onSubmit(typeModal, newParamsAppt, formValue.fullName);
      });
    }
  };

  const handleChangeForm = (key, data, rowInx) => (e, newValue) => {
    let value = '';

    switch (key) {
      case 'date':
        setFormValue({
          ...formValue,
          appointmentTime: '',
          timeSlotId: '',
          [key]: moment(e).startOf('date').format(),
        });
        return;
      case 'purposeData':
        let subPurposeIds = [];
        let purposeAnswers = [];
        if (
          data.purposeType === CLINIC_SERVICE_TYPE.SINGLE_CHOICE &&
          newValue
        ) {
          subPurposeIds.push(newValue.id);

          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        } else if (
          data.purposeType === CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE &&
          newValue
        ) {
          subPurposeIds = data.subPurposes
            .filter((it) => newValue.includes(it.name))
            .map((it) => it.id);
          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        } else {
          purposeAnswers = cloneDeep(formValue.purposeAnswers).map((it, inx) =>
            inx === rowInx
              ? {
                  ...it,
                  answer: get(e, 'target.value'),
                }
              : it
          );

          setFormValue({
            ...formValue,
            subPurposeIds,
            purposeAnswers,
          });
        }

        return;
      case 'appointmentTime':
        const timeSlotId = listTimeSlots?.find(
          (it) => it.value === newValue.value
        ).timeSlotId;
        setFormValue({
          ...formValue,
          timeSlotId,
          appointmentTime: newValue.value,
        });
        return;

      case 'phoneNumber':
        value = e.value;
        break;

      case 'email':
        setFormValue({
          ...formValue,
          email: get(e, 'target.value').trim(),
          notificationEmail: get(e, 'target.value').trim(),
        });
        return;

      case 'dateOfBirth':
        value = moment(e).startOf('date').format();
        break;

      case 'gender':
        value = newValue;
        break;

      case 'identityType':
        setFormValue({
          ...formValue,
          [key]: get(e, 'target.value'),
          identityNumber: '',
        });
        return;
      case 'countryCode':
      case 'ethnicGroup':
      case 'fullName':
      case 'identityNumber':
        value = get(e, 'target.value');
        break;

      default:
        value = newValue;
        break;
    }

    setFormValue({ ...formValue, [key]: value });
  };

  useEffect(() => {
    if (formValue.purposeId) {
      clinicServiceDispatcher.getDetailPurpose(
        formValue.purposeId,
        (result) => {
          setFormValue({
            ...formValue,

            purposeAnswers: result.purposeQuestions
              .sort((a, b) => a.order - b.order)
              .map((it) => ({
                ...it,
                question: it.title,
                answer: '',
              })),
          });

          setPurposeDetail({
            ...result,
            subPurposes: isEmpty(result.subPurposes)
              ? result.subPurposes
              : result.subPurposes?.sort((a, b) => a.order - b.order),
            purposeQuestions: isEmpty(result.purposeQuestions)
              ? result.purposeQuestions
              : result.purposeQuestions?.sort((a, b) => a.order - b.order),
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.purposeId]);

  useEffect(() => {
    if (formValue.location.id && formValue.date) {
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: formValue.location.id,
          viewAll: true,
          date: moment(formValue.date).format('YYYY-MM-DD'),
        },
        (result) => {
          const dayOfWeek = moment(formValue.date).day();
          const timeSlots = result
            .filter(
              (it) => it.dayOfWeek === dayOfWeek && !checkInvalidTimeslot(it)
            )
            .map((it) => ({
              timeSlotId: it?.id,
              key: formatTimeShowUILocal(it?.startDate),
              value: formatTimeShowUILocal(it?.startDate),
            }));

          setListTimeSlots(timeSlots);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue?.location?.id, formValue.date]);

  const renderListTabs = useMemo(() => {
    let list = [
      {
        name: 'Patient type',
        validKey: ['patientType'],
        component: (
          <PatientTypeStep formValue={formValue} setFormValue={setFormValue} />
        ),
      },
    ];

    if (formValue.patientType === 'minc') {
      list = list.concat([
        {
          name: 'Create appointment',
          validKey: ['patient', 'location', 'date', 'purposeId', 'timeSlotId'],
          component: (
            <CreateApptStep
              formValue={formValue}
              setFormValue={setFormValue}
              handleChangeForm={handleChangeForm}
              purposeDetail={purposeDetail}
              listTimeSlots={listTimeSlots}
            />
          ),
        },
      ]);
    } else {
      list = list.concat([
        {
          name: 'Patient information',
          validKey: [
            'email',
            'countryCode',
            'phoneNumber',
            'fullName',
            'dateOfBirth',
            'gender',
            'identityNumber',
            'ethnicGroup',
          ],
          component: (
            <PatientInformationStep
              errors={errors}
              formValue={formValue}
              setFormValue={setFormValue}
              handleChangeForm={handleChangeForm}
            />
          ),
        },
        {
          name: 'Create appointment',
          validKey: ['patient', 'location', 'date', 'purposeId', 'timeSlotId'],
          component: (
            <CreateApptStep
              formValue={formValue}
              setFormValue={setFormValue}
              handleChangeForm={handleChangeForm}
              purposeDetail={purposeDetail}
              listTimeSlots={listTimeSlots}
            />
          ),
        },
      ]);
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, purposeDetail, listTimeSlots, errors]);

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = renderListTabs[currentStep]?.validKey;
    const checkValid = filterKeys.some((key) =>
      Array.isArray(formValue[key]) ? isEmpty(formValue[key]) : !formValue[key]
    );

    if (
      [
        CLINIC_SERVICE_TYPE.SINGLE_CHOICE,
        CLINIC_SERVICE_TYPE.MULTIPLE_CHOICE,
      ].includes(purposeDetail.purposeType)
    ) {
      return isEmpty(formValue.subPurposeIds) || checkValid;
    } else if (purposeDetail.purposeType === CLINIC_SERVICE_TYPE.INPUT_TEXT) {
      const checkEmptyAnsswers = formValue.purposeAnswers.some((it) =>
        isEmpty(it.answer)
      );
      return checkEmptyAnsswers || checkValid;
    }

    return checkValid;
  }, [renderListTabs, currentStep, formValue, purposeDetail]);

  const StepperButtons = () => (
    <Box
      display="flex"
      justifyContent="flex-end"
      py={1.75}
      px={1.25}
      gridGap={16}
    >
      {currentStep !== 0 && (
        <CustomLoadingButton
          variant="outlined"
          color="primary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </CustomLoadingButton>
      )}

      <CustomLoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        disabled={isEmptyFilterParams || loading}
        onClick={() => {
          if (currentStep === renderListTabs.length - 1) {
            handleSubmit();
          } else if (
            renderListTabs[currentStep].name === 'Patient information'
          ) {
            handleNextStep();
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === renderListTabs.length - 1 ? 'Save' : 'Next'}
      </CustomLoadingButton>
    </Box>
  );

  return (
    <Paper square elevation={0}>
      <CustomDrawerTitle onClose={onClose}>
        Create appointment
      </CustomDrawerTitle>
      <CustomStepper
        steps={renderListTabs}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {renderListTabs[currentStep].component}
        </main>
      </CustomStepper>
    </Paper>
  );
};

export default ModalCreateClinicAppt;
