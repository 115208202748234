import { ClearComponent } from '@/new-components/ClearComponent';
import CustomCountryCodeInput from '@/new-components/CustomCountryCodeInput';
import CustomDate from '@/new-components/CustomDate';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Box,
  Grid,
  withStyles,
  InputLabel,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';

export const IDENTITY_TYPE = [
  {
    key: 'NRIC',
    value: 'NRIC/FIN',
  },
  {
    key: 'Passport',
    value: 'Passport',
  },
];

export const LIST_ETHNIC_GROUP = [
  {
    key: 'Chinese',
    value: 'Chinese',
  },
  {
    key: 'Malay',
    value: 'Malay',
  },
  {
    key: 'Indian',
    value: 'Indian',
  },
  {
    key: 'Others',
    value: 'Others',
  },
];

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: (props) => (props.hasClear ? 0 : 30),
      padding: '8px 5px',
      fontSize: 16,
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0,

      '& .MuiOutlinedInput-input': {
        marginRight: 8,
      },
    },
  },
})(TextField);
const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const CustomToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8,
    },
    '&:first-child': {
      borderRadius: 8,
    },
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles((theme) => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`,
    },
  },
}))(ToggleButton);

const PatientInformationStep = ({
  errors,
  formValue,
  setFormValue,
  handleChangeForm,
}) => {
  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputLabel>Email</CustomInputLabel>

          <CustomTextField
            id="email"
            fullWidth
            variant="outlined"
            placeholder="name@email.com"
            autoComplete="email"
            value={formValue.email || ''}
            onChange={handleChangeForm('email')}
            error={!!errors?.email}
            helperText={errors?.email}
            InputProps={{
              endAdornment: (
                <ClearComponent
                  id="email"
                  value={formValue.email}
                  onClear={() => setFormValue({ ...formValue, email: '' })}
                />
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Phone number</CustomInputLabel>
          <Box display="flex" gridGap={8}>
            <CustomCountryCodeInput
              country={formValue.countryCode}
              disabled={false}
              onChange={handleChangeForm('countryCode')}
            />
            <NumberFormat
              customInput={CustomTextField}
              variant="outlined"
              placeholder="Enter number"
              onValueChange={handleChangeForm('phoneNumber')}
              allowNegative={false}
              value={formValue.phoneNumber || ''}
            />
          </Box>
          {errors?.phoneNumber && (
            <CustomFormHelperText error>
              {errors?.phoneNumber}
            </CustomFormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>Full name</CustomInputLabel>
          <CustomTextField
            id="fullName"
            fullWidth
            variant="outlined"
            placeholder="Enter full name as per NRIC"
            value={formValue.fullName || ''}
            onChange={handleChangeForm('fullName')}
            error={!!errors?.fullName}
            helperText={errors?.fullName}
            InputProps={{
              endAdornment: (
                <ClearComponent
                  id="fullName"
                  value={formValue.fullName}
                  onClear={() => setFormValue({ ...formValue, fullName: '' })}
                />
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Date of birth</CustomInputLabel>
          <CustomDate
            selectedDate={formValue.dateOfBirth}
            isFullWidth
            placeholder="DD/MM/YYYY"
            clearable={false}
            onChange={handleChangeForm('dateOfBirth')}
            maxDate={moment()}
            style={{ height: 48, fontFamily: 'OpenSans', fontSize: 16 }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>Gender</CustomInputLabel>
          <CustomToggleButtonGroup
            exclusive
            value={formValue?.gender}
            onChange={handleChangeForm('gender')}
          >
            <CustomToggleButton
              value="Female"
              className={errors?.gender ? 'error' : ''}
            >
              Female
            </CustomToggleButton>
            <CustomToggleButton
              value="Male"
              className={errors?.gender ? 'error' : ''}
            >
              Male
            </CustomToggleButton>
          </CustomToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>ID type</CustomInputLabel>
          <CustomSelectContainer
            data={IDENTITY_TYPE || []}
            height="48px"
            fontSize="16px"
            isMutiple={false}
            hideClear
            colorInput="#000000de"
            placeholder="Select"
            renderValue={
              formValue.identityType
                ? IDENTITY_TYPE.find((it) => it.key === formValue.identityType)
                    ?.value
                : 'Select'
            }
            filterValue={formValue.identityType || ''}
            onChange={handleChangeForm('identityType')}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInputLabel>ID number</CustomInputLabel>
          <CustomTextField
            id="identityNumber"
            fullWidth
            variant="outlined"
            placeholder="Enter number"
            value={formValue.identityNumber || ''}
            onChange={handleChangeForm('identityNumber')}
            error={!!errors?.identityNumber}
            helperText={errors?.identityNumber}
            InputProps={{
              endAdornment: (
                <ClearComponent
                  id="identityNumber"
                  value={formValue.identityNumber}
                  onClear={() =>
                    setFormValue({ ...formValue, identityNumber: '' })
                  }
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>Ethnic group</CustomInputLabel>
          <CustomSelectContainer
            data={LIST_ETHNIC_GROUP || []}
            height="48px"
            fontSize="16px"
            isMutiple={false}
            hideClear
            colorInput="#000000de"
            placeholder="Select ethnic group"
            renderValue={
              formValue.ethnicGroup
                ? LIST_ETHNIC_GROUP.find(
                    (it) => it.key === formValue.ethnicGroup
                  )?.value
                : 'Select ethnic group'
            }
            filterValue={formValue.ethnicGroup || ''}
            onChange={handleChangeForm('ethnicGroup')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientInformationStep;
