import {
  ConsultationIcon,
  MedicalScreenIcon,
  OthersIcon,
  VaccinationIcon,
} from '@/assets/svg';

export const COLUMN_SUB_SERVICES = [
  {
    label: '',
    width: 40,
  },
  {
    label: 'No.',
    flex: 1,
  },
  {
    label: 'Type',
    flex: 4,
  },
  {
    label: 'Action',
    flex: 1,
    textAlign: 'center',
  },
];

export const COLUMN_SUB_SERVICES_OTHERS = [
  {
    label: '',
    width: 40,
  },
  {
    label: 'No.',
    flex: 0.8,
  },
  {
    label: 'Label',
    flex: 2,
  },
  {
    label: 'Hint Text',
    flex: 2,
    ml: 5.5,
  },
  {
    label: 'Action',
    flex: 1,
    textAlign: 'center',
  },
];

export const MAPPING_CLINIC_SERVICE_ICON = [
  {
    key: 'Consultation',
    icon: ConsultationIcon,
  },
  {
    key: 'Medical Screening',
    icon: MedicalScreenIcon,
  },
  {
    key: 'Vaccinations',
    icon: VaccinationIcon,
  },
  {
    key: 'Others',
    icon: OthersIcon,
  },
];

export const CLINIC_SERVICE_TYPE = {
  NO_SUB_SERVICE: 'NoSubService',
  SINGLE_CHOICE: 'SingleChoice',
  MULTIPLE_CHOICE: 'MultipleChoice',
  INPUT_TEXT: 'InputText',
};

export const MAPPING_CLINIC_SERVICE_TYPE = [
  {
    key: 'NoSubService',
    text: 'No sub-service',
  },
  {
    key: 'SingleChoice',
    text: 'Single choice set of sub-services',
  },
  {
    key: 'MultipleChoice',
    text: 'Multiple choice set of sub-services',
  },
  {
    key: 'InputText',
    text: 'No sub-service with input text',
  },
];

export const TYPE_MODAL = {
  Edit: 'Edit',
  Create: 'Create',
};
