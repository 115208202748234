import clinicApptDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
};

const clinicApptReducer = clinicApptDispatcher(initialState, {
  [clinicApptDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),

  [clinicApptDispatcher.getDataBookingSuccess]: (state, payload) => {
    return { ...state, timeBooking: payload.timeBooking };
  },
  [clinicApptDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
  [clinicApptDispatcher.resetData]: (state, _) => ({
    ...initialState,
  }),
});

export default clinicApptReducer;
