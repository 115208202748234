import marketingBroadcastDispatcher from '../marketing-messages/action';
import ChannelComponent from './component/ChannelComponent';
import ModalEditAngelNudge from './component/ModalEditAngelNudge';
import { COLUMN_ANGEL_NUDGE } from './constants';
import data from './fake-data.json';
import { EditIcon } from '@/assets/svg';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import { IconButton, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AngelNudgeManagement = () => {
  // const {list:data, paging } = useSelector((state) => state.marketingBroadcast);
  const [filterOption, setFilterOption] = useState({});
  const [sortOption, setSortOption] = useState({ sortAscending: false });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const handleToggleShowEditModal = useCallback(() => {
    setShowEditModal(!showEditModal);
  }, [showEditModal]);

  // const fetchData = async (
  //   pagingData = paging,
  //   sortOptionData = sortOption,
  //   filterOptionData = filterOption
  // ) => {
  //   marketingBroadcastDispatcher.getBroadcastData(
  //     pagingData,
  //     ...[sortOptionData, filterOptionData].filter((i) => i)
  //   );
  // };

  // const debounceLoadData = useCallback(
  //   debounce((paging, sortOption, filterOption) => {
  //     fetchData(
  //       {
  //         pageIndex: 0,
  //         pageSize: paging.pageSize,
  //       },
  //       sortOption,
  //       filterOption
  //     );
  //   }, 500),
  //   []
  // );

  // useEffect(() => {
  //   debounceLoadData(paging, sortOption, filterOption);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortOption, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_ANGEL_NUDGE.map((item) => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>{row?.category}</TableCell>
        <TableCell>
          <CustomTooltip content={row.item} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.condition} />
        </TableCell>
        <TableCell>{row?.timing}</TableCell>
        <TableCell>
          <ChannelComponent status={row?.channel} />
        </TableCell>

        <TableCell>
          <Typography dangerouslySetInnerHTML={{ __html: row?.notification }} />
        </TableCell>

        <TableCell>
          <Typography dangerouslySetInnerHTML={{ __html: row?.message }} />
        </TableCell>

        <TableCell>{row?.eventKill}</TableCell>
        <TableCell>{row?.reminder1}</TableCell>
        <TableCell>{row?.reminder2}</TableCell>
        <TableCell>{row?.escalation}</TableCell>
        <TableCell>{row?.status}</TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setShowEditModal(true);
              setSelectedItem(row);
            }}
          >
            <EditIcon color="#AD5E99" />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            isSearch={false}
            title="Angel Nudge Management"
            // filterOption={filterOption}
            // setFilterOption={setFilterOption}
            // fetchData={fetchData}
          />
        }
      >
        <CustomTable
          data={data.data.list}
          // paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No nudge"
          noDataHelperSubText="Please create a new nudge"
          totalCols={COLUMN_ANGEL_NUDGE.length}
          // fetchData={fetchData}
          style={{ height: 'calc(100vh - 207px)' }}
        />
      </CustomPaperContainer>
      {showEditModal && (
        <ModalEditAngelNudge
          open={showEditModal}
          onClose={handleToggleShowEditModal}
          selectedItem={selectedItem}
          onSubmit={handleToggleShowEditModal}
        />
      )}
    </>
  );
};

export default AngelNudgeManagement;
