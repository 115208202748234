import { AddImageOutlineIcon } from '../../assets/svg';
import CarouselAnt from '../../components/CarouselAnt';
import MultipleSelection from '../../components/CustomSelect/MutipleSelection';
import PhoneInput from '../../components/PhoneInput';
import UploadListImages from '../../components/UploadListImages';
import { UploadSingle } from '../common/component';
import {
  TextField,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';

export const arrayField = ({
  onChange,
  formValue,
  errors,
  listClinic,
  onDeleteCert,
  certShow,
  isSelectAllClinic,
  isClinicManager,
}) => {
  return [
    {
      key: 'mobile',
      render: (
        <TextField
          error={errors.mobile}
          helperText={errors.mobile}
          id="phone-input"
          label="Phone"
          value={formValue.mobile}
          onChange={onChange('mobile')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneInput
                  country={formValue.countryCode}
                  disabled={false}
                  onChange={onChange('countryCode')}
                />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      key: 'imageUrl',
      render: (
        <UploadSingle
          avatarCircle
          imageUrlSelected={formValue.imageUrl}
          onChange={onChange('imageUrl')}
          accept=".png,.jpg,.jpeg"
          uploadButton={
            <WrapperButtonUpload>
              <span className="icon">
                <AddImageOutlineIcon color="#ccc" />
              </span>
              {'  '}
              <span className="text">Avatar</span>
            </WrapperButtonUpload>
          }
        />
      ),
    },
    {
      key: 'clinicId',
      render: (
        <Grid item xs={12}>
          <InputLabel id="clinic-select-label">Clinic</InputLabel>
          <Select
            className="select-custom"
            variant="standard"
            labelId="clinic-select-label"
            id="clinic-select"
            value={formValue.clinicId || ''}
            onChange={onChange('clinicId')}
          >
            {listClinic.map((clinic, index) => (
              <MenuItem value={clinic.id} key={`${clinic.id}-${index}`}>
                {`${clinic.name}, ${clinic.address}, ${
                  clinic.workingHours || ''
                }`}
              </MenuItem>
            ))}
          </Select>
          {errors.clinicId && (
            <Typography color="error">{errors.clinicId}</Typography>
          )}
        </Grid>
      ),
    },
    {
      key: 'mcr',
      render: (
        <TextField
          error={errors.mcr}
          className="input-custom"
          variant="standard"
          label="MCR"
          value={formValue.mcr}
          onChange={onChange('mcr')}
          helperText={errors.mcr}
        />
      ),
    },
    {
      key: 'praticisingCertificate',
      render: (
        <UploadSingle
          typeUpload="normal"
          onChange={onChange('praticisingCertificate')}
          imageUrlSelected={formValue.praticisingCertificate}
          error={errors.praticisingCertificate}
          accept=".png,.jpg,.jpeg"
          uploadButton={
            <WrapperButtonUpload>
              <span className="icon">
                <AddImageOutlineIcon color="#ccc" />
              </span>{' '}
              <span className="text">Add Practising Certificate</span>
            </WrapperButtonUpload>
          }
        />
      ),
    },
    {
      key: 'lstClinicId',
      render: (
        <MultipleSelection
          onChange={onChange('lstClinicId')}
          value={formValue.lstClinicId || []}
          error={errors.lstClinicId}
          isMutiple
          inputLabel="Clinic"
          labelId="clinic-select-label"
          formClasses={{
            width: '100%',
            maxWidth: '100%',
            margin: '10px 0 0 0',
          }}
          data={listClinic}
          checkedAll={isSelectAllClinic()}
          renderValue={(value) => {
            let contentList = value.map((i) =>
              get(
                listClinic.find((c) => c.id === i),
                'name'
              )
            );
            return contentList.join(', ');
          }}
          useSelectAll={isClinicManager ? false : true}
          isClinic
        />
      ),
    },
    {
      key: 'lstCertificates',
      render: (
        <ListImgWrapped>
          <UploadListImages
            listImage={formValue.lstCertificates}
            onChange={onChange('lstCertificates')}
            onDelete={onDeleteCert}
            error={errors.lstCertificates}
            label="Certificate"
          />
          <CarouselAnt data={certShow} disabled />
        </ListImgWrapped>
      ),
    },
  ];
};

export const ROLE_FIELD = {
  SuperAdmin: [],
  Doctor: ['imageUrl', 'mcr', 'clinicId', 'praticisingCertificate'],
  CA: ['lstClinicId'],
  TeleFITSuperAdmin: [],
  TeleFITAdmin: [],
  Manager: [],
  Trainer: ['lstCertificates'],
  InternalTrainer: [],
  TeleFITStudio: [],
  CSO: [],
  CSOManager: [],

  MHS: [],
  Staff: [],
  LabAdmin: [],
  Swabber: [],
  MMSPublicist: [],
  MMSProjectManager: [],
  RegistrationStaff: [],
  BackroomStaff: [],
  ScreeningDoctor: [],
  DoctorAdmin: [],
  Nurse: [],
  ClinicManager: [, 'lstClinicId'],
  ClinicAdmin: [],
  SaleManager: [],
  SaleExec: [],
  FinanceManager: [],
  FinanceExec: [],
  MarketingManager: [],
  Counsellor: [],
  CounsellorExecutive: [],
};

export const DEFAULT_FIELD = [
  'firstName',
  'lastName',
  'email',
  'password',
  'countryCode',
  'roleType',
];

export const SINGLE_IMAGE = ['imageUrl', 'praticisingCertificate'];

export const LIST_IMAGES = 'lstCertificates';

export const COLOR_STATUS = {
  Approved: '#62CAA4',
  Reject: '#EA6B75',
  Pending: '#CACFD3',
  Active: '#62CAA4',
  InActive: '#EA6B75',
};

export const ROLE_STATUS = {
  Active: [
    'Doctor',
    'CA',
    'InternalTrainer',
    'MHS',
    'TeleFITAdmin',
    'TeleFITSuperAdmin',
    'Manager',
    'TeleFITStudio',
    'CSO',
    'SuperAdmin',
    'Staff',
    'LabAdmin',
    'Swabber',
  ],
  Approved: ['Trainer'],
};

const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;

const ListImgWrapped = styled.div`
  padding-bottom: 40px;
`;
