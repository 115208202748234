// import { COUNSEL_APPOINTMENT_STATUS } from '../constants';
import { CLINIC_APPT_STATUS } from '../constants';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import CustomAsyncAutoComplete from '@/new-components/CustomAsyncAutoComplete';
import CustomDateRange from '@/new-components/CustomDateRange';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Box,
  Toolbar,
  TextField,
  Checkbox,
  Paper,
  Button,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 600,
  },
})(Button);

const CustomFilterInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,

      '& input': {
        padding: 0,
        marginLeft: 0,
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#999999',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  isBtnSelected: {
    border: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4,
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const GroupToolbar = ({
  filterOption,
  setFilterOption,

  locationIds,
  setLocationIds,
  purposeIds,
  setPurposeIds,

  clinicStatuses,

  onFilterOptionChange,
  onResetFilterOps,

  appointmentDate,
  setAppointmentDate,
}) => {
  const classes = useStyles();
  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');

    if (startDate.trim() === endDate.trim()) {
      return `${startDate} `;
    } else if (moment(array[0]).isSame(moment(array[1]), 'year')) {
      const newStartDate = moment(array[0]).format('DD/MM');
      return `${newStartDate} - ${endDate} `;
    }

    return `${startDate} - ${endDate} `;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);
    return filterKeys.every((key) =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomDateRange
            selectedDate={appointmentDate || []}
            renderValue={() =>
              renderDateValue(appointmentDate || [], 'Select appointment date')
            }
            onChange={(startDate, endDate) => {
              if (startDate && endDate) {
                setAppointmentDate([startDate, endDate]);
                setFilterOption({
                  ...filterOption,
                  fromDate: startDate.toISOString(),
                  toDate: endDate.toISOString(),
                });
              } else {
                setAppointmentDate([]);
                setFilterOption({
                  ...filterOption,
                  fromDate: null,
                  toDate: null,
                });
              }
            }}
          />
          <CustomAsyncAutoComplete
            multiple
            disableCloseOnSelect
            isLocation
            endpoint="Locations/filter"
            method="post"
            nameKey="name"
            value={locationIds}
            onChange={(e, value) => {
              setFilterOption({
                ...filterOption,
                locationIds: value.map((it) => it.id),
              });
              setLocationIds(value);
            }}
            defaultFilter={{
              sortByName: true,
              hasPlatoName: true,
            }}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <CustomFilterInput
                {...params}
                variant="outlined"
                placeholder={isEmpty(locationIds) ? 'All locations' : ''}
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <Box display="flex" gridGap={4}>
                  <Checkbox
                    icon={<CheckboxDefault width={20} height={20} />}
                    checkedIcon={<CheckboxActive width={20} height={20} />}
                    checked={selected}
                    color="primry"
                    className={classes.checkboxIcon}
                  />
                  <span>{option.name}</span>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) => {
              let newValue = value.filter((it) => locationIds?.includes(it));
              return newValue.length === 1 ? (
                <div className={classes.tagStyle}>{newValue[0].name}</div>
              ) : newValue.length > 1 ? (
                <Box>Locations ({newValue.length})</Box>
              ) : (
                ''
              );
            }}
            PaperComponent={({ children, ...other }) => (
              <Paper
                {...other}
                style={{ width: 320 }}
                className={classes.selectWrapper}
              >
                {children}
              </Paper>
            )}
            style={{ width: locationIds.length === 1 ? 200 : 160 }}
            isSelected={locationIds.length === 1}
          />
          <CustomAsyncAutoComplete
            multiple
            disableCloseOnSelect
            isLocation
            endpoint="Purposes/filter"
            method="post"
            nameKey="name"
            value={purposeIds}
            isFormatResult
            onChange={(e, value) => {
              setFilterOption({
                ...filterOption,
                purposeIds: value.map((it) => it.id),
              });
              setPurposeIds(value);
            }}
            defaultFilter={{
              sortByName: true,
            }}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <CustomFilterInput
                {...params}
                variant="outlined"
                placeholder={isEmpty(purposeIds) ? 'All purposes' : ''}
              />
            )}
            renderOption={(option, { selected }) => {
              return (
                <Box display="flex" gridGap={4}>
                  <Checkbox
                    icon={<CheckboxDefault width={20} height={20} />}
                    checkedIcon={<CheckboxActive width={20} height={20} />}
                    checked={selected}
                    color="primry"
                    className={classes.checkboxIcon}
                  />
                  <span>{option.name}</span>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) => {
              let newValue = value.filter((it) => purposeIds?.includes(it));
              return newValue.length === 1 ? (
                <div className={classes.tagStyle}>{newValue[0].name}</div>
              ) : newValue.length > 1 ? (
                <Box>Purposes ({newValue.length})</Box>
              ) : (
                ''
              );
            }}
            PaperComponent={({ children, ...other }) => (
              <Paper
                {...other}
                style={{ width: 320 }}
                className={classes.selectWrapper}
              >
                {children}
              </Paper>
            )}
            style={{ width: purposeIds.length === 1 ? 200 : 160 }}
            isSelected={purposeIds.length === 1}
          />
          <CustomSelectContainer
            isFullWidth
            filterValue={clinicStatuses || []}
            data={CLINIC_APPT_STATUS}
            renderValue={renderValue(
              clinicStatuses.map(
                (it) => CLINIC_APPT_STATUS.find((item) => item.key === it).value
              ),
              'Statuses',
              'All statuses'
            )}
            clearable={false}
            onChange={onFilterOptionChange('statuses')}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={CLINIC_APPT_STATUS.length === clinicStatuses.length}
          />
        </Box>

        <CustomButton
          color="primary"
          disabled={isEmptyFilterParams}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
