import { COLUMN_SUB_SERVICES, COLUMN_SUB_SERVICES_OTHERS } from '../constants';
import { CloseCircle, CreateNewIcon } from '@/assets/svg';
import CustomButton from '@/new-components/CustomButton';
import { Box, IconButton, TextField, withStyles } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { cloneDeep, get } from 'lodash';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
        paddingLeft: 4,
        marginLeft: 0,
        height: 30,
      },

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
})(TextField);

const RowHeader = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    background: '#f1f1f1',
    fontSize: 12,
    color: '#000',
    padding: '10px',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
})(Box);

const RowItem = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderBottom: '1px solid #e1e1e1',
    boxSizing: 'border-box',
    background: '#fff',
    padding: 10,
  },
})(Box);

const ListSubServices = ({ formValue, setFormValue, fieldKey }) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    let newFormValue = cloneDeep(formValue);
    let updatedList = Array.from(newFormValue[fieldKey]);
    const [removed] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, removed);
    updatedList = updatedList.map((item, index) => ({
      ...item,
      order: index,
    }));
    newFormValue[fieldKey] = updatedList;

    setFormValue(newFormValue);
  };

  const handleChangeForm = (key, index) => (e) => {
    const value = get(e.target, 'value');
    let newFormValue = cloneDeep(formValue);
    let updatedList = Array.from(newFormValue[fieldKey]);
    updatedList[index][key] = value;
    newFormValue[fieldKey] = updatedList;
    setFormValue(newFormValue);
  };

  const handleDelete = (index) => {
    let newFormValue = cloneDeep(formValue);
    let updatedList = Array.from(newFormValue[fieldKey]);
    updatedList.splice(index, 1);
    newFormValue[fieldKey] = updatedList;
    setFormValue(newFormValue);
  };

  const handleAddNew = () => {
    let newFormValue = cloneDeep(formValue);
    let updatedList = Array.from(newFormValue[fieldKey]);
    if (fieldKey === 'subPurposes') {
      updatedList.push({ name: '', order: updatedList?.length || 0 });
    } else {
      updatedList.push({
        title: '',
        hintText: '',
        order: updatedList?.length || 0,
      });
    }

    newFormValue[fieldKey] = updatedList;
    setFormValue(newFormValue);
  };

  const columns =
    fieldKey === 'subPurposes'
      ? COLUMN_SUB_SERVICES
      : COLUMN_SUB_SERVICES_OTHERS;

  return (
    <Box width="100%" border="1px solid #e1e1e1" borderRadius={12}>
      <RowHeader>
        {columns.map((d) => (
          <Box
            flex={d.flex}
            width={d.width ? d.width : 'unset'}
            textAlign={d.textAlign ? d.textAlign : 'left'}
            ml={d.ml ? d.ml : 0}
          >
            {d.label}
          </Box>
        ))}
      </RowHeader>
      <Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="all-column"
            type="column"
            direction="vertical"
          >
            {(provided, snapshot) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                <Box
                  style={{
                    marginBottom: `${
                      snapshot?.draggingFromThisWith ? '70px' : '0px'
                    }`,
                  }}
                >
                  {formValue[fieldKey].map((item, index) => (
                    <Draggable
                      index={index}
                      key={`type-${index}`}
                      draggableId={`type-${index}`}
                    >
                      {(provided) => (
                        <RowItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          border={
                            snapshot?.draggingFromThisWith
                              ? '1px solid #e1e1e1'
                              : 'none'
                          }
                        >
                          <Box width={40} height={24}>
                            <DragIndicator
                              style={{
                                color: '#666',
                              }}
                            />
                          </Box>

                          <Box flex={0.8}>{index + 1}</Box>
                          {fieldKey === 'subPurposes' ? (
                            <Box flex={4}>
                              <CustomTextField
                                fullWidth
                                variant="outlined"
                                value={item.name || ''}
                                onChange={handleChangeForm('name', index)}
                                placeholder="Enter text"
                              />
                            </Box>
                          ) : (
                            <>
                              <Box flex={2}>
                                <CustomTextField
                                  fullWidth
                                  variant="outlined"
                                  value={item.title || ''}
                                  onChange={handleChangeForm('title', index)}
                                  placeholder="Enter text"
                                />
                              </Box>
                              <Box flex={2}>
                                <CustomTextField
                                  fullWidth
                                  variant="outlined"
                                  value={item.hintText || ''}
                                  onChange={handleChangeForm('hintText', index)}
                                  placeholder="Enter text"
                                  style={{ marginLeft: 24 }}
                                />
                              </Box>
                            </>
                          )}

                          <Box flex={1} textAlign={'center'}>
                            <IconButton onClick={() => handleDelete(index)}>
                              <CloseCircle />
                            </IconButton>
                          </Box>
                        </RowItem>
                      )}
                    </Draggable>
                  ))}
                </Box>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box p={1}>
        <CustomButton
          size="small"
          color="primary"
          startIcon={<CreateNewIcon />}
          onClick={handleAddNew}
          style={{ width: 'fit-content' }}
        >
          Add new
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ListSubServices;
