import {
  calculateTotalCost,
  formatData,
  formatExpressTotalTestServices,
  returnOptionalPackageTests,
  generateParamsTestIdsPackageIdsData,
  refactorPackageData,
} from '../../utils';
import SelectServiceStep from './SelecetServiceStep';
import SelectPatientStep from './SelectPatientStep';
import SelectPlaceAndTimeStep from './SelectPlaceAndTimeStep';
import ViewSelectPackage from './ViewSelectPackage';
import { urlLabel } from '@/enum/PermissionEnum';
import allScreeningsDispatcher from '@/module/all-screenings/action';
import checkinPatientDispatcher from '@/module/checkin-patient-information/action';
import companyDispatcher from '@/module/company/action';
import { packageDispatcher } from '@/module/package-management';
import locationDispatcher from '@/module/setup/action';
import { refactorListData } from '@/module/setup/constants';
import upcomingHSDispatcher from '@/module/upcoming-health-screening/action';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomStepper from '@/new-components/CustomStepper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { cloneDeep, findIndex, get, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const CreateAppointment = () => {
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [patientInfo, setPatientInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [dataInfo, setDataInfo] = useState({});
  const [isEditCode, setIsEditCode] = useState(false);
  const [locationFollowUp, setLocationFollowUp] = useState([]);

  const [totalCost, setTotalCost] = useState(0);
  const [totalAmountDefault, setTotalAmountDefault] = useState(0);

  const [loading, setLoading] = useState(false);

  const [timeSlot, setTimeSlot] = useState([]);
  const [timeSlotChecked, setTimeSlotChecked] = useState();
  const [dateChecked, setDateChecked] = useState();
  const [currentLocation, setCurrentLocation] = useState({});
  const [restrictedPackageIds, setRestrictedPackageIds] = useState([]);
  const [restrictedTestIds, setRestrictedTestIds] = useState([]);
  const [projectCustomFields, setProjectCustomFields] = useState([]);
  const [lstOptionalTests, setLstOptionalTests] = useState([]);
  const [packageTestIds, setPackageTestIds] = useState(null);

  // Disable updating status payment of Paxscreening when Project is free payment
  const [isProjectSuppression, setIsProjectSuppression] = useState(false);

  const [formValue, setFormValue] = useState({
    timeSlotId: '',
    date: '',
    healthScreeningId: '',
    isSuppression: false,
  });

  const [packageDetail, setPackageDetail] = useState({});

  const location = useLocation();
  const history = useHistory();
  const apptData = get(location, 'state', {});
  const label = get(location, 'label', 'Create new appointment');
  const isReschedule = get(apptData, 'isReschedule');
  const isFollowUp = get(apptData, 'isFollowUp');

  if (
    history.location.pathname ===
      '/all-screenings/create-follow-up-appointment' &&
    isEmpty(apptData)
  ) {
    history.push(`/${urlLabel.allScreenings}`);
  }
  const onSubmit = () => {
    if (loading) return;

    setLoading(true);
    if (isFollowUp) {
      let lstTests = refactorListData(formValue);
      let newListTest = [];

      lstTests.forEach(
        (test) => (newListTest = newListTest.concat(test.items))
      );

      const newParams = {
        patientId: formValue.patientId,
        timeSlotId: formValue.timeSlotId,
        date: moment(formValue.date).format('YYYY-MM-DD'),
        locationId: formValue.screeningId,
        registeredData: {
          lstTests: newListTest,
          lstProfiles: formValue.lstProfiles,
          lstPackages: formValue.lstPackages,
          ignoredTests: formValue.ignoredTests,
          ignoredProfiles: formValue.ignoredProfiles,
        },
      };

      // Check valid Procedure
      const totalProcedureTests = packageDetail.lstProcedures;
      const totalMandatoryProcedure = packageDetail.totalMandatoryProcedure;
      const ignoredProcedure = formValue.ignoredTests?.filter((it) =>
        totalProcedureTests?.map((d) => d.id)?.includes(it.testId)
      );

      const checkTestProcedureDone = ignoredProcedure.filter((it) =>
        dataInfo.doneFollowUpTests?.map((d) => d.testId)?.includes(it.testId)
      );

      // Check valid Radiology
      const totaRadiologyTests = packageDetail.lstRadiologies;
      const totalMandatoryRadiology = packageDetail.totalMandatoryRadiology;
      const ignoredRadiology = formValue.ignoredTests?.filter((it) =>
        totaRadiologyTests?.map((d) => d.id)?.includes(it.testId)
      );
      const checkTestRadiologyDone = ignoredRadiology.filter((it) =>
        dataInfo.doneFollowUpTests?.map((d) => d.testId)?.includes(it.testId)
      );

      if (
        ignoredProcedure?.length !==
          totalProcedureTests?.length - totalMandatoryProcedure &&
        isEmpty(checkTestProcedureDone)
      ) {
        customToast(
          'error',
          'Please select optional Procedures for selected package.'
        );
      } else if (
        ignoredRadiology?.length !==
          totaRadiologyTests?.length - totalMandatoryRadiology &&
        isEmpty(checkTestRadiologyDone)
      ) {
        customToast(
          'error',
          'Please select optional Radiologies for selected package.'
        );
      } else {
        upcomingHSDispatcher.followUpAppointment(
          apptData.id,
          newParams,
          ({ isSuccess }) => {
            setLoading(false);
            if (isSuccess) {
              customToast(
                'success',
                <span>
                  Appointment for patient <b>{apptData.fullName}</b> has been
                  successfully created.
                </span>,
                'Follow-up appointment created'
              );
              history.push({
                pathname: `/${urlLabel.allScreenings}`,
              });
            }
          }
        );
      }
    } else {
      let lstTests = refactorListData(formValue);
      lstTests = formatExpressTotalTestServices(lstTests);

      const newScreeningFieldsList = generateScreeningFieldsListData(
        formValue.lstScreeningFields
      );

      let newParams = {
        screeningId: formValue.screeningId,
        timeSlotId: formValue.timeSlotId,
        patientId: patientInfo.id,
        date: moment(formValue.date).format('YYYY-MM-DD'),
        jsonData: projectInfo.jsonArrayStaffItems,
        staffType: formValue?.staffType,
        lstScreeningFields: newScreeningFieldsList,
        lstTests,
        lstProfiles: formValue.lstProfiles,
        lstPackages: formValue.lstPackages,
        ignoredTests: formValue.ignoredTests,
        ignoredProfiles: formValue.ignoredProfiles,
        isPaxscreenSuppression: formValue.isPaxscreenSuppression,
      };

      upcomingHSDispatcher.createAppointment(newParams, ({ isSuccess }) => {
        setLoading(false);
        if (isSuccess) {
          customToast(
            'success',
            <span>
              Appointment for patient <b>{patientInfo.fullName}</b> has been
              successfully updated.
            </span>,
            'Appointment created'
          );
          history.push(`/${urlLabel.allScreenings}`);
        }
      });
    }
  };

  const onCancel = () => {
    history.push(`/${urlLabel.allScreenings}`);
  };

  const handleChangeData = (data, filterKey) => {
    let allTest = [];
    data.forEach((prj) => {
      allTest = allTest.concat(prj.items);
    });
    const selectedData = allTest.filter((test) => test.isChecked);
    const totalCost = calculateTotalCost({
      ...formValue,
      [filterKey]: selectedData,
    });

    setFormValue({ ...formValue, [filterKey]: selectedData });
    setTotalCost(totalCost);
  };

  const generateScreeningFieldsListData = (data) => {
    const newData = data.map((it) => {
      if (['DropdownList', 'Rating'].includes(it.customFieldType)) {
        return { ...it, value: String(it.value) };
      }
      if (it.customFieldType === 'MultiSelect') {
        return { ...it, value: JSON.stringify(it.value) };
      }
      return it;
    });
    return newData;
  };

  useEffect(() => {
    if (apptData.id && apptData.projectId) {
      checkinPatientDispatcher.getData(apptData.id, (paxData) => {
        const {
          paxInfo,
          lstTests,
          lstProfiles,
          lstPackages,
          ignoredTests,
          ignoredProfiles,
          testsByCatetory,
          doneFollowUpTests,
        } = paxData;
        // Have package
        if (!isEmpty(lstPackages)) {
          const { id, name, price } = lstPackages[0];
          packageDispatcher.getPackageById(id, (result) => {
            const { lstTests: lstPackageTests } = result;

            let lstProcedures = [];
            let lstRadiologies = [];

            lstPackageTests.forEach((item) => {
              if (item.category === 'Procedures') lstProcedures = item.items;
              if (item.category === 'Radiologies') lstRadiologies = item.items;
            });

            const newPackageData = refactorPackageData({
              ...result,
              isFollowUp,
              lstProcedures,
              lstRadiologies,
              ignoredTests,
              ignoredProfiles,
            });

            const {
              lstProcedures: newLstProcedures,
              lstRadiologies: newLstRadiologies,
              totalMandatoryProcedure,
              totalMandatoryRadiology,
              holdDoneFollowUpTestsProcedures,
              holdDoneFollowUpTestsRadiologies,
              lstOptionalTests,
              selectedOptionalIds,
            } = newPackageData;
            let holdIdMandatory = [];
            const mandatoryProcedures = lstProcedures.filter(
              (it) => it.isRequired
            );
            const mandatoryRadiologies = lstRadiologies.filter(
              (it) => it.isRequired
            );

            holdIdMandatory = mandatoryProcedures.concat(mandatoryRadiologies);
            holdIdMandatory = holdIdMandatory.map((it) => it.id);
            let packageTestIds = selectedOptionalIds.concat(holdIdMandatory);
            if (!isEmpty(doneFollowUpTests)) {
              packageTestIds = packageTestIds.filter(
                (it) =>
                  doneFollowUpTests?.findIndex((d) => d.testId === it) === -1
              );
            }

            setPackageTestIds(packageTestIds);
            setLstOptionalTests(lstOptionalTests);
            setPackageDetail({
              ...result,
              lstTests: lstPackageTests,
              lstProcedures: newLstProcedures,
              lstRadiologies: newLstRadiologies,
              totalMandatoryProcedure,
              totalMandatoryRadiology,
              name,
              price,
              holdDoneFollowUpTestsRadiologies,
              holdDoneFollowUpTestsProcedures,
              doneFollowUpTests,
            });
          });
        }

        // Calculate total cost and total amount
        const newLstTests = formatData({ lstTests });

        const totalCost = calculateTotalCost({
          ...newLstTests,
          lstPackages,
          lstProfiles,
        });

        setPatientInfo({
          fullName: paxInfo.fullName,
          identityNumber: paxInfo.identityNumber,
          dateOfBirth: paxInfo.dateOfBirth,
          nationality: paxInfo.nationality,
          ethnicGroup: paxInfo.ethnicGroup,
          mobile: paxInfo.mobile,
          email: paxInfo.email,
          countryCode: paxInfo.countryCode,
        });

        if (isFollowUp) {
          const listAddOn = lstTests.filter((it) =>
            ['Radiologies', 'Procedures', 'Vaccinations'].includes(it.category)
          );

          setDataInfo({
            ...apptData,
            lstTests: listAddOn,
            lstProfiles,
            lstPackages,
            ignoredTests,
            ignoredProfiles,
            doneFollowUpTests,
          });
          setFormValue({
            patientId: paxInfo.id,
            lstTests: listAddOn,
            lstProfiles,
            lstPackages,
            ignoredTests,
            ignoredProfiles,
          });
        }
        setTotalCost(totalCost);
        setTotalAmountDefault(apptData?.totalCost);
      });
      companyDispatcher.getProjectByID(apptData.projectId, (result) => {
        setProjectInfo({
          code: result.code,
          companyName: result.companyName,
          staffTypeLabel: result.staffTypeLabel,
          startDate: result.startDate,
          endDate: result.endDate,
          lstHealthScreenings: result.lstHealthScreenings,
          currLocationId: apptData.locationId,
        });
      });
      if (!isFollowUp) {
        setFormValue({
          timeSlotId: !isReschedule ? apptData.timeSlotId : '',
          date: apptData.startDate,
          healthScreeningId: apptData.healthScreeningId,
          isSuppression: apptData.isPaxscreenSuppression,
        });

        // Hide free payment
        setIsProjectSuppression(isProjectSuppression);
      }
    }
  }, [isFollowUp, apptData, isReschedule]);

  useEffect(() => {
    if (isFollowUp || isEmpty(formValue) || isEmpty(packageDetail)) return;
    let newLstProcedures = [];
    let newLstRadiologies = [];

    const { lstProfiles, lstProcedures, lstRadiologies } = packageDetail;
    const { ignoredProfiles, ignoredTests } = formValue;

    // Filter test restricted by location
    // if (!isEmpty(restrictedTestIds)) {
    //   newLstProcedures = lstProcedures?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    //   newLstRadiologies = lstRadiologies?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    // }

    // Get list of Profile, Procedure, Radiology
    // Filter unmandatory test
    // Filter Optional test based on ingnored Test

    const lstOptionalTests = returnOptionalPackageTests({
      lstProcedures,
      lstRadiologies,
      lstProfiles,
      ignoredTests,
      ignoredProfiles,
    });

    // if (isEmpty(newLstOptionalTests)) {
    //   const newOptionalWithoutRestricted = lstOptionalTests?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    //   newLstOptionalTests = newOptionalWithoutRestricted;
    // }
    setLstOptionalTests(lstOptionalTests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValue.ignoredTests,
    formValue.ignoredProfiles,
    packageDetail,
    isFollowUp,
  ]);

  useEffect(() => {
    if (isEmpty(packageDetail) || isEmpty(formValue?.lstPackages)) return;
    const { lstProcedures, lstRadiologies, id } = packageDetail;
    const { lstPackages, ignoredTests } = formValue;
    const projectId = lstPackages[0]?.projectId;
    // let newLstProcedures = [];
    // let newLstRadiologies = [];

    // Filter test restricted by location
    // if (!isEmpty(restrictedTestIds)) {
    //   newLstProcedures = lstProcedures?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    //   newLstRadiologies = lstRadiologies?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    // }

    const ignoredProcedures = lstProcedures?.filter((it) => !it.isRequired);
    const newIgnoredProcedures = formatIgnoredTests(
      id,
      projectId,
      ignoredProcedures
    );
    const ignoredRadiologies = lstRadiologies?.filter((it) => !it.isRequired);
    const newIgnoredRadiologies = formatIgnoredTests(
      id,
      projectId,
      ignoredRadiologies
    );

    const newIgnoredTests = mergeArray(
      newIgnoredProcedures,
      newIgnoredRadiologies,
      'testId'
    );

    setFormValue({
      ...formValue,
      ignoredTests: isFollowUp ? ignoredTests : newIgnoredTests,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageDetail, isFollowUp]);
  console.log('formValue', formValue);
  const formatIgnoredTests = (packageId, projectId, data) => {
    const newData = data.map((item) => ({
      testId: item.id,
      packageId,
      projectId,
    }));
    return newData;
  };

  const mergeArray = (a, b, key) => {
    const reduced = a.filter(
      (aitem) => !b.find((bitem) => aitem[key] === bitem[key])
    );
    return reduced.concat(b);
  };

  useEffect(() => {
    if (isEmpty(dataInfo) || !packageTestIds) return;
    if (isFollowUp) {
      const formatTests = (lstTests) => {
        lstTests.forEach((testCategory) => {
          if (testCategory.category === 'Measurements') {
            testCategory.items = testCategory.items.reduce((prev, curr) => {
              return prev.concat(curr.total);
            }, []);
          }
        });
        return lstTests;
      };
      const lstTests = formatTests(cloneDeep(dataInfo).lstTests);
      const lstPackages = cloneDeep(dataInfo.lstPackages);
      const params = generateParamsTestIdsPackageIdsData({
        lstPackages,
        lstTests,
      });
      // Handle optional that already restricted (Procedure & Radiology)
      const newParams = {
        isFollowUp: true,
        paxScreeningId: dataInfo.id,
        testIds: params.testIds.concat(packageTestIds),
      };
      allScreeningsDispatcher.getRestrictLocationByIds(
        newParams,
        (restrictedLocationIds) => {
          locationDispatcher.getLocationFollowUp((result) => {
            const newList = [...result]
              .map((it) => ({
                ...it,
                locationId: it.id,
                locationName: it.name,
                locationAddress: it.address,
              }))
              .filter(
                (item) => !restrictedLocationIds.includes(item.locationId)
              );
            setLocationFollowUp(newList);
          });
        }
      );
    }
  }, [dataInfo, isFollowUp, packageTestIds]);

  const STEPS = [
    {
      name: 'General information',
      component: (
        <SelectPatientStep
          dataInfo={apptData}
          patientInfo={patientInfo}
          setPatientInfo={setPatientInfo}
          projectInfo={projectInfo}
          setProjectInfo={setProjectInfo}
          formValue={formValue}
          setFormValue={setFormValue}
          setIsEditCode={setIsEditCode}
          errors={errors}
          setErrors={setErrors}
          setProjectCustomFields={setProjectCustomFields}
          setCurrentLocation={setCurrentLocation}
        />
      ),
    },
    {
      name: 'Select place and time',
      validKeys: ['date', 'screeningId', 'timeSlotId'],
      component: (
        <SelectPlaceAndTimeStep
          dataInfo={apptData}
          patientInfo={patientInfo}
          projectInfo={projectInfo}
          setProjectInfo={setProjectInfo}
          formValue={formValue}
          setFormValue={setFormValue}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
          timeSlotChecked={timeSlotChecked}
          setTimeSlotChecked={setTimeSlotChecked}
          dateChecked={dateChecked}
          setDateChecked={setDateChecked}
          setRestrictedPackageIds={setRestrictedPackageIds}
          setRestrictedTestIds={setRestrictedTestIds}
        />
      ),
    },
    {
      name: 'Select service',
      validKeys: [
        'lstPackages',
        'lstProfiles',
        'lstMeasurements',
        'lstProcedures',
        'lstRadiologies',
        'lstVaccinations',
        'lstReviews',
        'lstTests',
      ],
      component: (
        <SelectServiceStep
          totalCost={totalCost}
          totalAmountDefault={totalAmountDefault}
          projectInfo={projectInfo}
          locationId={currentLocation.locationId}
          dataInfo={dataInfo}
          paymentStatus={apptData.paymentStatus}
          formValue={formValue}
          setFormValue={setFormValue}
          handleChangeData={handleChangeData}
          packageDetail={packageDetail}
          setPackageDetail={setPackageDetail}
          projectCustomFields={projectCustomFields}
          lstOptionalTests={lstOptionalTests}
          restrictedTestIds={restrictedTestIds}
        />
      ),
    },
  ];

  const renderSteps = () => {
    let listSteps = [...STEPS];

    if (isFollowUp)
      listSteps = [].concat(
        {
          name: 'Select place and time',
          validKeys: ['date', 'screeningId', 'timeSlotId'],
          component: (
            <SelectPlaceAndTimeStep
              isFollowUp={isFollowUp}
              dataInfo={apptData}
              patientInfo={patientInfo}
              projectInfo={projectInfo}
              formValue={formValue}
              setFormValue={setFormValue}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              locationFollowUp={locationFollowUp}
              timeSlot={timeSlot}
              setTimeSlot={setTimeSlot}
              timeSlotChecked={timeSlotChecked}
              setTimeSlotChecked={setTimeSlotChecked}
              dateChecked={dateChecked}
              setDateChecked={setDateChecked}
              setRestrictedPackageIds={setRestrictedPackageIds}
              setRestrictedTestIds={setRestrictedTestIds}
            />
          ),
        },
        {
          name: 'Select service',
          component: (
            <ViewSelectPackage
              totalCost={totalCost}
              totalAmountDefault={totalAmountDefault}
              packageDetail={packageDetail}
              formValue={formValue}
              paymentStatus={apptData.paymentStatus}
              lstOptionalTests={lstOptionalTests}
              dataInfo={dataInfo}
              locationId={currentLocation.locationId}
              restrictedTestIds={restrictedTestIds}
            />
          ),
        }
      );
    return listSteps;
  };
  const isEmptyFilterParams = useMemo(() => {
    let type = renderSteps()[currentStep]?.name;
    let isValid = false;
    let filterKeys = renderSteps()[currentStep]?.validKeys || [];
    switch (type) {
      case 'General information':
        isValid =
          isEmpty(patientInfo) ||
          isEmpty(projectInfo) ||
          (isEmpty(formValue.staffType) &&
            projectInfo.lstStaffTypeItems.length);
        return isValid;
      case 'Select place and time':
        isValid = filterKeys.some((key) =>
          isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
        );
        return isValid;
      default:
        // Select service step
        /*
          1. If user doesn't select any test
          2. If project has custom survey and doesn't enter full form
          3. If user select anyone test or custom survey of project is filled 
            - If user select package but doesn't required optional 
        */

        // 1. If user doesn't select any test
        const checkEmptyTest = !filterKeys.some((key) =>
          isBoolean(formValue[key]) ? formValue[key] : !isEmpty(formValue[key])
        );

        // 2. If project has custom survey and doesn't enter full form
        const formatLstScreeningFields = formValue?.lstScreeningFields?.map(
          (item) => ({
            ...item,
            hasAdditional: projectCustomFields.items.find(
              (it) => it.id === item.fieldId
            ).hasAdditional,
          })
        );
        const checkEmptyCustomSurvey =
          !isEmpty(projectCustomFields.items) &&
          projectCustomFields.items.length !==
            formValue.lstScreeningFields.length
            ? projectCustomFields.items.length !==
              formValue.lstScreeningFields.length
            : formatLstScreeningFields?.some(
                (item) =>
                  isEmpty(item.value) ||
                  (item.value.includes('Others') &&
                    item.hasAdditional &&
                    isEmpty(item.otherAnswer))
              );

        if (checkEmptyTest || checkEmptyCustomSurvey)
          return checkEmptyTest || checkEmptyCustomSurvey;
        else {
          // 3. If user select anyone test or custom survey of project is filled
          // - If user select package but doesn't required optional
          if (!isEmpty(formValue.lstPackages) && !isEmpty(packageDetail)) {
            const {
              lstProcedures,
              lstRadiologies,
              lstProfiles,
              totalMandatoryProcedure,
              totalMandatoryProfile,
              totalMandatoryRadiology,
              holdDoneFollowUpTestsRadiologies,
              holdDoneFollowUpTestsProcedures,
            } = packageDetail;
            const { ignoredTests, ignoredProfiles } = formValue;

            let newLstProcedures = cloneDeep(lstProcedures);
            let newLstRadiologies = cloneDeep(lstRadiologies);

            // Filter test restricted by location
            // if (!isEmpty(restrictedTestIds)) {
            //   newLstProcedures = newLstProcedures?.filter(
            //     it => !restrictedTestIds?.includes(it.id)
            //   );
            //   newLstRadiologies = newLstRadiologies?.filter(
            //     it => !restrictedTestIds?.includes(it.id)
            //   );
            // }

            // Profiles
            const unMandatoryProfiles = cloneDeep(lstProfiles)?.filter(
              (it) => !it.isRequired
            );
            const selectedProfiles = unMandatoryProfiles?.filter((value) => {
              return ignoredProfiles?.some((test) => {
                return value.id === test.profileId;
              });
            });
            // Procedures
            const unMandatoryProcedures = cloneDeep(newLstProcedures)?.filter(
              (it) => !it.isRequired
            );
            const selectedProcedures = unMandatoryProcedures?.filter(
              (value) => {
                return ignoredTests?.some((test) => {
                  return value.id === test.testId;
                });
              }
            );

            const selectedRequiredProcedures = unMandatoryProcedures?.filter(
              (value) => {
                return ignoredTests?.every((test) => {
                  return value.id !== test.testId;
                });
              }
            );

            // Radiology
            const unMandatoryRadiology = cloneDeep(newLstRadiologies)?.filter(
              (it) => !it.isRequired
            );
            const selectedRadiology = unMandatoryRadiology?.filter((value) => {
              return ignoredTests?.some((test) => {
                return value.id === test.testId;
              });
            });

            const selectedRequiredRadiology = unMandatoryRadiology?.filter(
              (value) => {
                return ignoredTests?.every((test) => {
                  return value.id !== test.testId;
                });
              }
            );

            if (isFollowUp) {
              const checkFollowProcedures = !(totalMandatoryProcedure -
                holdDoneFollowUpTestsProcedures.length ===
              0
                ? totalMandatoryProcedure === selectedRequiredProcedures.length
                : totalMandatoryProcedure -
                    holdDoneFollowUpTestsProcedures.length ===
                  selectedRequiredProcedures.length);
              const checkFollowRadiology = !(totalMandatoryRadiology -
                holdDoneFollowUpTestsRadiologies.length ===
              0
                ? totalMandatoryRadiology === selectedRequiredRadiology.length
                : totalMandatoryRadiology -
                    holdDoneFollowUpTestsRadiologies.length ===
                  selectedRequiredRadiology.length);

              return checkFollowProcedures || checkFollowRadiology;
            } else
              return (
                unMandatoryProfiles?.length - selectedProfiles?.length !==
                  totalMandatoryProfile ||
                unMandatoryProcedures?.length - selectedProcedures?.length !==
                  totalMandatoryProcedure ||
                unMandatoryRadiology?.length - selectedRadiology?.length !==
                  totalMandatoryRadiology
              );
          }
        }

        break;
    }

    return isValid;
  }, [patientInfo, projectInfo, currentStep, formValue, packageDetail]);

  const isEmptyFilterParamsFollowUp = useMemo(() => {
    let isValid = false;
    let filterKeys = renderSteps()[currentStep]?.validKeys || [];
    isValid = filterKeys.some((key) =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );
    return isValid;
  }, [currentStep, formValue]);

  const handleProceed = async () => {
    if (currentStep === renderSteps().length - 1) {
      return onSubmit();
    }

    if (renderSteps()[currentStep].name === 'Select place and time') {
      const newFormValue = cloneDeep(formValue);

      // Deselect restricted package
      if (restrictedPackageIds.includes(formValue.lstPackages?.[0]?.id)) {
        newFormValue.lstPackages = [];
        newFormValue.ignoredTests = [];
        newFormValue.ignoredProfiles = [];
      }

      // Deselect restricted mandatory tests
      if (!isEmpty(packageDetail) && !isEmpty(newFormValue.lstPackages)) {
        const lstMandatoryTests = [
          ...packageDetail.lstProcedures,
          ...packageDetail.lstRadiologies,
        ].filter((item) => item.isRequired);
        const restrictedTests = lstMandatoryTests.filter((item) =>
          restrictedTestIds.includes(item.id)
        );

        if (restrictedTests.length) {
          newFormValue.lstPackages = [];
          newFormValue.ignoredTests = [];
          newFormValue.ignoredProfiles = [];
        }
      }

      // Deselect restricted optional tests
      if (!isEmpty(packageDetail) && !isEmpty(newFormValue.lstPackages)) {
        const restrictedTests = lstOptionalTests.filter((item) =>
          restrictedTestIds.includes(item.id)
        );
        const ignoredTests = formatIgnoredTests(
          newFormValue.lstPackages[0].id,
          newFormValue.lstPackages[0].projectId,
          restrictedTests
        );

        newFormValue.ignoredTests = mergeArray(
          ignoredTests,
          newFormValue.ignoredTests,
          'testId'
        );
      }

      newFormValue.lstProcedures = (newFormValue.lstProcedures || []).filter(
        (item) => !restrictedTestIds.includes(item.id)
      );
      newFormValue.lstRadiologies = (newFormValue.lstRadiologies || []).filter(
        (item) => !restrictedTestIds.includes(item.id)
      );

      const totalCost = calculateTotalCost(newFormValue);
      if (!isFollowUp) setTotalCost(totalCost);
      return setCurrentStep((currentStep) => currentStep + 1);
    }

    return setCurrentStep((currentStep) => currentStep + 1);
  };

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title={label}
            isSearch={false}
            renderButton={() => (
              <>
                <Button color="primary" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          />
        }
        children={
          <CustomStepper
            disableNext
            steps={renderSteps()}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            renderButtons={() => (
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={currentStep === 0}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  style={{ marginRight: 16 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={
                    isFollowUp
                      ? isEmptyFilterParamsFollowUp
                      : isEmptyFilterParams || isEditCode || errors.code
                  }
                  onClick={handleProceed}
                >
                  {currentStep === renderSteps().length - 1 ? 'Create' : 'Next'}
                </Button>
              </Box>
            )}
          >
            <Box height="calc(100vh - 282px)" overflow="scroll">
              {renderSteps() && renderSteps()[currentStep]?.component}
            </Box>
          </CustomStepper>
        }
      />
    </>
  );
};

export default CreateAppointment;
